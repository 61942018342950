import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Typography,
    Tabs,
    Tab,
    Box,
    Button,
    MenuItem,
    Chip,
    Stack,
    Container,
} from '@mui/material';

import { useSnackbar } from 'notistack';

import { gql, useMutation, useQuery } from '@apollo/client';
import { SignatureForm } from './WorkOrders';
import { useReducer, useState } from 'react';
import { useMe } from '../CurrentUserContext';
import { MyTextField } from './Common';


const formReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.payload };
        case 'reset':
            return { ...action.payload };
        default:
            throw new Error();
    }
};


export const UserProfileComponent = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { me, refetchMe } = useMe()
    const [signature, setSignature] = useState(me.profile.signature || '');
    const [hasChanges, setHasChanges] = useState(false);

    const [state, dispatch] = useReducer(formReducer, {
        signature: me.profile.signature || '',
        email: me.email || '',
        firstName: me.firstName || '',
        lastName: me.lastName || '',
    });

    const [updateUserProfile, { loading: updating }] = useMutation(gql`
        mutation UpdateUserProfile ( $id: ID!, $input: UserProfileInput! ) {
            updateUserProfile ( id: $id, input: $input ) {
                id
            }
        }
    `);

    const onSubmit = async () => {
        await updateUserProfile({
            variables: {
                id: me.id,
                input: state,
            }
        });
        setHasChanges(false);
        await refetchMe()
        enqueueSnackbar('Profile updated', { variant: 'success' })
    }

    return (
        <Container maxWidth="md">
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6">
                        Profile d'utilisateur
                    </Typography>

                    <MyTextField
                        label="Nom d'utilisateur"
                        value={me.username}
                        disabled={true}
                    />

                    <MyTextField
                        label="E-mail"
                        value={state.email}
                        onChange={e => {
                            dispatch({ type: 'set', payload: { email: e.target.value } })
                            setHasChanges(true)
                        }}
                    />

                    <MyTextField
                        label="Prénom"
                        value={state.firstName}
                        onChange={e => {
                            dispatch({ type: 'set', payload: { firstName: e.target.value } })
                            setHasChanges(true)
                        }}
                    />

                    <MyTextField
                        label="Nom de famille"
                        value={state.lastName}
                        onChange={e => {
                            dispatch({ type: 'set', payload: { lastName: e.target.value } })
                            setHasChanges(true)
                        }}
                    />

                    {updating
                        ? <CircularProgress />
                        : (
                            <SignatureForm
                                title="MA SIGNATURE"
                                state={{ clientSignature: state.signature }}
                                dispatch={(({ value }) => {
                                    dispatch({ type: 'set', payload: { signature: value } })
                                    setHasChanges(true)
                                })}
                            />
                        )}

                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            disabled={!hasChanges || updating}
                            onClick={onSubmit}>
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Container>
    )
}