import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";

import {
    Container,
    CardHeader,
    Button,
    Box,
    Stack,
    Paper,
    Chip,
    IconButton,

    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,

    Zoom,
} from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import LaunchIcon from '@mui/icons-material/Launch';
import BusinessIcon from '@mui/icons-material/Business';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

// import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
// import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
// import MoreTimeIcon from '@mui/icons-material/MoreTime';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import { frFR, enUS, nlNL } from '@mui/x-data-grid';

import {
    Loading,
    CustomCard,
    BasicBreadcrumbs,
    MyTextField,
    ConfirmDialog,
    ZenDataGrid,
    useLocalStorage,
    DOCUMENT_STATE_MAPPING,
    GSE_WORKORDER_STATE_MAPPING,
    useDebounce,
    useDebouncedValue,
    getFileExtension,
} from './Common'

import { Trans, t, Plural } from '@lingui/macro'

import { useSnackbar } from 'notistack';

import { Settings } from '../Settings'

import { UPDATE_GSE_WORK_ORDER_MUTATION } from "./WorkOrders";

import {
    WO_STATE_MAP,
} from './WorkOrders'
import { useMe } from "../CurrentUserContext";


const GSE_DEFAULT_EMAIL = `Cher Client,

En pièce jointe, vous trouverez la fiche relative à l'intervention effectuée sur votre système.
L'équipe GSE.be vous remercie pour votre confiance et reste à votre disposition pour toutes questions ou complément d'information.

Bien cordialement.

Patrick Fondu
Administrateur.
GSE.be`


export const DocumentComponent = () => {
    const { me } = useMe();

    const { enqueueSnackbar } = useSnackbar()
    // const history = useHistory()
    const { params } = useRouteMatch()
    const { documentId } = params

    const [showEmailForm, setShowEmailForm] = useState(false)
    const [emailTo, setEmailTo] = useState('')
    const [emailMessage, setEmailMessage] = useState(
        me.profile.organization.id === "1"
            ? GSE_DEFAULT_EMAIL
            : '')

    const [updateGseWorkOrderState] = useMutation(UPDATE_GSE_WORK_ORDER_MUTATION)

    const { data, error, loading, refetch } = useQuery(gql`
        query DocumentQuery ( $documentId: ID! ) {
            document ( documentId: $documentId ) {
                id
                titre
                dateUpload
                file
                client {
                    id
                    fullName
                    email
                }
                workOrder {
                    id
                    state
                }
            }
        }
    `, {
        variables: {
            documentId,
        },
        onCompleted: data => {
            setEmailTo(data.document.client.email || '')
        },
    })

    const [sendGseWorkorderEmail] = useMutation(gql`
        mutation SendGseWorkorderEmailMutation ( $to: String!, $message: String!, $workorderId: ID! ) {
            sendGseWorkorderEmail ( to: $to, message: $message, workorderId: $workorderId ) {
                sent
            }
        }
    `)

    const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false)

    if (loading) return <Loading />
    if (error) return 'error...'

    const { document } = data

    const isPdf = document.file.endsWith(".pdf");

    const sendEmail = async () => {
        const { data } = await sendGseWorkorderEmail({
            variables: {
                to: emailTo,
                message: emailMessage,
                workorderId: document.workOrder.id,
            },
        })
        const { sent } = data.sendGseWorkorderEmail
        enqueueSnackbar(sent ? `Message sent` : `Message send failed`)
        setShowEmailForm(false);
    }

    return (
        <>
            <Container component="main" maxWidth={false} sx={{ mb: 4 }}>
                <BasicBreadcrumbs pages={[
                    [`/clients/${document.client.id}/view`, `${document.client.fullName}`],
                    [`Document ${document.id}`]
                ]} />

                {document.workOrder?.id && (
                    <CustomCard>
                        <CardHeader title="Fiche de travail" />

                        <Box sx={{ p: 2 }}>
                            <>Etat: {WO_STATE_MAP[document.workOrder.state]}</>
                        </Box>

                        <Stack direction="column" spacing={2} sx={{ px: 2, pb: 2 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={e => setShowEmailForm(true)}>Envoyer au client par e-mail</Button>
                                <>
                                    <Button
                                        variant="contained"
                                        disabled={!me.profile.mayCloseWorkOrders}
                                        onClick={() => setShowConfirmCloseDialog(true)}
                                    >CLÔTURER</Button>
                                    <ConfirmDialog
                                        open={showConfirmCloseDialog}
                                        title={"Confirmation requis"}
                                        body={`...`}
                                        onClose={() => setShowConfirmCloseDialog(false)}
                                        onSubmit={async () => {
                                            await updateGseWorkOrderState({
                                                variables: {
                                                    id: document.workOrder.id,
                                                    data: {},
                                                    state: 'CLOSED',
                                                }
                                            })
                                            await refetch();
                                        }}
                                    />
                                </>
                            </Stack>
                            {showEmailForm && (
                                <>
                                    <MyTextField
                                        label={'Recipient'}
                                        value={emailTo}
                                        onChange={e => setEmailTo(e.target.value)}
                                    />
                                    <MyTextField
                                        label={'Message'}
                                        value={emailMessage}
                                        onChange={e => setEmailMessage(e.target.value)}
                                        multiline
                                        rows={10}
                                    />
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained" onClick={() => sendEmail()}>ENVOYER</Button>
                                        <Button variant="outlined" onClick={() => setShowEmailForm(false)}>Annuler</Button>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </CustomCard>
                )}

                {isPdf && (
                    <div>
                        <iframe style={{ width: '100%', minHeight: 800 }} src={`${Settings.MEDIA_URL}/${document.file}`} />
                    </div>
                )}
            </Container>
        </>
    )
}


export const DocumentsListingPage = () => (
    <Paper sx={{ p: 2 }}>
        <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1}>
                <Button variant="outlined" color="primary" disabled={true}
                    startIcon={<AddCircleOutlineIcon />}
                    >
                    Nouveau document
                </Button>
            </Stack>
            <DocumentsListing rowsPerPage={20} />
        </Stack>
    </Paper>
)


export const DocumentsListing = props => {
    const { clientId } = props
    const history = useHistory()
    const [lang] = useLocalStorage('lang', 'fr')

    const LOCALE_TEXT_MAP = {
        'fr': frFR.components.MuiDataGrid.defaultProps.localeText,
        'en': enUS.components.MuiDataGrid.defaultProps.localeText,
        'nl': nlNL.components.MuiDataGrid.defaultProps.localeText,
    }

    const localeText = LOCALE_TEXT_MAP[lang]

    const [search, setSearch] = useState('')
    const debouncedSearch = useDebouncedValue(search, 650)

    const [page, setPage] = useState(0)
    const [skip, setSkip] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 20)

    const { data, loading, errors, refetch } = useQuery(gql`
        query DocumentSearchQuery ( $input: DocumentSearchInputType! ) {
            searchDocuments ( input: $input ) {
                count
                documents {
                    ... on GseWorkOrderType {
                        __typename
                        id
                        createdAt
                        state
                        signedDocument {
                            id
                            createdAt
                            titre
                            file
                            uploadedFilename
                        }
                        workOrderClient: client {
                            id
                            fullName
                        }
                    }
                    ... on DocumentType {
                        __typename
                        id
                        createdAt
                        titre
                        file
                        uploadedFilename
                        documentClient: client {
                            id
                            fullName
                        }
                    }
                    ... on FormType {
                        __typename
                        id
                        createdAt
                        state
                        template {
                            id
                            displayName
                        }
                        formClient: client {
                            id
                            fullName
                        }
                        document {
                            id
                            createdAt
                            titre
                            file
                            uploadedFilename
                        }
                    }
                }
            }
        }
    `, {
        variables: {
            input: {
                q: debouncedSearch,
                skip,
                first: rowsPerPage,
                formTemplates: [],
                clientId,
            }
        },
        fetchPolicy: 'no-cache',
    })

    if (errors) return 'error...'

    const columns = [
        {
            field: 'document_type',
            sortable: false,
            // flex: 0.1,
            flex: 1,
            // minWidth: 200,
            headerName: 'Document',
            renderCell: params => {
                const { row } = params
                if (row.__typename === 'GseWorkOrderType') {
                    return <>
                        {row.signedDocument?.titre || 'Fiche de travail'}
                        <Chip
                            // label={document.state}
                            label={GSE_WORKORDER_STATE_MAPPING[row.state]['text']}
                            color={GSE_WORKORDER_STATE_MAPPING[row.state]['color']}
                            size="small" sx={{ ml: 1 }} />
                    </>
                }
                if (row.__typename === 'DocumentType') {
                    return row.titre
                }
                if (row.__typename === 'FormType') {
                    return <>
                        {row.template.displayName}
                        <Chip
                            label={DOCUMENT_STATE_MAPPING[row.state]['text']}
                            color={DOCUMENT_STATE_MAPPING[row.state]['color']}
                            size="small"
                            sx={{ ml: 1 }}
                        />
                    </>
                }
            }
        },
        {
            field: 'client_name',
            minWidth: 120,
            sortable: false,
            flex: 1,
            headerName: 'Client',
            renderCell: (params) => {
                const { row } = params
                if (row.__typename === 'GseWorkOrderType') {
                    return row.workOrderClient?.fullName || 'N/A'
                }
                if (row.__typename === 'DocumentType') {
                    return row.documentClient?.fullName || 'N/A'
                }
                if (row.__typename === 'FormType') {
                    return row.formClient?.fullName || 'N/A'
                }
            },
        },
        {
            field: 'created_at',
            minWidth: 90,
            sortable: false,
            // flex: 0.4,
            headerName: t`Cree le`,
            renderCell: params => params.row.createdAt,
        },
        {
            field: 'actions',
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            // flex: 0.1,
            // minWidth: 80,
            headerName: 'Actions',
            renderCell: params => {
                const { row } = params
                const { __typename } = row
                const client = row.workOrderClient || row.documentClient || row.formClient
                const document = __typename === 'GseWorkOrderType'
                    ? (row.signedDocument || {})
                    : (__typename === 'FormType')
                        ? (row.document || {})
                        : (__typename === 'DocumentType')
                            ? row
                            : {}
                    ;

                return (
                    <Stack direction="row" spacing={1} sx={{ whiteSpace: 'nowrap' }}>
                        <IconButton size="small">
                            {(getFileExtension(document.file) === "pdf") ?
                                <PictureAsPdfIcon sx={{ color: "text.primary" }} /> :
                                (["jpg", "jpeg", "png"].includes(getFileExtension(document.file))) ?
                                    <ImageOutlinedIcon sx={{ color: "text.primary" }} /> :
                                    <InsertDriveFileOutlinedIcon sx={{ color: "text.primary" }} />
                            }
                        </IconButton>

                        {/**
                        <IconButton
                            size="small"
                            disabled={true}
                        >
                            <PictureAsPdfIcon sx={{ color: "text.disabled" }} />
                        </IconButton>
                        */}

                        <IconButton
                            size="small"
                            onClick={() => history.push(`/documents/${document.id}/delete`)}
                        >
                            <DeleteIcon />
                        </IconButton>
                        {/** 
                        <IconButton
                            size="small"
                            onClick={() => history.push(`/clients/${client.id}/editaddress/${address.id}`)}
                            color="inherit"
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            // onClick={() => history.push(`/clients/${client.id}/address/${address.id}`)}
                            onClick={() => history.push(`/clients/${client.id}/addappointment/${address.id}`)}
                            color="inherit"
                        >
                            <CalendarTodayIcon />
                        </IconButton>

                        <IconButton
                            size="small"
                            // onClick={() => history.push(`/address/${address.id}`)}
                            onClick={() => history.push(`/clients/${client.id}/addsystem/${address.id}`)}
                            color="inherit"
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => history.push(`/clients/${client.id}/deletesystem/${address.id}`)}
                            color="inherit"
                        >
                            <DeleteIcon />
                        </IconButton>
                        */}
                    </Stack>
                )
            }
        }
    ].filter((column) => {
        return clientId ? column.field !== 'client_name' : true
    })

    const documents = data?.searchDocuments?.documents || []
    const count = data?.searchDocuments?.count || 0

    return (
        <Stack direction="column" spacing={2}>
            <MyTextField
                autoFocus={true}
                label="Rechercher"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <ZenDataGrid
                rows={documents}
                columns={columns}
                loading={loading || (debouncedSearch !== search)}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                paginationMode="server"
                pageSize={rowsPerPage}
                rowsPerPageOptions={[5, 10, 20, 50]}
                page={page}
                isRowSelectable={() => true}
                onPageSizeChange={e => setRowsPerPage(e.pageSize)}
                autoHeight
                hideFooterRowCount
                localeText={localeText}
                onRowClick={(e) => {
                    const { row } = e
                    const client = row.workOrderClient || row.documentClient || row.formClient
                    if (row.workOrderClient) history.push(`/gse/workorder/${row.id}/edit`)
                    if (row.documentClient) history.push(`/clients/${client.id}/documents/uploads/${row.id}`)
                    if (row.formClient) history.push(`/clients/${client.id}/documents/forms/${row.id}`)
                }}
            />
        </Stack>
    )
}