import React, { useState, useEffect } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Trans, t } from '@lingui/macro'

import {
    CustomCard,
    MyTextField,
    MultiSelect,
    BasicBreadcrumbs,
    CardButtons,
    GoBackButton,
    SaveButton,
    Loading
} from './Common'
import { UPDATE_SYSTEM } from "../Queries";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';

import { useSnackbar } from 'notistack';

import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';





const EditSystem = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { params } = useRouteMatch();
    const history = useHistory()
    const { id } = params;
    const [system, setSystem] = useState({
        systemId: id,
        contratEntretien: false,
        dateInstallation: "",
        omnium: false,
        addressId: "",
        identifier: "",
    })
    const [installers, setInstallers] = useState([])
    const { data: usersData, loading: usersLoading } = useQuery(gql`
        query {
            users{
                id
                username
                firstName
            }
        }
    `)
    const { data, loading, refetch } = useQuery(gql`
    query SystemQuery($id:ID!){
        system(id:$id){
            id
            type
            identifier
            client {
                id
                fullName
                addresses {
                    id
                    formatted
                }
            }
            dateInstallation
            omnium
            contratEntretien
            installers {
                id
                firstName
              }
            address {
                id
                formatted
            }
        }}
    `, { variables: { id: id } })

    const [UpdateSystem] = useMutation(UPDATE_SYSTEM)


    useEffect(() => {
        if (data) {
            setSystem({
                systemId: data.system.id,
                contratEntretien: data.system.contratEntretien,
                dateInstallation: data.system.dateInstallation,
                omnium: data.system.omnium,
                addressId: data.system.address.id,
                identifier: data.system.identifier,
            })
            setInstallers(data.system.installers.map(x => x.firstName))
        }
    }, [data])

    const handleFormChange = (key, val) => {
        setSystem({ ...system, [key]: val })
    }
    const handleSubmit = async () => {
        const temp = users.filter(x => installers.includes(x.firstName))
        const installers_ids = []
        temp.forEach(x => installers_ids.push(x.id))
        let variables = {
            ...system,
            installers: installers_ids
        }
        await UpdateSystem({ variables })
        await refetch()
        enqueueSnackbar(t`Système modifié`, { variant: 'success' });
        history.push(`/systems/${id}/view`)
    }

    if (loading || usersLoading) return <Loading />

    const users = usersData.users.filter(u => u.username !== "root");

    return <>
        <Container maxWidth="lg" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${data?.system?.client?.id}/view`, `${data?.system?.client?.fullName}`],
                [`/systems/${data?.system?.id}/view`, `${data?.system?.type}`],
                [t`Modification`]
            ]} />
            <CustomCard sx={{ maxWidth: 'md', mx: 'auto' }}>
                <CardHeader
                    title={t`Modifier Configuration Système`} />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DesktopDatePicker
                                label={t`Date d'installation`}
                                inputFormat="DD/MM/yyyy"
                                value={system.dateInstallation}
                                onChange={(e) => handleFormChange("dateInstallation", e.format("YYYY-MM-DD"))}
                                renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Identifiant`}
                                onChange={(e) => handleFormChange("identifier", e.target.value)}
                                value={system.identifier}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <MultiSelect
                                values={installers}
                                onChange={(e) => setInstallers([...e.target.value])}
                                users={users}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Adresse`}
                                onChange={(e) => handleFormChange("addressId", e.target.value)}
                                value={system.addressId}
                                required
                                select
                            >
                                {data.system.client.addresses.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>{x.formatted}</MenuItem>
                                ))}
                            </MyTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Omnium"
                                control={
                                    <Checkbox
                                        onChange={(e) => handleFormChange("omnium", e.target.checked)}
                                        checked={system.omnium}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t`Contrat d'entretien`}
                                control={
                                    <Checkbox
                                        onChange={(e) => handleFormChange("contratEntretien", e.target.checked)}
                                        checked={system.contratEntretien}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <CardButtons>
                        <GoBackButton />
                        <SaveButton disabled={loading} onClick={handleSubmit} />
                    </CardButtons>
                </CardContent>
            </CustomCard>
        </Container>
    </>
}

export default EditSystem;