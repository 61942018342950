import { AddressForm, ConfirmDialog, DEFAULT_ADDRESS } from "./Common";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation, gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useSnackbar } from 'notistack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton'

import { Trans, t } from '@lingui/macro'

import {
    Grid,
    Stack,
} from '@mui/material';

import {
    CustomCard,
    BasicBreadcrumbs,
    CardButtons,
    GoBackButton,
    SaveButton,
    Loading,
} from "./Common";

import { useMe } from "../CurrentUserContext";
import { ADDRESS_QUERY } from "../Queries";


export const EditAddress = ({ state, dispatch }) => {

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const { params } = useRouteMatch()
    const { me } = useMe()

    const { addressId, id } = params

    const [open, setOpen] = useState(false)

    const defaultAddr = state.selectedAddress
        ? state.selectedAddress
        : DEFAULT_ADDRESS

    const [currentAddress, setCurrentAddress] = useState({ ...defaultAddr })

    if (addressId && !state.selectedAddress) {

    }

    const { data, loading, error } = useQuery(ADDRESS_QUERY, {
        variables: { id: addressId },
        onCompleted: data => {
            const { address } = data
            setCurrentAddress({
                number: address.number,
                street: address.street,
                city: address.city,
                postalCode: address.postalCode,
                country: address.country,
            })
        },
    })

    const { refetch } = useQuery(gql`
        query ClientQuery($id:ID!){
        client(id:$id){
            id
            addresses {
            id
            formatted
            number
            street
            city
            postalCode
            city
            country
            }
          }
        }
    `, { variables: { id } })


    const [EditAddress] = useMutation(gql`
    mutation EditAddressMutation($addressId:ID!, $config:AddressInput!){
        editAddress(addressId:$addressId, config:$config){
          id
        }
      }
    `)

    const [deleteAddress] = useMutation(gql`
    mutation Delete($addressId:ID){
        delete(addressId:$addressId){
          ok
        }
      }
    `)

    const onSubmit = async () => {
        await EditAddress({ variables: { config: currentAddress, addressId } })
        await refetch()
        enqueueSnackbar(t`Adresse modifiée`, { variant: 'success' });
        history.push(`/clients/${id}/view`)
    }

    const onDelete = async () => {
        await deleteAddress({
            variables: {
                addressId
            }
        })
        await refetch()
        enqueueSnackbar(t`Adresse supprimée`, { variant: 'success' });
        history.push(`/clients/${id}/view`)
    }

    if ( loading ) return <Loading />
    if ( error ) return 'error'
    return (
        <>
            {/**
            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${id}/view`, `${data.address.client.fullName}`],
                    [t`Modifier Adresse`]
                ]} />
            </Container>
            
            <CardHeader
                title={t`Modifier Adresse`}
                action={me.profile.mayDeleteClient &&
                    <IconButton onClick={() => setOpen(true)} color="inherit">
                        <DeleteOutlineIcon />
                    </IconButton>
                }
            />
            */}
            <AddressForm address={currentAddress} onChange={a => setCurrentAddress(a)} />
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <GoBackButton />
                <SaveButton onClick={onSubmit} disabled={loading}>
                    {t`Sauvgarder`}
                </SaveButton>
            </Stack>

            <ConfirmDialog
                open={open}
                onClose={() => setOpen(false)}
                title={t`Suppression Adresse`}
                body={<Trans>
                    Attention vous êtes sur le point de supprimer cette adresse.<br />
                    Les éléments suivants relié à cette adresse seront également supprimés:
                    <ul>
                        <li>systèmes</li>
                        <li>rendez-vous</li>
                        <li>entretiens</li>
                        <li>commentaires</li>
                        <li>sim </li>
                        <li>documents</li>
                    </ul>
                    Veuillez confirmer l'action de suppression.
                </Trans>}
                onSubmit={onDelete}
            />
        </>
    )
}
