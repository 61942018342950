import React, { useState, useEffect, useRef, useReducer } from "react";
import { useMutation, useQuery, gql, fallbackHttpConfig } from "@apollo/client";
import { useRouteMatch, Redirect, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Trans, t } from '@lingui/macro'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {
    CustomCard,
    MyTextField,
    SaveButton,
    Loading,
    ZenDataGrid,
    useLocalStorage,
    ConfirmDialog,
} from './Common'

import CardHeader from '@mui/material/CardHeader';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import BoltIcon from '@mui/icons-material/Bolt';

import {
    Stack,
    Typography,
    MenuItem,
    FormControlLabel,
    IconButton,
    Input,
    Container,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Checkbox,
    FormGroup,
} from '@mui/material'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import moment from 'moment';
import 'moment/locale/fr'

import SignatureCanvas from 'react-signature-canvas'

import { frFR, enUS, nlNL } from '@mui/x-data-grid';
import { Settings } from "../Settings";
import { AnotherDemoForm, DemoForm, MyFormBuilder } from "./GenericForms";


const reducer = (state, action) => {
    switch (action.type) {
        case 'addLineItem':
            return {
                ...state,
                lineItems: [
                    ...state.lineItems,
                    {
                        label: '',
                        quantity: 0,
                        prixHTVA: 0,
                    }
                ]
            }
        case 'updateLineItem': {
            const result = {
                ...state,
                lineItems: [...state.lineItems],
            }
            result.lineItems[action.index] = action.value
            return result
        }
        case 'removeLineItem':
            let newLineItems = state.lineItems.filter((item, index) => {
                if (index !== action.index) return item;
            })
            return {
                ...state,
                lineItems: newLineItems,
            }
        case 'set':
            let newState = {
                ...state,
                [action.key]: action.value,
            }
            if ((action.key === 'pricingVatRate') || (action.key === 'pricingAmountHtva')) {
                newState = {
                    ...newState,
                    pricingTotalTvac: (Number(newState['pricingAmountHtva']) + (Number(newState['pricingVatRate']) / 100) * Number(newState['pricingAmountHtva'])).toFixed(2),
                }
            }
            return newState;
        default:
            throw new Error();
    }
}

const ORDER_TYPE_MAPPING = {
    "REPAIR": "Depannage",
    "MAINTENANCE": "Entretien",
    "MODIFICATION": "Modification",
    "OTHER": "Autre",
}

const MAPPING = {
    centralDeSurveillance: 'Centrale de surveillance',
    tensionSecteur: 'Tension secteur',
    tensionDeCharge: 'Tension de charge',
    consummation: 'Consummation',
    cameraJourNuit: 'Camera jour/nuit',
    disqueDur: 'Disque dur',
    connectionInternet: 'Connection internet',
    miseAuPoint: 'Mise au point',
    etanchieteCamera: 'Etanchiete camera',
    ventilationNVR: 'Ventilation NVR',

    centraleSansFil: 'Centrale sans fil',
    capaciteBatterieCentrale: 'Capacite batterie centrale',
    capaciteBatterieSirene: 'Capacite batterie sirene',
    startTime: 'Heure de debut',
    endTime: 'Heure de fin',
}

export const UPDATE_GSE_WORK_ORDER_MUTATION = gql`mutation UpdateGseWorkOrderMutation ( $id: ID!, $data: GenericScalar!, $state: String! ) {
    updateGseWorkOrder ( id: $id, data: $data, state: $state ) {
        id
        data
        state
        client {
            id
            fullName
        }
    } 
}
`

const getMapping = s => {
    return MAPPING?.[s] || s;
}

const formatDate = date => {
    if (date === null) return ''
    const yyyy = date.getFullYear()
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const dd = String(date.getDate()).padStart(2, '0')
    return `${yyyy}-${mm}-${dd}`
}

const today = () => {
    return formatDate(new Date())
}

const GseWorkOrderForm = ({ inputData, onSave, onDelete, createdBy, workorderState, uuid }) => {
    const initialData = {
        // meta:
        showPricing: inputData?.showPricing || 'false',
        serviceCompleted: inputData?.serviceCompleted || 'true',

        // -- pricing questions
        pricingAmountHtva: 0,
        pricingVatRate: 21,
        pricingTotalTvac: 0,

        // -- 
        orderType: inputData?.orderType || 'MAINTENANCE',

        // verifSystemeReglementation: inputData?.verifSystemeReglementation || false,
        // majFirmware: inputData?.majFirmware || false,

        realisation: inputData?.realisation || '',
        date: inputData?.date || today(),
        remarque: inputData?.remarque || '',
        lineItems: inputData?.lineItems || [],
        startTime: inputData?.startTime || '',
        endTime: inputData?.endTime || '',
        alimentation: inputData?.alimentation || {
            tensionDeCharge: '',
            tensionSecteur: '',
            consummation: '',
            centraleSansFil: 'false',
            capaciteBatterieCentrale: '',
            capaciteBatterieSirene: '',
        },
        commTests: inputData?.commTests || {
            RTC: inputData?.commTests?.RTC || false,
            GSM: inputData?.commTests?.GSM || false,
            GPRS: inputData?.commTests?.GPRS || false,
            ADSL: inputData?.commTests?.ADSL || false,
            Vocal: inputData?.commTests?.Vocal || false,
            SMS: inputData?.commTests?.SMS || false,
            centralDeSurveillance: inputData?.commTests?.centralDeSurveillance || false,
        },
        vidTests: {
            cameraJourNuit: inputData?.vidTests?.cameraJourNuit || false,
            disqueDur: inputData?.vidTests?.disqueDur || false,
            connectionInternet: inputData?.vidTests?.connectionInternet || false,
            miseAuPoint: inputData?.vidTests?.miseAuPoint || false,
            etanchieteCamera: inputData?.vidTests?.etanchieteCamera || false,
            ventilationNVR: inputData?.vidTests?.ventilationNVR || false,
            autre: inputData?.vidTests?.autre || '',
        },
        wiredAlarmTests: inputData?.wiredAlarmTests || [
            { label: 'Détecteur périmétrique', count: 0, checked: false },
            { label: 'Détecteur actif et volumétrique', count: 0, checked: false },
            { label: 'Clavier de commande', count: 0, checked: false },
            { label: 'Sirènes et flash', count: 0, checked: false },
            { label: 'Détecteurs speciaux', count: 0, checked: false },
            { label: 'Détecteur de fumée', count: 0, checked: false },
            { label: 'Détecteur technique', count: 0, checked: false },
            { label: 'Détecteur extérieur', count: 0, checked: false },
            { label: 'Télécommande', count: 0, checked: false },
            { label: 'Extension', count: 0, checked: false },
        ],
        wirelessAlarmTests: inputData?.wirelessAlarmTests || [
            { label: 'Détecteur périmétrique', count: 0, checked: false },
            { label: 'Détecteur actif et volumétrique', count: 0, checked: false },
            { label: 'Clavier de commande', count: 0, checked: false },
            { label: 'Sirènes et flash', count: 0, checked: false },
            { label: 'Supervision', count: 0, checked: false },
            { label: 'Détecteur de fumée', count: 0, checked: false },
            { label: 'Détecteur technique', count: 0, checked: false },
            { label: 'Détecteur extérieur', count: 0, checked: false },
            { label: 'Télécommande', count: 0, checked: false },
            { label: 'Jamming', count: 0, checked: false },
        ],
        alarmTestsOther: inputData?.alarmTestsOther || '',
        paymentMethod: inputData?.paymentMethod || '',
        clientSignature: inputData?.clientSignature || '',
    }

    const [state, dispatch] = useReducer(reducer, initialData);

    const [page, setPage] = useState('form')

    const [iframeLoading, setIframeLoading] = useState(true)

    const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false)

    return (<>
        {workorderState === 'PENDING' && (
            <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
                <MetaForm state={state} dispatch={dispatch} />
                <MissionForm state={state} dispatch={dispatch} />
                <LineItemForm2 state={state} dispatch={dispatch} />
                <AlimentationForm state={state} dispatch={dispatch} />
                <AlarmTestsForm state={state} dispatch={dispatch} />
                <CommTestsForm state={state} dispatch={dispatch} />
                <VidTestsForm state={state} dispatch={dispatch} />
                <PaymentForm state={state} dispatch={dispatch} />
                <Stack direction="row" spacing={2}>
                    <SaveButton onClick={() => {
                        onSave(state);
                    }} />
                    <Button onClick={() => {
                        onSave(state, 'AWAITING_SIGNATURE');
                    }}>Signature Client</Button>
                    <Button variant="outlined" color="secondary" onClick={() => setConfirmDeleteIsOpen(true)}>
                        Supprimmer
                    </Button>
                    <ConfirmDialog
                        open={confirmDeleteIsOpen}
                        onClose={() => setConfirmDeleteIsOpen(false)}
                        title={t`Supprimer la fiche de travail`}
                        body={<Trans>Veuillez confirmer l'action de suppression.</Trans>}
                        onSubmit={onDelete}
                    />
                </Stack>
            </Container>
        )}

        {workorderState === 'AWAITING_SIGNATURE' && (
            <>
                <Container component="main" maxWidth={false}>
                    {iframeLoading && <Loading />}
                    <iframe
                        style={{ width: '100%', minHeight: 800, display: iframeLoading ? 'none' : 'initial' }}
                        onLoad={() => setIframeLoading(false)}
                        src={`${Settings.BACKEND_URL}/export/printable/workorder-preview/${uuid}/`}>
                    </iframe>
                    <SummaryAndClientSignature state={state} dispatch={dispatch} onSave={onSave} createdBy={createdBy} />
                </Container>
            </>
        )}
    </>)
}

const MetaForm = ({ state, dispatch }) => (
    <CustomCard>
        <CardHeader title="CONFIG FICHE" />
        <Grid spacing={2} sx={{ m: 2 }}>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <MyTextField
                    label="Capturer et montrer des prix sur la fiche"
                    value={state.showPricing}
                    onChange={e => dispatch({ type: 'set', key: 'showPricing', value: e.target.value })}
                    select
                >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                </MyTextField>
            </Grid>

            <Grid item xs={12}>
                <MyTextField
                    label="Prestation terminée"
                    value={state.serviceCompleted}
                    onChange={e => dispatch({ type: 'set', key: 'serviceCompleted', value: e.target.value })}
                    select
                >
                    <MenuItem value={'true'}>Oui</MenuItem>
                    <MenuItem value={'false'}>Non</MenuItem>
                </MyTextField>
            </Grid>
        </Grid>
    </CustomCard>
)


const SummaryAndClientSignature = ({ state, dispatch, onSave, createdBy }) => {
    return (
        <>
            {/**
            <CustomCard>
                <CardHeader title={t`Fiche de travail`} />
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">{`Technicien`}</Typography>
                    <Typography variant="body1">{createdBy.firstName} {createdBy.lastName}</Typography>

                    <Typography variant="h5">Type de prestation: {ORDER_TYPE_MAPPING[state.orderType]}</Typography>

                    <Typography variant="body1">Realisation: {state.realisation ? state.realisation : '/'}</Typography>
                    <Typography variant="body1">Remarque: {state.remarque ? state.remarque : '/'}</Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">Date: {state.date}</Typography>
                    <Typography variant="h6">Heure debut: {state.startTime}</Typography>
                    <Typography variant="h6">Heure fin: {state.endTime}</Typography>
                </Box>
            </CustomCard>

            <CustomCard>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">ALIMENTATION</Typography>
                    <Table size="small">
                        <TableBody>
                            {Object.entries(state.alimentation).map(([key, value], i) => (
                                <TableRow>
                                    <TableCell>{getMapping(key)}</TableCell>
                                    <TableCell align="right">{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </CustomCard>

            <CustomCard>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">TESTS DES COMMUNICATIONS</Typography>
                    <Table size="small">
                        <TableBody>
                            {Object.entries(state.commTests).map(([key, value], i) => (
                                <TableRow>
                                    <TableCell sx={{ fontWeight: value ? 'bold' : 'regular', color: value ? 'inherit' : 'text.disabled' }}>{getMapping(key)}</TableCell>
                                    <TableCell align="right">{value ? <CheckBoxIcon color="primary" /> : <IndeterminateCheckBoxIcon color="disabled" />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </CustomCard>

            <CustomCard>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">TESTS DES CONTROLES (VIDEO SURVEILLANCE)</Typography>
                    <Table size="small">
                        <TableBody>
                            {Object.entries(state.vidTests).map(([key, value], i) => (
                                <TableRow>
                                    <TableCell sx={{ fontWeight: value ? 'bold' : 'regular', color: value ? 'inherit' : 'text.disabled' }}>{getMapping(key)}</TableCell>
                                    <TableCell align="right">{value ? <CheckBoxIcon color="primary" /> : <IndeterminateCheckBoxIcon color="disabled" />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </CustomCard>

            <CustomCard>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">TESTS ALARME (FILAIRE)</Typography>
                    <Table size="small">
                        <TableBody>
                            {state.wiredAlarmTests.map((test, i) => (
                                test.checked && (
                                    <TableRow>
                                        <TableCell>{test.label}</TableCell>
                                        <TableCell align="right">{test.count}</TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </CustomCard>

            <CustomCard>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">TESTS ALARME (SANS FIL)</Typography>
                    <Table size="small">
                        <TableBody>
                            {state.wirelessAlarmTests.map((test, i) => (
                                test.checked && (
                                    <TableRow>
                                        <TableCell>{test.label}</TableCell>
                                        <TableCell align="right">{test.count}</TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </CustomCard>
            */}

            <SignatureForm state={state} dispatch={dispatch} />

            <Stack direction="row" spacing={2}>
                <SaveButton onClick={() => {
                    onSave(state);
                }} />

                <Button
                    onClick={async () => {
                        dispatch({
                            type: 'set',
                            key: 'clientSignature',
                            value: '',
                        })
                        await onSave(state, 'PENDING');
                    }}
                    variant="filled"
                >Revenir en arriere</Button>

                <Button
                    onClick={async () => {
                        await onSave(state)
                        await onSave(state, 'SIGNED')
                    }}
                    variant="filled"
                >Finaliser</Button>

                {/**
                <Button onClick={() => {
                    onSave(state);
                    setPage('client_signature');
                }}>Finaliser & Signer</Button>
                */}
            </Stack>
        </>
    )
}

const AlimentationForm = ({ state, dispatch }) => {
    const { alimentation } = state;
    return (
        <CustomCard>
            <CardHeader title={t`ALIMENTATION`} />
            <Grid container sx={{ p: 2 }} spacing={1}>
                <Grid item sm={6} md={4}>
                    <MyTextField
                        label={t`Centrale sans fil`}
                        value={alimentation.centraleSansFil}
                        onChange={e => dispatch({
                            type: 'set',
                            key: 'alimentation',
                            value: {
                                ...alimentation,
                                centraleSansFil: e.target.value,
                            }
                        })}
                        select
                    >
                        <MenuItem value={'true'}>Oui</MenuItem>
                        <MenuItem value={'false'}>Non</MenuItem>
                    </MyTextField>
                </Grid>

                {(alimentation.centraleSansFil === 'false') && (<>
                    <Grid item sm={6} md={4}>
                        <MyTextField
                            label={t`Tension de charge (V)`}
                            value={alimentation.tensionDeCharge}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'alimentation',
                                value: {
                                    ...alimentation,
                                    tensionDeCharge: e.target.value,
                                }
                            })}
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <MyTextField
                            label={t`Tension de secteur (V)`}
                            value={alimentation.tensionSecteur}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'alimentation',
                                value: {
                                    ...alimentation,
                                    tensionSecteur: e.target.value,
                                }
                            })}
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <MyTextField
                            label={t`Consommation (mA)`}
                            value={alimentation.consummation}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'alimentation',
                                value: {
                                    ...alimentation,
                                    consummation: e.target.value,
                                }
                            })}
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <MyTextField
                            label={t`Capacite batterie centrale (A)`}
                            value={alimentation.capaciteBatterieCentrale}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'alimentation',
                                value: {
                                    ...alimentation,
                                    capaciteBatterieCentrale: e.target.value,
                                }
                            })}
                        />
                    </Grid>
                    <Grid item sm={6} md={4}>
                        <MyTextField
                            label={t`Capacity batterie sirene (A)`}
                            value={alimentation.capaciteBatterieSirene}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'alimentation',
                                value: {
                                    ...alimentation,
                                    capaciteBatterieSirene: e.target.value,
                                }
                            })}
                        />
                    </Grid>
                </>)}
            </Grid>
        </CustomCard>
    )
}

const PaymentForm = ({ state, dispatch }) => {
    const PAYMENT_METHODS = [
        'Paiement anticipatif',
        'Paiement a reception facture',
        'Paiement Bancontact',
        'Paiement cash',
    ]
    return (
        <CustomCard>
            <CardHeader title="PAIEMENT" />
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MyTextField
                            label="Methode de paiement"
                            value={state.paymentMethod}
                            onChange={e => dispatch({ type: 'set', key: 'paymentMethod', value: e.target.value })}
                            select
                        >
                            <MenuItem value="">--</MenuItem>
                            {PAYMENT_METHODS.map(method => <MenuItem value={method}>{method}</MenuItem>)}
                        </MyTextField>
                    </Grid>
                    {state.showPricing === 'true' && (<>
                        <Grid item sm={6}>
                            <MyTextField
                                label="Montant HTVA €"
                                type="number"
                                value={state.pricingAmountHtva}
                                onChange={e => dispatch({ type: 'set', key: 'pricingAmountHtva', value: e.target.value })}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <MyTextField
                                label="TVA %"
                                type="number"
                                value={state.pricingVatRate}
                                onChange={e => dispatch({ type: 'set', key: 'pricingVatRate', value: e.target.value })}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <MyTextField
                                label="Montant TVAC (TTC) €"
                                type="number"
                                disabled={true}
                                value={state.pricingTotalTvac}
                            />
                        </Grid>
                    </>)}
                </Grid>
            </Box>
        </CustomCard>
    )
}

const MissionForm = ({ state, dispatch }) => {
    const injectRealisation = str => dispatch({
        type: 'set',
        key: 'realisation',
        value: state.realisation.indexOf(str) === -1 ? `${state.realisation}${str}\n` : state.realisation,
    })

    return (
        <CustomCard>
            <CardHeader title={t`MISSION`} />
            <Box sx={{ p: 2 }}>
                <MyTextField
                    label={t`PRESTATION DEMANDÉE`}
                    value={state.orderType}
                    sx={{ mb: 2 }}
                    onChange={e => dispatch({
                        type: 'set',
                        key: 'orderType',
                        value: e.target.value,
                    })}
                    select
                >
                    <MenuItem value="REPAIR">{t`Depannage`}</MenuItem>
                    <MenuItem value="MAINTENANCE">{t`Entretien`}</MenuItem>
                    <MenuItem value="MODIFICATION">{t`Modification`}</MenuItem>
                    <MenuItem value="OTHER">{t`Autre...`}</MenuItem>
                </MyTextField>

                {state.orderType === "OTHER" && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="p">
                            Veuillez decrire la prestation dans le section <span style={{ fontWeight: 'bold' }}>Réalisation</span>.
                        </Typography>
                    </Box>
                )}

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12} md={6} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="yyyy-MM-dd"
                                value={state.date}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'set',
                                        key: 'date',
                                        value: formatDate(e),
                                    })
                                }}
                                renderInput={params => <MyTextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <MyTextField
                            label="Heure de debut"
                            value={state.startTime}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'startTime',
                                value: e.target.value,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <MyTextField
                            label="Heure de fin"
                            value={state.endTime}
                            onChange={e => dispatch({
                                type: 'set',
                                key: 'endTime',
                                value: e.target.value,
                            })}
                        />
                    </Grid>
                </Grid>

                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Button size="small" variant="contained" color="success" startIcon={<BoltIcon />} onClick={() => injectRealisation(`Entretien annuel de votre système d'alarme selon la réglementation.`)}>Entretien annuel</Button>
                    <Button size="small" variant="contained" color="success" startIcon={<BoltIcon />} onClick={() => injectRealisation('Vérification du système selon la réglementation.')}>Verif. systeme regl.</Button>
                    <Button size="small" variant="contained" color="success" startIcon={<BoltIcon />} onClick={() => injectRealisation('Mise à jour firmware centrale et transmetteur.')}>MÀJ firmware</Button>
                </Stack>

                {/**
                <FormGroup sx={{ pb: 1 }}>
                    <FormControlLabel
                        control={<Checkbox checked={state.verifSystemeReglementation} onChange={e => dispatch({type: 'set', key: 'verifSystemeReglementation', value: !state.verifSystemeReglementation})} />}
                        label="Vérification du système selon la réglementation"
                        />
                    <FormControlLabel
                        control={<Checkbox checked={state.majFirmware} onChange={e => dispatch({type: 'set', key: 'majFirmware', value: !state.majFirmware})} />}
                        label="Mise à jour firmware centrale et transmetteur"
                        />
                </FormGroup>
                */}

                <Stack direction="column" spacing={2}>
                    <MyTextField
                        label={t`Réalisation`}
                        multiline
                        rows={4}
                        value={state.realisation}
                        onChange={e => dispatch({
                            type: 'set',
                            key: 'realisation',
                            value: e.target.value,
                        })}
                    />
                    <MyTextField
                        label={t`Remarques`}
                        multiline
                        rows={2}
                        value={state.remarque}
                        onChange={e => dispatch({
                            type: 'set',
                            key: 'remarque',
                            value: e.target.value,
                        })}
                    />
                </Stack>
            </Box>
        </CustomCard>
    )
}


const TextfieldWithCheckbox = ({ }) => {
    return (
        <MyTextField
            InputProps={{
                startAdornment: <IconButton />
            }}

        />
    )
}


const CommTestsForm = ({ state, dispatch }) => {
    const { commTests } = state;
    return (
        <CustomCard>
            <CardHeader title={t`TESTS DES COMMUNICATIONS`} />
            <Box sx={{ p: 2 }}>
                <Stack direction="row">
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, RTC: e.target.checked } })} checked={commTests.RTC} />} label="RTC" />
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, GSM: e.target.checked } })} checked={commTests.GSM} />} label="GSM" />
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, GPRS: e.target.checked } })} checked={commTests.GPRS} />} label="GPRS" />
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, ADSL: e.target.checked } })} checked={commTests.ADSL} />} label="ADSL" />
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, Vocal: e.target.checked } })} checked={commTests.Vocal} />} label="Vocal" />
                </Stack>
                <Stack direction="row">
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, SMS: e.target.checked } })} checked={commTests.SMS} />} label="SMS" />
                    <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'commTests', value: { ...commTests, centralDeSurveillance: e.target.checked } })} checked={commTests.centralDeSurveillance} />} label="Centrale de surveillance" />
                </Stack>
            </Box>
        </CustomCard>
    )
}

const AlarmTestsForm = ({ state, dispatch }) => {
    const { wiredAlarmTests, wirelessAlarmTests } = state
    return (
        <CustomCard>
            <CardHeader title="TESTS ET CONROLES (ALARME)" />
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6" sx={{ mb: 2 }}>SYSTEME FILAIRE</Typography>
                    <Stack direction="column" spacing={2}>
                        {wiredAlarmTests.map(({ label, count, checked }, i) => {
                            return <MyTextField
                                type="number"
                                label={label}
                                value={count}
                                disabled={!checked}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton
                                            onClick={(e) => {
                                                const isChecked = !checked;

                                                dispatch({
                                                    type: 'set',
                                                    key: 'wiredAlarmTests',
                                                    value: [
                                                        ...state.wiredAlarmTests.slice(0, i),
                                                        {
                                                            ...state.wiredAlarmTests[i],
                                                            checked: isChecked,
                                                            count: 0,
                                                        },
                                                        ...state.wiredAlarmTests.slice(i + 1),
                                                    ],
                                                })

                                                if (isChecked) {
                                                    console.log(e)
                                                    // @TODO attempt to set focus on the parent
                                                    // input element, as to automatically open the ipad kb..
                                                }
                                            }}
                                        >
                                            {checked
                                                ? <RadioButtonCheckedIcon />
                                                : <RadioButtonUncheckedIcon />
                                            }
                                        </IconButton>
                                    )
                                }}
                                onChange={e => {
                                    dispatch({
                                        type: 'set',
                                        key: 'wiredAlarmTests',
                                        value: [
                                            ...state.wiredAlarmTests.slice(0, i),
                                            {
                                                ...state.wiredAlarmTests[i],
                                                count: e.target.value,
                                            },
                                            ...state.wiredAlarmTests.slice(i + 1),
                                        ],
                                    })
                                }}
                                onFocus={e => {
                                    e.target.select()
                                }}
                            />
                        })}
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6" sx={{ mb: 2 }}>SYSTEME SANS FIL</Typography>
                    <Stack direction="column" spacing={2}>
                        {wirelessAlarmTests.map(({ label, count, checked }, i) => {
                            return <MyTextField
                                type="number"
                                label={label}
                                value={count}
                                disabled={!checked}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton
                                            onClick={(e) => {
                                                const isChecked = !checked;

                                                dispatch({
                                                    type: 'set',
                                                    key: 'wirelessAlarmTests',
                                                    value: [
                                                        ...state.wirelessAlarmTests.slice(0, i),
                                                        {
                                                            ...state.wirelessAlarmTests[i],
                                                            checked: isChecked,
                                                            count: 0,
                                                        },
                                                        ...state.wirelessAlarmTests.slice(i + 1),
                                                    ],
                                                })

                                                if (isChecked) {
                                                    console.log(e)
                                                    // @TODO attempt to set focus on the parent
                                                    // input element, as to automatically open the ipad kb..
                                                }
                                            }}
                                        >
                                            {checked
                                                ? <RadioButtonCheckedIcon />
                                                : <RadioButtonUncheckedIcon />
                                            }
                                        </IconButton>
                                    )
                                }}
                                onChange={e => {
                                    dispatch({
                                        type: 'set',
                                        key: 'wirelessAlarmTests',
                                        value: [
                                            ...state.wirelessAlarmTests.slice(0, i),
                                            {
                                                ...state.wirelessAlarmTests[i],
                                                count: e.target.value,
                                            },
                                            ...state.wirelessAlarmTests.slice(i + 1),
                                        ],
                                    })
                                }}
                                onFocus={e => {
                                    e.target.select()
                                }}
                            />
                        })}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <MyTextField
                        label="Autre ..."
                        multiline
                        rows={3}
                        value={state.alarmTestsOther}
                        onChange={e => dispatch({
                            type: 'set',
                            key: 'alarmTestsOther',
                            value: e.target.value,
                        })}
                    />
                </Grid>
            </Grid>
        </CustomCard>
    )
}


const LineItemForm2 = ({ state, dispatch }) => {
    return (<>
        <CustomCard>
            <CardHeader title={t`Fournitures`} />
            <Box sx={{ p: 2, pt: 0 }}>
                {state.lineItems.map((lineItem, i) => (<>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item sm={3}>
                            <MyTextField
                                label={t`Quantité`}
                                value={lineItem.quantity}
                                onChange={e => {
                                    dispatch({
                                        type: 'updateLineItem',
                                        index: i,
                                        value: {
                                            ...lineItem,
                                            quantity: e.target.value,
                                        }
                                    })
                                }}
                                type={"number"}
                            />
                        </Grid>
                        <Grid item sm={state.showPricing === 'true' ? 6 : 9}>
                            <MyTextField
                                label={t`Fourniture`}
                                value={lineItem.label}
                                onChange={e => {
                                    dispatch({
                                        type: 'updateLineItem',
                                        index: i,
                                        value: {
                                            ...lineItem,
                                            label: e.target.value,
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        {state.showPricing === 'true' && (
                            <Grid item sm={3}>
                                <MyTextField
                                    label={t`Prix HTVA/pc`}
                                    value={lineItem.prixHTVA}
                                    onChange={e => {
                                        dispatch({
                                            type: 'updateLineItem',
                                            index: i,
                                            value: {
                                                ...lineItem,
                                                prixHTVA: e.target.value,
                                            }
                                        })
                                    }}
                                    type={"number"}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>))}

                <Button
                    sx={{ mt: 2 }}
                    onClick={() => dispatch({ type: 'addLineItem' })}
                    variant="outlined"
                >{t`Ajouter une ligne`}</Button>
            </Box>
        </CustomCard>
    </>)
}


const VidTestsForm = ({ state, dispatch }) => {
    const { vidTests } = state
    return (
        <CustomCard>
            <CardHeader title={t`TESTS DES CONTROLES (VIDEO SURVEILLANCE)`} />
            <Grid container>
                <Grid item sm={4}>
                    <Stack direction="column" sx={{ p: 2 }}>
                        <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, cameraJourNuit: e.target.checked } })} hecked={vidTests.cameraJourNuit} />} label="Cameras jour/nuit" />
                        <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, disqueDur: e.target.checked } })} checked={vidTests.disqueDur} />} label="Disque dur" />
                        <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, connectionInternet: e.target.checked } })} checked={vidTests.connectionInternet} />} label="Connection Internet" />
                    </Stack>
                </Grid>
                <Grid item sm={4}>
                    <Stack direction="column" sx={{ p: 2 }}>
                        <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, miseAuPoint: e.target.checked } })} checked={vidTests.miseAuPoint} />} label="Mise au point" />
                        <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, etanchieteCamera: e.target.checked } })} checked={vidTests.etanchieteCamera} />} label="Etanchiete camera" />
                        <FormControlLabel control={<Checkbox onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, ventilationNVR: e.target.checked } })} checked={vidTests.ventilationNVR} />} label="Ventilation NVR" />
                    </Stack>
                </Grid>
                <Grid item sm={4} sx={{ p: 2 }}>
                    <MyTextField
                        label="Autre ..."
                        value={vidTests.autre}
                        // onChange={e => setVidTests({...vidTests, autre: e.target.value})}
                        onChange={e => dispatch({ type: 'set', key: 'vidTests', value: { ...vidTests, autre: e.target.value } })}
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid>
        </CustomCard>
    )
}

export const SignatureForm = ({ state, dispatch, title, allowRemoteSignature, backgroundColor }) => {
    title = title || t`SIGNATURE CLIENT`
    // const width = 1120;
    const width = 'calc(210mm - 50mm)'
    const height = 220;
    const canvasRef = useRef(null)
    const { clientSignature } = state
    allowRemoteSignature = allowRemoteSignature || false
    const [showRemoteForm, setShowRemoteForm] = useState(false)
    useEffect(() => {
        if (!canvasRef?.current) return;
        if (!clientSignature.length) {
            canvasRef.current.clear();
            return;
        }
        canvasRef.current.fromDataURL(clientSignature)
    }, [canvasRef, clientSignature])
    useEffect(() => {
        const handleResize = () => {
            canvasRef.current.fromDataURL(clientSignature)
        }
        window.addEventListener('resize', handleResize)
        return _ => window.removeEventListener('resize', handleResize)
    }, [clientSignature])

    backgroundColor = backgroundColor || 'rgba(1,1,1,.1)'

    return (
        <Box sx={{ mb: 2 }}>
            <div style={{ margin: '.5rem -1rem 0 -1rem', padding: '0 1rem 1rem 1rem', color: 'white', backgroundColor, }}>
                <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
                    <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                        <Typography sx={{ color: "text.disabled", fontWeight: 'bold', fontSize: '1.2rem' }}>{title}</Typography>
                        {/**
                    <div>
                        <Typography variant="body1">Le client confirme l'exactitude des prestations et informations indiquees.</Typography>
                        <Typography variant="body1">Le client confirme nous confier la gestion de ses codes techniques.</Typography>
                        <Typography variant="body1">Le client accepte la telegestion de son systeme d'alarme.</Typography>
                        <Typography variant="body1">Le client accepte les conditions du control de maintenance et entretien</Typography>
                    </div>
                    */}
                        <Button
                            // sx={{ m: 'auto' }}
                            size="small"
                            // variant=""
                            onClick={() => {
                                dispatch({
                                    type: 'set',
                                    key: 'clientSignature',
                                    value: '',
                                })
                            }}
                        >effacer &amp; recommencer</Button>
                    </Stack>
                    <div style={{ margin: 'auto', width, height, backgroundColor: 'white', border: '2px dashed gray' }}>
                        <SignatureCanvas
                            ref={canvasRef}
                            penColor="blue"
                            canvasProps={{
                                style: {
                                    width,
                                    height,
                                    // border: '2px dashed darkgrey'
                                },
                            }}
                            onEnd={e => {
                                dispatch({
                                    type: 'set',
                                    key: 'clientSignature',
                                    value: canvasRef.current.toDataURL(),
                                })
                            }}
                        />
                    </div>
                </Stack>
            </div>
        </Box>
    )
}


export const EditGseWorkOrderComponent = () => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const { params } = useRouteMatch()
    const { orderId } = params;

    const [waitingForSave, setWaitingForSave] = useState(false);

    const [updateGseWorkOrderMutation] = useMutation(UPDATE_GSE_WORK_ORDER_MUTATION)

    const { data, error, loading, refetch } = useQuery(gql`
        query GseWorkOrderQuery ( $gseWorkOrderId: ID! ) {
            gseWorkOrder ( id: $gseWorkOrderId ) {
                id
                uuid
                data
                state
                signedDocument {
                    id
                    file
                }
                createdBy {
                    id
                    username
                    email
                    firstName
                    lastName
                }
                createdAt
            }
        }
    `, {
        variables: {
            gseWorkOrderId: orderId,
        },
        onError: data => {
            console.log('here with error onError', data)
        }
    })

    const [deleteWorkorder] = useMutation(gql`
        mutation DeleteGseWorkOrderMutation ( $id: ID! ) {
            deleteGseWorkOrder ( id: $id ) {
                id
            }
        }
    `)

    useEffect(() => {
        if (data) {
            const { signedDocument, state } = data.gseWorkOrder
            const signedOrClosed = state === 'SIGNED' || state === 'CLOSED'
            const hasSignedDoc = Boolean(signedDocument?.id)
            if (hasSignedDoc && signedOrClosed) {
                history.replace(`/document/${signedDocument.id}`)
            }
        }
    }, [data])

    if (loading) return <Loading />
    if (error) return 'error...'

    const { gseWorkOrder } = data

    const onDelete = async () => {
        await deleteWorkorder({ variables: { id: orderId } })
        history.replace('/gse/workorders')
    }

    /**
     * warning: naming makes things a bit confusing here. reducer state vs work order state vs data etc... 
     */
    const onSave = async (newData, newState) => {
        setWaitingForSave(true)
        try {
            const { errors } = await updateGseWorkOrderMutation({
                variables: { id: orderId, data: newData, state: newState || '' },
            });
            if (errors && Boolean(errors.length)) throw 'updateGseWorkOrder failed'
            enqueueSnackbar(`Le formulaire a été enregistré`)
        } catch {
            enqueueSnackbar('Erreur d\'enregistrement/génération de fichier. Veuillez recommencer plus tard.')
        }
        await refetch()
        setWaitingForSave(false)

    }

    return (<>
        {waitingForSave
            ? <Loading />
            : <GseWorkOrderForm
                workorderState={gseWorkOrder.state}
                inputData={gseWorkOrder.data}
                onSave={onSave}
                onDelete={onDelete}
                createdBy={gseWorkOrder.createdBy}
                uuid={gseWorkOrder.uuid}
            />
        }
    </>)
}


export const WO_TYPE_MAP = {
    MAINTENANCE: 'Entretien',
    REPAIR: 'Dépannage',
    UNKNOWN: 'Non spécifié',
}

export const WO_STATE_MAP = {
    SIGNED: 'Signé',
    AWAITING_SIGNATURE: 'Signature en attente',
    PENDING: 'En cours',
    CLOSED: 'Clôturé',
    UNKNOWN: 'Non spécifié',
}

export const GseWorkOrderListing = () => {
    const [first, setFirst] = useState(10)
    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)

    const [includedStates, setIncludedStates] = useState([])

    const [q, setQ] = useState('')

    const history = useHistory();

    const [lang] = useLocalStorage('lang', 'fr');
    const LOCALE_TEXT_MAP = {
        'fr': frFR.components.MuiDataGrid.defaultProps.localeText,
        'en': enUS.components.MuiDataGrid.defaultProps.localeText,
        'nl': nlNL.components.MuiDataGrid.defaultProps.localeText,
    }
    const localeText = LOCALE_TEXT_MAP[lang]

    const { data, loading, error, refetch } = useQuery(gql`
        query GseWorkOrderListingQuery ( $q: String, $first: Int, $skip: Int, $states: [String!]! ) {
            searchGseWorkOrders ( q: $q, first: $first, skip: $skip, states: $states ) {
                count
                results {
                    id
                    state
                    data
                    createdAt
                    client {
                        id
                        fullName
                    }
                    signedDocument {
                        id
                        file
                    }
                }
            }
        }
    `, {
        variables: {
            q,
            first,
            skip,
            states: includedStates,
        },
        fetchPolicy: 'no-cache',
    })

    if (error) return 'error...'

    const searchGseWorkOrders = data?.searchGseWorkOrders
    const count = searchGseWorkOrders?.count || 0
    const results = searchGseWorkOrders?.results || []

    const flatResults = results.map(r => ({
        id: r.id,
        state: WO_STATE_MAP[r.state || 'UNKNOWN'],
        serviceCompleted: r.data?.serviceCompleted !== 'false',
        serviceCompletedStr: r.data?.serviceCompleted === 'false' ? 'Non terminé' : '',
        createdAt: r.createdAt,
        orderType: WO_TYPE_MAP[r.data?.orderType || 'UNKNOWN'],
        clientName: r.client?.fullName,
    }))

    const handleSizeChange = number => {
        setFirst(number)
    }

    const handlePageChange = number => {
        setPage(number)
        setSkip(number * first)
    }

    const toggleIncludedState = state => {
        if (includedStates.indexOf(state) >= 0) {
            setIncludedStates(includedStates.filter(s => s !== state))
        } else {
            setIncludedStates([...includedStates, state])
        }
    }

    return (<>
        {/**
        <MyFormBuilder />
        <DemoForm />
        */}
        <CustomCard>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={12} sm={12}>
                    <MyTextField
                        label={`Rechercher une fiche de travail`}
                        value={q}
                        onChange={e => setQ(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Stack direction="row" spacing={1}>
                        {[
                            { key: 'PENDING', label: WO_STATE_MAP['PENDING'] },
                            { key: 'AWAITING_SIGNATURE', label: WO_STATE_MAP['AWAITING_SIGNATURE'] },
                            { key: 'SIGNED', label: WO_STATE_MAP['SIGNED'] },
                            { key: 'CLOSED', label: WO_STATE_MAP['CLOSED'] },
                        ].map(((state, i) => (
                            <FormControlLabel
                                key={i}
                                label={state.label}
                                control={<Checkbox
                                    onChange={e => toggleIncludedState(state.key)}
                                />
                                } />
                        )))}
                    </Stack>
                </Grid>
            </Grid>
        </CustomCard>

        <CustomCard>
            {flatResults.length === 0 && (
                <>
                    <Box sx={{ p: 2 }}>
                        {t`sans resultat`}
                    </Box>
                </>
            )}
            {flatResults.length > 0 && (
                <Paper style={{ width: '100%' }}>
                    <ZenDataGrid
                        loading={loading}
                        rows={flatResults}
                        columns={[
                            {
                                field: 'createdAt',
                                headerName: t`Créé`,
                                sortable: false,
                                valueGetter: params => moment(params.row.createdAt).format('YYYY-MM-DD'),
                            },
                            // {
                            //     field: 'id',
                            //     headerName: t`ID`,
                            //     sortable: false,
                            //     minWidth: 15,
                            // },
                            {
                                field: 'state',
                                headerName: 'Etat',
                                sortable: false,
                                width: 150,
                            },
                            {
                                field: 'clientName',
                                headerName: 'Client',
                                sortable: false,
                                minWidth: 150,
                            },
                            {
                                field: 'orderType',
                                headerName: t`Prestation`,
                                sortable: false,
                                minWidth: 150,
                                // flex: 1,
                            },
                            {
                                field: 'serviceCompletedStr',
                                headerName: '',
                                sortable: false,
                                flex: 1,
                            }
                        ]}
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        onRowClick={({ row }) => history.push(`/gse/workorder/${row.id}/edit`)}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        onPageSizeChange={handleSizeChange}
                        pageSize={first}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowCount={count}
                        page={page}
                        isRowSelectable={() => true}
                        density="compact" // "compact" | "standard" | "comfortable"
                        autoHeight
                        hideFooterRowCount
                        localeText={localeText}
                        getRowClassName={params => {
                            const { row } = params
                            return (row.serviceCompleted === false)
                                ? 'warning'
                                : ''
                                ;
                        }}
                    />
                </Paper>
            )}
        </CustomCard>
    </>)
}

// export const AddGseWorkOrderButton = ({ clientId }) => {
//     const history = useHistory()
//     const { enqueueSnackbar } = useSnackbar();
// 
//     const [ createGseWorkOrder, { loading, error }] = useMutation(gql`
//         mutation CreateGseWorkOrderMutation ( $clientId: ID! ) {
//             createGseWorkOrder ( clientId: $clientId ) {
//                 id
//             }
//         }
//     `)
// 
//     const onClick = async () => {
//         const { data } = await createGseWorkOrder({ variables: { clientId }});
//         history.push(`/gse/workorder/${data.createGseWorkOrder.id}/edit/`);
//         enqueueSnackbar(`Fiche de travail cree`, { variant: 'success' })
//     }
// 
//     return (<>
//         <Button onClick={onClick}>
//             Create GSE Work Order
//         </Button>
//     </>)
// }
