import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useRouteMatch, NavLink } from "react-router-dom";
import {
    useQuery,
    gql,
    useMutation,
    // useMutation,
} from '@apollo/client';

import { t } from '@lingui/macro'


// import Form from '@rjsf/core';
import Form, { Templates } from '@rjsf/mui';

// import { RJSFSchema } from '@rjsf/utils';

import validator from '@rjsf/validator-ajv8';

import CodeEditor from '@uiw/react-textarea-code-editor';

// import { Wrapper } from "@googlemaps/react-wrapper"; // @TODO can be removed?
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import ReactMapGL, { Marker, FullscreenControl } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import '../App.css'

import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Typography,
    Tabs,
    Tab,
    Box,
    Button,
    MenuItem,
    Chip,
    Stack,
    InputAdornment,
    IconButton,
    Autocomplete,

    FormControl,
    OutlinedInput,
    InputLabel,
    Select,

} from '@mui/material';
import Container from '@mui/material/Container';
import {
    // LocalFireDepartmentOutlined,
    // WorkspacePremiumOutlined,
    // FingerprintOutlined,
    ShieldOutlined,
    // PolicyOutlined,
    WarningAmber,
    EditOutlined,
}
    from '@mui/icons-material';

import {
    useLocalStorage,
    AddressForm,
    Loading,
    MyTextField,
    LinkTab,
    DOCUMENT_STATE_MAPPING,
} from './Common'

import mapboxgl from "mapbox-gl";
import { useSnackbar } from 'notistack';

import { Settings } from '../Settings';

// import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import {
    Switch, Route, useRouteMatch, Redirect, NavLink, useParams, useHistory,
    // useHistory,
} from 'react-router-dom';

import { SelectClient } from './Tasks';
import { SignatureForm } from './WorkOrders';
import { useMe } from '../CurrentUserContext';


const StateChip = ({ state }) => <Chip
    // label={document.state}
    label={DOCUMENT_STATE_MAPPING[state]['text']}
    color={DOCUMENT_STATE_MAPPING[state]['color']}
    size="small" sx={{ ml: 1 }} />


const extendUiSchema = (uiSchema, templates) => {
    // recursively extend the uiSchema with the templates:

    let newUiSchema = JSON.parse(JSON.stringify(uiSchema))

    if (!uiSchema) return uiSchema
    Object.keys(uiSchema).forEach(key => {
        const field = uiSchema[key]
        // console.log(key, field)

        if (key === 'x:ui:ObjectFieldTemplate') {
            newUiSchema['ui:ObjectFieldTemplate'] = templates[field]
        }
        if (typeof field === 'object') {
            newUiSchema[key] = extendUiSchema(newUiSchema[key], templates)
        }


        // if (field['ui:ArrayFieldTemplate']) {
        //     field['ui:ArrayFieldTemplate'] = templates[field['ui:ArrayFieldTemplate']]
        // }
        // if (field['ui:FieldTemplate']) {
        //     field['ui:FieldTemplate'] = templates[field['ui:FieldTemplate']]
        // }

        if (field.properties) {
            field.properties = extendUiSchema(field.properties, templates)
        }
        // if (field.items) {
        //     extendUiSchema(field.items, templates)
        // }
    })
    console.log('uiSchema:', uiSchema)
    return newUiSchema
}


export const MyJsonEditor = ({ title, value, setValue, style }) => {
    const theme = useTheme()
    const [errorMessage, setErrorMessage] = useState(null)
    const hasErrors = !!errorMessage
    // theme.palette.error.main = 'red'
    return (
        <>
            <Paper style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: 10,
                border: `2px solid ${hasErrors ? theme.palette.error.main : theme.palette.text.secondary}`,
                overflow: 'auto',
                ...style,
            }}>
                <Typography variant="h6" gutterBottom>{title}</Typography>
                <CodeEditor
                    data-color-mode="dark"
                    value={JSON.stringify(value, null, 2)}
                    onChange={e => {
                        // setHtmlTemplate(e.target.value)
                        // hasUnsavedChanges(true)
                        try {
                            setValue(JSON.parse(e.target.value))
                            setErrorMessage(null)
                        } catch (e) {
                            setErrorMessage(e.message)
                        }
                    }}
                    language={'json'}
                    padding={10}
                    style={{
                        fontSize: 12,
                        height: '100%',
                        // backgroundColor: '#f5f5f5',
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                    }}
                />
                {hasErrors && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </Paper>

        </>
    )
}


const StartEndTimeField = props => {
    const [state, setState] = useState(props.formData || {
        start_time: '',
        end_time: '',
    })
    const onChange = (name) => {
        return e => {
            const newState = { ...state, [name]: e.target.value }
            setState(newState)
            props.onChange(newState)
        }
    }
    return <>
        <Grid container spacing={2}>
            <Grid item sm={6}>
                <MyTextField
                    label={t`Heure début`}
                    type="text"
                    value={state.start_time}
                    onChange={onChange('start_time')}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
            </Grid>
            <Grid item sm={6}>
                <MyTextField
                    label={t`Heure fin`}
                    // type="time"
                    type="text"
                    value={state.end_time}
                    onChange={onChange('end_time')}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
            </Grid>
        </Grid>
    </>
}



const InProgressFormComponent = props => {
    const {
        client,
        address,
        systems,
        template,

        formSchema,
        formUiSchema,
        values,
        onSubmit,
        form,

        showConfigManager,
    } = props

    const { data, loading, error } = useQuery(gql`
        query ClientAddressesAndSystemsQuery ( $id: ID! ) {
            client (id: $id) {
                id
                addresses {
                    id
                    formatted
                    systems {
                        id
                        template {
                            id
                            type
                        }
                    }
                }
            }
        }
    `, { variables: { id: client.id } })

    const [addressValue, setAddressValue] = useState(address?.id)
    const [selectedSystems, setSelectedSystems] = useState([])

    const showAddressSelection = (!address && template.requiresAddress)
    const showSystemsSelection = (systems.length === 0 && template.requiresSystems)

    if (loading) return <Loading />
    if (error) return <>{error.message}</>

    const { addresses } = data.client

    if (showAddressSelection) {
        return (
            <Stack direction="column" spacing={2}>
                <Autocomplete
                    disablePortal
                    value={addressValue}
                    onChange={(e, value) => {
                        setAddressValue(value)
                    }}
                    options={addresses.map(a => ({ id: a.id, label: a.formatted }))}
                    renderInput={(params) => <MyTextField {...params} label="Adresse" />}
                />
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onSubmit({
                                address: addressValue.id,
                            })
                        }}
                    >
                        Valider
                    </Button>
                </Stack>
            </Stack>
        )
    }

    const systemsSelection = data.client.addresses.find(a => a.id === address?.id)?.systems || []

    if (showSystemsSelection) {
        return (
            <>
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6" gutterBottom>
                        {address.formatted}
                    </Typography>
                    <FormControl>
                        <InputLabel>{t`Systemes`}</InputLabel>
                        <Select
                            multiple
                            value={selectedSystems}
                            onChange={(e) => {
                                setSelectedSystems(e.target.value)
                            }}
                            input={<OutlinedInput label={t`Systemes`} />}
                        >
                            {systemsSelection.map(system => (
                                <MenuItem key={system.id} value={system.id}>
                                    {system.template.type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onSubmit({
                                systems: selectedSystems,
                            })
                        }}
                    >
                        Valider
                    </Button>
                </Stack>
            </>
        )
    }

    return (
        <Grid container spacing={2}>

            {template.requiresAddress && (
                <Grid item md={12}>
                    <Typography variant="h6" gutterBottom>
                        {address.formatted}
                    </Typography>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={async () => {
                            setAddressValue(null)
                            await onSubmit({
                                address: '',
                                systems: [],
                            })
                        }}
                    >
                        {t`Changer l'adresse`}
                    </Button>
                </Grid>
            )}

            {template.requiresSystems && (
                <Grid item md={12}>
                    <ul>
                        {systems.map(system => (
                            <li key={system.id}>
                                {system.template.type}
                            </li>
                        ))}
                    </ul>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={async () => {
                            setAddressValue(null)
                            await onSubmit({
                                systems: [],
                            })
                        }}
                    >
                        {t`Changer les systèmes`}
                    </Button>
                </Grid>
            )}

            {!showConfigManager && (
                <Grid item md={12}>
                    <MyForm
                        schema={formSchema}
                        uiSchema={formUiSchema}
                        formData={values}
                        onSubmit={onSubmit}
                        onError={() => console.log("errors")}
                        formContext={{
                            formId: form.id,
                            client,
                        }}
                    />
                </Grid>
            )}

            {/**
            {showConfigManager && (
                <>
                    <Grid item md={12}>
                        <Stack direction="column" spacing={2}>
                            <Paper sx={{ maxHeight: 200, overflow: 'auto' }}>
                                <CodeEditor
                                    data-color-mode="dark"
                                    value={jinjaTemplate}
                                    onChange={e => {
                                        setJinjaTemplate(e.target.value)
                                    }}
                                    language={'html'}
                                    padding={10}
                                    style={{
                                        fontSize: 12,
                                        height: '100%',
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                    }}
                                />
                            </Paper>
                            <Stack spacing={2} direction="row">
                                <Button
                                    variant="contained"
                                    onClick={async () => {
                                        await onSubmitFormTemplateConfig();
                                    }}
                                >
                                    Save template
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ p: 2 }}>
                            <Stack direction="column" spacing={2}>
                                <MyForm
                                    schema={formSchema}
                                    uiSchema={formUiSchema}
                                    formData={values}
                                    // validator={validator}
                                    onSubmit={onSubmit}
                                    onError={() => console.log("errors")}
                                    formContext={{
                                        formId: form.id,
                                        client,
                                    }}
                                />
                                <MyJsonEditor
                                    title="Form data"
                                    value={values}
                                    onChange={e => { }}
                                    setValue={e => { }}
                                />
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item md={8}>
                        {iframeLoading && <Loading />}
                        <iframe
                            ref={configPdfRef}
                            style={{
                                marginBottom: '1rem',
                                width: '100%',
                                minHeight: 1200,
                                display: iframeLoading ? 'none' : 'initial',
                            }}
                            onLoad={() => setIframeLoading(false)}
                            src={`${Settings.BACKEND_URL}/export/printable/form-preview/${uuid}/`}>
                        </iframe>
                    </Grid>
                </>
            )}
            */}
        </Grid>
    )
}


export const FormComponent = () => {
    const { me } = useMe()
    const { enqueueSnackbar } = useSnackbar()
    const { id } = useParams()
    const [clientSignature, setClientSignature] = useState('')
    const [jinjaTemplate, setJinjaTemplate] = useState('')
    const { data, loading, error, refetch } = useQuery(gql`
        query FormComponentQuery($id: ID!) {
            form (id: $id) {
                id
                uuid
                values
                address {
                    id
                    formatted
                    systems {
                        id
                        template {
                            id
                            type
                        }
                    }
                }
                systems {
                    id
                    template {
                        id
                        type
                    }
                }
                state
                createdAt
                createdBy {
                    id
                    username
                }
                document {
                    id
                    file
                }
                clientSignature
                client {
                    id
                    fullName
                    addresses {
                        id
                        formatted
                        systems {
                            id
                        }
                    }
                    organization {
                        id
                        name
                        lang
                    }
                }
                template {
                    id
                    displayName
                    formSchema
                    formUiSchema
                    template
                    requiresAddress
                    requiresSystems
                }
            }
        }
    `, {
        fetchPolicy: 'no-cache',
        variables: { id },
        onCompleted: ({ form }) => {
            const { template } = form
            setJinjaTemplate(template.template)
        },
    })
    const [updateForm] = useMutation(gql`
        mutation UpdateFormMutation($input: UpdateFormValuesInput!) {
            updateForm(input: $input) {
                id
                values
                address {
                    id
                }
                systems {
                    id
                    template {
                        id
                        type
                    }
                }
            }
        }
    `, {
        fetchPolicy: 'no-cache',
    })
    const [updateFormTemplate] = useMutation(gql`
        mutation UpdateFormTemplateMutation($input: UpdateFormTemplateInput!) {
            updateFormTemplate(input: $input) {
                id
            }
        }
    `, {
        fetchPolicy: 'no-cache',
    })
    const [iframeLoading, setIframeLoading] = useState(true);
    const [showConfigManager, setShowConfigManager] = useState(false)
    const configPdfRef = useRef(null)

    if (loading) return <Loading />
    if (error) return 'error...'

    const { form } = data
    const { uuid, template, values, client, state, document, address, systems } = form
    const { formSchema, formUiSchema } = template

    const refreshIframe = () => {
        const newUrl = `${Settings.BACKEND_URL}/export/printable/form-preview/${uuid}/?cb=${Date.now()}`
        if (configPdfRef?.current) {
            configPdfRef.current.src = newUrl;
        }
    }

    const onSubmitFormTemplateConfig = async () => {
        await updateFormTemplate({
            variables: {
                input: {
                    id: template.id,
                    template: jinjaTemplate,
                }
            }
        })
        await refetch()
        enqueueSnackbar('Template saved', { variant: 'success' })
        refreshIframe();
    }

    const onSubmit = async ({ formData, address, systems }) => {
        const input = {
            id,
            values: formData,
            address,
            systems,
        }
        console.log(`input = `, input)
        const { data, error } = await updateForm({
            variables: {
                input: {
                    ...input,
                    ...(showConfigManager ? {} : { state: 'AWAITING_SIGNATURE' })
                }
            }
        })
        await refetch()
        if (error) {
            alert('here')
            console.log(error)
            return
        }
        enqueueSnackbar('Saved', { variant: 'success' })
        refreshIframe()
    }
    const onSubmitSignature = async () => {
        await updateForm({
            variables: {
                input: {
                    id,
                    state: null,
                    clientSignature,
                }
            }
        })
        if (!showConfigManager) {
            await updateForm({
                variables: {
                    input: {
                        id,
                        state: 'SIGNED',
                        clientSignature,
                    }
                }
            })
        }
        await refetch()
        enqueueSnackbar('Document signed!', { variant: 'success' })
    }

    return (
        <>
            {me.profile.maySeeDemos && (
                <Stack sx={{ mb: 2 }} spacing={2} direction="row">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowConfigManager(!showConfigManager)}
                    >
                        Config Manager
                    </Button>
                </Stack>
            )}

            <Box sx={{ mb: 2 }}>
                <Typography variant="h4">{template.displayName} <StateChip state={state} /></Typography>
                <Typography variant="caption">Cree le {form.createdAt}</Typography>
            </Box>

            {state === 'IN_PROGRESS' && (
                <InProgressFormComponent
                    form={form}
                    client={client}
                    address={address}
                    systems={systems}
                    template={template}
                    formSchema={formSchema}
                    formUiSchema={formUiSchema}
                    values={values}
                    onSubmit={onSubmit}
                    showConfigManager={false}
                />
            )}

            {state === 'AWAITING_SIGNATURE' && (
                <>
                    {iframeLoading && <Loading />}
                    <iframe
                        style={{
                            marginBottom: '1rem',
                            width: '100%',
                            minHeight: 800,
                            display: iframeLoading ? 'none' : 'initial',
                        }}
                        onLoad={() => setIframeLoading(false)}
                        src={`${Settings.BACKEND_URL}/export/printable/form-preview/${uuid}/`}>
                    </iframe>

                    <SignatureForm
                        state={{ clientSignature }}
                        dispatch={(({ value }) => {
                            setClientSignature(value);
                        })}
                    />

                    <Button
                        variant="contained"
                        onClick={onSubmitSignature}
                        disabled={!clientSignature}
                    >
                        Submit
                    </Button>
                </>
            )}

            {state === 'SIGNED' && (
                <>
                    {iframeLoading && <Loading />}

                    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            onClick={async () => {
                                enqueueSnackbar('Envoi en cours...', { variant: 'info' })
                            }}
                        >
                            Envoyer par email
                        </Button>
                        <Button
                            variant="contained"
                            onClick={async () => {
                                enqueueSnackbar('Impression en cours...', { variant: 'info' })
                            }}
                        >
                            Imprimer
                        </Button>
                    </Stack>

                    <iframe
                        style={{
                            marginBottom: '1rem',
                            width: '100%',
                            minHeight: 800,
                            display: iframeLoading ? 'none' : 'initial',
                        }}
                        onLoad={() => setIframeLoading(false)}
                        src={`${Settings.MEDIA_URL}/${document.file}`} />
                </>
            )}
        </>
    )
}


const LineItemField = props => {
    const [state, setState] = useState(props.formData || {
        name: '',
        quantity: '1',
        price_per_unit: '0',
        price_per_time: '0', // not sure what this is actually, it's "P.T." ?
        // PRIX TOTALE, DUUUUH
    })
    const onChange = (name, coerceNumber) => {
        return e => {
            const value = coerceNumber
                ? (isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)
                : e.target.value;
            const newState = { ...state, [name]: value }
            setState(newState)
            props.onChange(newState)
        }
    }

    const quantity = +state.quantity
    const price_per_unit = +state.price_per_unit
    const total_price = (!isNaN(quantity) && !(isNaN(price_per_unit)))
        ? (quantity * price_per_unit).toFixed(2)
        : '0.0'

    useEffect(() => {
        const newState = { ...state, price_per_time: total_price }
        setState(newState)
        props.onChange(newState)
    }, [total_price])

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6}>
                <MyTextField
                    label="Name"
                    type="text"
                    value={state.name}
                    onChange={onChange('name', false)}
                />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <MyTextField
                    label="QTE."
                    type="number"
                    value={state.quantity}
                    onChange={onChange('quantity', true)}
                />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <MyTextField
                    label="P.U."
                    type="number"
                    value={state.price_per_unit}
                    onChange={onChange('price_per_unit', true)}
                />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
                <MyTextField
                    label="P.T."
                    disabled={true}
                    type="number"
                    value={total_price}
                />
            </Grid>
        </Grid>
    </>
}


const ClientAddressField = props => {
    const { formContext, uiSchema } = props
    const { client } = formContext
    const [state, setState] = useState(props.formData || '')
    const hasSelectedAddress = Boolean(state)

    const { addresses } = client

    useEffect(() => {
        if (addresses.length == 1) {
            setState(addresses[0].id)
            props.onChange(addresses[0].id)
        }
    }, [addresses])

    const getAddressById = id => addresses.find(a => a.id === id)

    const fieldTitle = uiSchema['ui:title'] || t`Adresse`

    if (hasSelectedAddress) {
        return (
            <MyTextField
                label={fieldTitle}
                type="text"
                disabled={true}
                value={getAddressById(state).formatted}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end" color="default" onClick={e => {
                                setState('')
                                props.onChange('')
                            }}>
                                <EditOutlined />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        )
    }

    return (
        <Autocomplete
            id="address-autocomplete"
            label={fieldTitle}
            disablePortal
            options={addresses.map(a => ({ id: a.id, label: a.formatted }))}
            renderInput={params => {
                console.log('params', params)
                return <MyTextField {...params} label={t`Adresse`} />
            }}
            onChange={(e, value) => {
                setState(value.id)
                props.onChange(value.id)
            }}
        />
    )
}


const AutocompleteClientField = props => {
    const { formContext, uiSchema } = props
    const [search, setSearch] = useState('')
    const { data, loading, error } = useQuery(gql`
        query Clients ( $search: String ) {
            searchClients ( search: $search, first: 10, skip: 0 ) {
                count
                clients {
                    id
                    fullName
                    icons {
                        label
                        icon
                        color
                    }
                }
            }
        }
    `, {
        variables: {
            search,
        }
    })
    const [state, setState] = useState(props.formData || '')
    const hasSelectedClient = Boolean(state)

    if (loading) return <Loading />
    if (error) return 'error loading clients'

    const { count, clients } = data.searchClients
    const fieldTitle = uiSchema['ui:title'] || t`Client`

    const getClientById = id => clients.find(c => c.id === id)

    if (hasSelectedClient) {
        return (
            <MyTextField
                label={fieldTitle}
                type="text"
                disabled={true}
                value={getClientById(state).fullName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end" color="default" onClick={e => {
                                setState('')
                                props.onChange('')
                            }}>
                                <EditOutlined />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        )
    }

    return (
        <Autocomplete
            id="client-choice-autocomplete"
            label={fieldTitle}
            disablePortal
            options={clients.map(a => ({ id: a.id, label: a.fullName }))}
            renderInput={params => {
                return <MyTextField {...params} label={t`Client`} />
            }}
            onChange={(e, value) => {
                setState(value.id)
                props.onChange(value.id)
            }}
            inputValue={search}
            onInputChange={(e, value) => {
                setSearch(value)
            }}
        />
    )
}


// const AttributeValuesField = props => {
//     console.log('in attribute values field with', props)
//     return (<>
//         meh.
//         <pre>{JSON.stringify(Object.keys(props), null, 2)}</pre>
//         <pre>{JSON.stringify(props.uiSchema, null, 2)}</pre>
//         <pre>{JSON.stringify(props.schema, null, 2)}</pre>
//         <pre>{JSON.stringify(props.formData, null, 2)}</pre>
//     </>)
// }


const MissionTemplate = props => {
    console.log('in template with', props)
    return (
        <Paper sx={{ p: 2, border: '1px solid blue', borderColor: 'primary' }}>
            <Typography variant="h6" gutterBottom>MISSION DETAILS</Typography>
            {props.properties.map(prop => (
                <div style={{ border: '1px solid yellow' }} key={prop.content.key}>{prop.content}</div>
            ))}
        </Paper>
    )
}


const MissionField = props => {
    console.log('here in mission field with', props)
    return null
}


const CustomFieldTemplate = (props) => {
    const { uiSchema, formContext } = props;
    // Check if the field should be hidden based on its ui:options.condition configuration
    const { data } = formContext;
    const shouldHide = () => {
        const { condition } = (uiSchema || {})['ui:options'] || {};
        if (condition) {
            const { fieldName, operator, value } = condition;
            const fieldValue = data[fieldName];
            console.log(`shouldHide: ${fieldName} | "${fieldValue}" ${operator} "${value}" ?`)

            if (undefined === fieldValue) return true

            switch (operator) {
                case '==':
                    return fieldValue != value;
                case '!=':
                    return fieldValue == value;
                default:
                    return false;
            }
        }
        return false;
    };
    if (shouldHide()) return null;
    return <Templates.FieldTemplate {...props} />
}


export const MyForm = ({ uiSchema, ...props }) => <Form
    validator={validator}
    fields={{
        start_end_time: StartEndTimeField,
        line_item: LineItemField,
        address: ClientAddressField,
        client: AutocompleteClientField,
        // MissionField,

        // attributes: AttributeValuesField,
    }}
    // templates={{
    // this works, but:
    // FieldTemplate: CustomFieldTemplate,
    // this sadly doesn't work for string mapped uiSchema templates, see:
    // https://github.com/rjsf-team/react-jsonschema-form/issues/1680#issuecomment-1501382275
    // I've hacked in this feature but augmenting the uiSchema with the template functions references
    // for now, see extendUiSchema():
    // }}

    // uiSchema={uiSchema}

    uiSchema={extendUiSchema(uiSchema, {
        'MissionTemplate': MissionTemplate,
    })}
    {...props}
>
    <Stack spacing={2} direction="row">
        <Button variant="contained" color="primary" type="submit">
            {t`Sauvgarder`}
        </Button>
    </Stack>
</Form>