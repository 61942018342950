import React, {
    useMemo,
    useState,
} from 'react';
import {
    useHistory,
} from 'react-router-dom';
import { Trans, t } from '@lingui/macro'
import {
    Stack,
    Typography,
    Card,
    CardContent,
    CardActions,
    Box,
    Button,
    Menu,
    MenuItem,
    Paper,
    Grid,
    Divider,
    Checkbox,
    Link,
    FormControlLabel,
    IconButton,
    Alert,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';

import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MapIcon from '@mui/icons-material/Map';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import {
    useQuery,
    gql,
} from "@apollo/client";

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import {
    toFrDate,
    MyTextField,
    Loading,
    IconBarComponent,
} from './Common';

import {
    SystemMapComponent,
} from './Demos'

import {
    useLocalStorage,
} from './Common'

import { useTheme } from '@mui/material/styles';

import moment from 'moment';


const prettyPrintTechnicians = technicians => {
    const firstNames = technicians.map(t => t.firstName)
    if (technicians.length === 0) return "PAS DE TECHNICIEN";
    if (technicians.length === 1) return firstNames[0];
    const [theFirst, ...theRest] = firstNames
    const commaSeparated = theRest.join(', ')
    return `${commaSeparated} et ${theFirst}`
}


const SystemCard = ({ system, overdue, highlight }) => {
    highlight = Boolean(highlight)
    const theme = useTheme();
    const history = useHistory();
    const { upcomingMaintenanceAppointments } = system;
    const hasAppointments = upcomingMaintenanceAppointments.length > 0;
    const { client } = system

    // const textColor = overdue ? "warning.main" : hasAppointments ? "secondary.main" : "primary.main"
    const textColor = hasAppointments ? "secondary.main" : "primary.main"

    const cardSx = highlight
        ? { boxShadow: `0px 0px .5rem 0 ${theme.palette.primary.main}` }
        : {};

    return (
        <Card sx={cardSx}>
            <CardContent>
                <Typography color="text.primary">
                    {client.fullName}
                </Typography>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography variant="h5" sx={{ color: textColor }}>
                        {toFrDate(system.maintenanceDueDate)}
                    </Typography>
                    {!hasAppointments && (
                        <Button color="primary"
                            variant="outlined"
                            startIcon={<AddCircleIcon />}
                            onClick={() => history.push(`/systems/${system.id}/addappointment`)}>RDV</Button>
                    )}
                </Stack>

                {overdue && (
                    <Box mt={1} mx={-1}>
                        <Alert severity="warning"><Trans>ENTRETIEN EN RETARD</Trans></Alert>
                    </Box>
                )}

                <Box my={1}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <Typography variant="body1">
                            {system.type}
                        </Typography>
                        <IconBarComponent icons={system.icons} fontSize="small" />
                    </Stack>
                    <Typography variant="body2" color="text.disabled" mb={2}>
                        <Trans>installe le</Trans> {toFrDate(system.dateInstallation)}
                    </Typography>
                </Box>

                {hasAppointments && (
                    <>
                        <Box mt={1}>
                            <Divider />
                        </Box>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center"
                            onClick={() => history.push(`/appointments/${upcomingMaintenanceAppointments[0].id}`)}
                            sx={{
                                color: 'text.disabled',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: 'text.primary',
                                },
                            }}
                        >
                            <EventIcon fontSize="large" />
                            <Stack
                                direction="column"
                                py={1}
                            >
                                <Typography variant="body2" sx={{ color: "inherit" }} p={0} m={0}>
                                    <Trans>Rendez-vous le</Trans>
                                </Typography>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                    {moment(upcomingMaintenanceAppointments[0].datetime).format('LLL')}
                                </Typography>
                                <Typography className="who" variant="body2">
                                    {prettyPrintTechnicians(upcomingMaintenanceAppointments[0].technicians)}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box mb={1}>
                            <Divider />
                        </Box>
                    </>
                )}


                {(client.telephone || client.email) && (
                    <Stack direction="column" my={2}>
                        <Typography>
                            <Trans>Tel.</Trans>
                            {client.telephone && <Link color="primary" underline="none" href={`tel://${client.telephone}`}> {client.telephone}</Link>}
                        </Typography>
                        {client.email && (
                            <Link href={`email://${client.email}`} color="inherit" underline="none">
                                {client.email}
                            </Link>
                        )}
                    </Stack>
                )}

                <Typography variant="body">
                    {system.address.formatted}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    color={hasAppointments ? "secondary" : "primary"}
                    onClick={() => history.push(`/clients/${client.id}/view`)}
                >
                    <Trans>Fiche Client</Trans>
                </Button>
            </CardActions>
        </Card>
    )
}


const DispatchAddressCards = ({ selectedSystemIds, systems, overdueSystems }) => {
    const allSystems = [...overdueSystems, ...systems]
    const selectedSystems = allSystems.filter(s => selectedSystemIds.indexOf(s.id) > -1)

    console.log("RENDERING CARDS!", systems.length, selectedSystems.length, allSystems.length)

    return (
        <>
            <div style={{
                // see: https://medium.com/code-writers/minmax-repeat-auto-fill-and-auto-fit-in-grid-layout-18e2912da399
                // this create the grid of cards with dynamic (filling) widths:
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, auto))',
                gap: '1rem',
            }}>

                {selectedSystems.length > 0 ? (
                    <>
                        {selectedSystems.map((system, i) => (
                            <SystemCard
                                key={system.id}
                                system={system}
                                highlight={true} />
                        ))}
                    </>
                ) : (
                    <>
                        {overdueSystems.filter(s => selectedSystemIds.indexOf(s.id) === -1).map((system, i) => (
                            <SystemCard
                                key={system.id}
                                system={system}
                                overdue={true} />
                        ))}

                        {systems.filter(s => selectedSystemIds.indexOf(s.id) === -1).map((system, i) => (
                            <SystemCard
                                key={system.id}
                                system={system}
                                overdue={true} />
                        ))}
                    </>
                )}

                {/**
                {(selectedSystemIds.length > 0) && overdueSystems.filter(s => selectedSystemIds.indexOf(s.id) > -1).map((system, i) => (
                    <SystemCard key={system.id} system={system} highlight={true} />
                ))}

                {(selectedSystemIds.length > 0) && systems.filter(s => selectedSystemIds.indexOf(s.id) > -1).map((system, i) => (
                    <SystemCard key={system.id} system={system} highlight={true} />
                ))}

                {overdueSystems.filter(s => selectedSystemIds.indexOf(s.id) === -1).map((system, i) => (
                    <SystemCard key={system.id} system={system} overdue={true} />
                ))}

                {systems.filter(s => selectedSystemIds.indexOf(s.id) === -1).map((system, i) => (
                    <SystemCard key={system.id} system={system} />
                ))}
                */}
            </div>
        </>
    )
}


export const NewDispatchDashboard = () => {
    const today = () => moment().format('YYYY-MM-DD')
    const [nextDays, setNextDays] = useLocalStorage('dashboard.nextDays', 30);
    const [includeThoseWithAppointments, setIncludeThoseWithAppointments] = useState(true)
    const [showMap, setShowMap] = useLocalStorage('dashboard.showMap', false)
    const [showDetails, setShowDetails] = useLocalStorage('dashboard.showDetails', false)
    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(moment(fromDate).add(nextDays, 'days').format('YYYY-MM-DD'))
    const [selectedSystemIds, setSelectedSystemIds] = useState([])
    const toggleSelectedSystem = system => {
        const { id } = system;
        if (selectedSystemIds.indexOf(id) >= 0) {
            setSelectedSystemIds(selectedSystemIds.filter(sid => id !== sid))
        } else {
            setSelectedSystemIds([...selectedSystemIds, id])
        }
    }
    const onNextDaysChange = value => {
        setNextDays(value)
        setFromDate(today())
        setToDate(moment().add(value, 'days').format('YYYY-MM-DD'))
    }
    const { data, error, loading } = useQuery(gql`
        fragment extendedSystemFields on ExtendedSystemType {
            id
            type
            maintenanceDueDate
            dateInstallation
            client {
                id
                fullName
                telephone
                email
            }
            icons {
                name
                color
                label
            }
            address {
                id
                lat
                lng
                formatted
            }
            upcomingMaintenanceAppointments {
                id
                type
                datetime
                technicians {
                    id
                    firstName
                }
            }
            config
            omnium
            sim {
                id
            }
        }
        query SystemsWithMaintenanceRequired ( $fromDate: String!, $toDate: String!, $includeThoseWithAppointments: Boolean ) {
            systemsWithMaintenanceRequired( fromDate: $fromDate, toDate: $toDate, includeThoseWithAppointments: $includeThoseWithAppointments ) {
                matchedSystems {
                    ...extendedSystemFields
                }
                overdueSystems {
                    ...extendedSystemFields
                }
            }
        }
    `, {
        variables: {
            includeThoseWithAppointments,
            fromDate,
            toDate,
        },
        fetchPolicy: "no-cache",
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const menuIsOpen = Boolean(anchorEl);
    const onSelect = value => {
        onNextDaysChange(value);
        setAnchorEl(null);
    }

    const systems = data?.systemsWithMaintenanceRequired?.matchedSystems || [];
    const overdueSystems = data?.systemsWithMaintenanceRequired?.overdueSystems || [];
    const addresses = systems.map(system => system.address)
    const overdueAddresses = overdueSystems.map(system => system.address)

    ///////// const addressCards = useMemo(() => {
    /////////     console.log(`in cards with ${systems.length} ${overdueSystems.length} ${selectedSystemIds.length}`)
    /////////     return <DispatchAddressCards
    /////////         selectedSystemIds={selectedSystemIds}
    /////////         systems={systems}
    /////////         overdueSystems={overdueSystems}
    /////////     />
    ///////// }, [systems, overdueSystems, selectedSystemIds])

    if (error) return 'error'

    // const addressCards = <DispatchAddressCards
    //     selectedSystemIds={selectedSystemIds}
    //     systems={systems}
    //     overdueSystems={overdueSystems}
    // />

    return (
        <Grid container spacing={2}>
            <Grid item lg={7} xs={12}>
                <Paper
                    elevation={10}
                    p={2}
                    sx={{
                        position: 'sticky',
                        // top: 'calc(64px + .5rem)',
                        top: '64px',
                        zIndex: 1,
                    }}
                >
                    <Stack p={2} direction="row" sx={{ justifyContent: 'space-between' }}>
                        {showDetails ? (
                            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} sm={4}>
                                    <DesktopDatePicker
                                        label={t`A partir de`}
                                        inputFormat="yyyy-MM-DD"
                                        value={fromDate}
                                        mask="____-__-__"
                                        onChange={d => setFromDate(d.format('YYYY-MM-DD'))}
                                        renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DesktopDatePicker
                                        label={t`Jusqu'au`}
                                        inputFormat="yyyy-MM-DD"
                                        value={toDate}
                                        mask="____-__-__"
                                        onChange={d => setToDate(d.format('YYYY-MM-DD'))}
                                        renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        checked={includeThoseWithAppointments}
                                        control={<Checkbox
                                            color="secondary"
                                            value={includeThoseWithAppointments}
                                            onClick={() => setIncludeThoseWithAppointments(!includeThoseWithAppointments)} />}
                                        label={t`Inclure les rendez-vous`}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography variant="h6">
                                <Trans>
                                    Entretiens requise dans les
                                    <Button
                                        style={{ fontSize: '1.5rem', margin: '0 .5rem' }}
                                        size="large"
                                        onClick={e => setAnchorEl(e.currentTarget)}
                                    >
                                        ▼ {nextDays}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={menuIsOpen}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                    >
                                        <MenuItem onClick={() => onSelect(30)}>30</MenuItem>
                                        <MenuItem onClick={() => onSelect(60)}>60</MenuItem>
                                        <MenuItem onClick={() => onSelect(90)}>90</MenuItem>
                                        <MenuItem onClick={() => onSelect(180)}>180</MenuItem>
                                    </Menu>
                                    prochains jours
                                </Trans>
                            </Typography>
                        )}
                        <Stack direction="row">
                            <IconButton onClick={() => {
                                const newState = !showDetails;
                                setShowDetails(newState)
                                if (!newState) {
                                    onNextDaysChange(30)
                                }
                            }}>
                                {showDetails ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
                            </IconButton>
                            <IconButton onClick={() => setShowMap(!showMap)}>
                                {showMap ? <MapIcon /> : <MapOutlinedIcon />}
                            </IconButton>
                        </Stack>
                    </Stack>
                </Paper>

                <Box sx={{
                    display: {
                        lg: 'block',
                        md: 'none',
                        sm: 'none',
                        xs: 'none',
                    },
                    mt: 2,
                }}>
                    {loading && (<Loading />)}

                    {/************ addressCards */}
                    <DispatchAddressCards
                        selectedSystemIds={selectedSystemIds}
                        systems={systems}
                        overdueSystems={overdueSystems}
                    />
                </Box>

            </Grid>
            <Grid item lg={5} xs={12}>
                <Box sx={{
                    mt: { lg: -2 },
                    mr: { lg: -2 },
                    mb: { lg: -2 },
                    top: { lg: '64px' },
                    position: { lg: 'sticky' },
                    minHeight: {
                        lg: 'calc(100vh - 64px)',
                        md: '50vh',
                        sm: '50vh',
                        xs: '50vh',
                    },
                    // border: '2px solid red',

                    // mt: -2,
                    // mr: -2,
                    // mb: -2,
                    // position: 'sticky',
                    // top: 0,
                    // border: '2px solid red',
                    // minHeight: 'calc(100vh - 64px)',
                }}
                >

                    {(addresses.length === 0 && overdueAddresses.length === 0) ? (
                        <Typography>{t`Données manquantes`}</Typography>
                    ) : (
                        <Box sx={{
                            display: 'block',
                            // height: 'calc(100vh - 64px)',
                            width: '100%',
                            height: {
                                lg: 'calc(100vh - 64px)',
                                md: '50vh',
                                sm: '50vh',
                                xs: '50vh',
                            },
                            // border: '1px solid yellow',
                        }}>
                            <SystemMapComponent
                                systems={systems}
                                overdueSystems={overdueSystems}
                                dataMapper={system => system.address}
                                center={addresses[0]}
                                selectedSystemIds={selectedSystemIds}
                                onClick={toggleSelectedSystem}
                            />

                            <Box sx={{
                                display: {
                                    lg: 'none',
                                },
                                mt: 2,
                            }}>
                                {/************* addressCards */}
                                <DispatchAddressCards
                                    selectedSystemIds={selectedSystemIds}
                                    systems={systems}
                                    overdueSystems={overdueSystems}
                                />
                            </Box>
                        </Box>
                    )}


                </Box>
            </Grid>
        </Grid>
    )
}


// export const DispatchDashboard = () => {
//     const today = () => moment().format('YYYY-MM-DD')
//     const [nextDays, setNextDays] = useLocalStorage('dashboard.nextDays', 30);
//     const [includeThoseWithAppointments, setIncludeThoseWithAppointments] = useState(true)
//     const [showMap, setShowMap] = useLocalStorage('dashboard.showMap', false)
//     const [showDetails, setShowDetails] = useLocalStorage('dashboard.showDetails', false)
//     const [fromDate, setFromDate] = useState(today())
//     const [toDate, setToDate] = useState(moment(fromDate).add(nextDays, 'days').format('YYYY-MM-DD'))
//     const [selectedSystemIds, setSelectedSystemIds] = useState([])
// 
//     const toggleSelectedSystem = system => {
//         const { id } = system;
//         if (selectedSystemIds.indexOf(id) >= 0) {
//             setSelectedSystemIds(selectedSystemIds.filter(sid => id !== sid))
//         } else {
//             setSelectedSystemIds([...selectedSystemIds, id])
//         }
//     }
// 
//     // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
// 
//     const onNextDaysChange = value => {
//         setNextDays(value)
//         setFromDate(today())
//         setToDate(moment().add(value, 'days').format('YYYY-MM-DD'))
//     }
// 
//     const { data, error, loading } = useQuery(gql`
//         fragment extendedSystemFields on ExtendedSystemType {
//             id
//             type
//             maintenanceDueDate
//             dateInstallation
//             client {
//                 id
//                 fullName
//                 telephone
//                 email
//             }
//             icons {
//                 name
//                 color
//                 label
//             }
//             address {
//                 id
//                 lat
//                 lng
//                 formatted
//             }
//             upcomingMaintenanceAppointments {
//                 id
//                 type
//                 datetime
//                 technicians {
//                     id
//                     firstName
//                 }
//             }
//             config
//             omnium
//             sim {
//                 id
//             }
//         }
//         query SystemsWithMaintenanceRequired ( $fromDate: String!, $toDate: String!, $includeThoseWithAppointments: Boolean ) {
//             systemsWithMaintenanceRequired( fromDate: $fromDate, toDate: $toDate, includeThoseWithAppointments: $includeThoseWithAppointments ) {
//                 matchedSystems {
//                     ...extendedSystemFields
//                 }
//                 overdueSystems {
//                     ...extendedSystemFields
//                 }
//             }
//         }
//     `, {
//         variables: {
//             includeThoseWithAppointments,
//             fromDate,
//             toDate,
//         },
//         fetchPolicy: "no-cache",
//     })
// 
//     const [anchorEl, setAnchorEl] = useState(null)
//     const menuIsOpen = Boolean(anchorEl);
// 
//     const onSelect = value => {
//         onNextDaysChange(value);
//         setAnchorEl(null);
//     }
// 
//     if (error) return 'error'
// 
//     const systems = data?.systemsWithMaintenanceRequired?.matchedSystems || [];
//     const overdueSystems = data?.systemsWithMaintenanceRequired?.overdueSystems || [];
//     const addresses = systems.map(system => system.address)
//     const overdueAddresses = overdueSystems.map(system => system.address)
// 
//     return (
//         <>
//             {/**
//             <Button
//                 onClick={() => {
//                     ['info', 'warning', 'error', 'success'].forEach( variant => enqueueSnackbar(`variant ${variant}`, { variant }))
//                 }}
//                 >info</Button>
//             */}
//             <Box mb={2}>
//                 <Paper p={2}>
//                     <Stack p={2} direction="row" sx={{ justifyContent: 'space-between' }}>
//                         {showDetails ? (
//                             <Grid container spacing={2} sx={{ alignItems: 'center' }}>
//                                 <Grid item xs={12} sm={4}>
//                                     <DesktopDatePicker
//                                         label={t`A partir de`}
//                                         inputFormat="yyyy-MM-DD"
//                                         value={fromDate}
//                                         mask="____-__-__"
//                                         onChange={d => setFromDate(d.format('YYYY-MM-DD'))}
//                                         renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={4}>
//                                     <DesktopDatePicker
//                                         label={t`Jusqu'au`}
//                                         inputFormat="yyyy-MM-DD"
//                                         value={toDate}
//                                         mask="____-__-__"
//                                         onChange={d => setToDate(d.format('YYYY-MM-DD'))}
//                                         renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={4}>
//                                     <FormControlLabel
//                                         checked={includeThoseWithAppointments}
//                                         control={<Checkbox
//                                             color="secondary"
//                                             value={includeThoseWithAppointments}
//                                             onClick={() => setIncludeThoseWithAppointments(!includeThoseWithAppointments)} />}
//                                         label={t`Inclure les rendez-vous`}
//                                         labelPlacement="end"
//                                     />
//                                 </Grid>
//                             </Grid>
//                         ) : (
//                             <Typography variant="h6">
//                                 <Trans>
//                                     Entretiens requise dans les
//                                     <Button
//                                         style={{ fontSize: '1.5rem', margin: '0 .5rem' }}
//                                         size="large"
//                                         onClick={e => setAnchorEl(e.currentTarget)}
//                                     >
//                                         ▼ {nextDays}
//                                     </Button>
//                                     <Menu
//                                         anchorEl={anchorEl}
//                                         open={menuIsOpen}
//                                         onClose={() => setAnchorEl(null)}
//                                         anchorOrigin={{
//                                             vertical: 'top',
//                                             horizontal: 'left',
//                                         }}
//                                         transformOrigin={{
//                                             vertical: 'top',
//                                             horizontal: 'left'
//                                         }}
//                                     >
//                                         <MenuItem onClick={() => onSelect(30)}>30</MenuItem>
//                                         <MenuItem onClick={() => onSelect(60)}>60</MenuItem>
//                                         <MenuItem onClick={() => onSelect(90)}>90</MenuItem>
//                                         <MenuItem onClick={() => onSelect(180)}>180</MenuItem>
//                                     </Menu>
//                                     prochains jours
//                                 </Trans>
//                             </Typography>
//                         )}
//                         <Stack direction="row">
//                             <IconButton onClick={() => {
//                                 const newState = !showDetails;
//                                 setShowDetails(newState)
//                                 if (!newState) {
//                                     onNextDaysChange(30)
//                                 }
//                             }}>
//                                 {showDetails ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
//                             </IconButton>
//                             <IconButton onClick={() => setShowMap(!showMap)}>
//                                 {showMap ? <MapIcon /> : <MapOutlinedIcon />}
//                             </IconButton>
//                         </Stack>
//                     </Stack>
//                 </Paper>
//             </Box>
// 
//             {showMap && (
//                 <>
//                     {(addresses.length === 0 && overdueAddresses.length === 0) ? (
//                         <Typography>{t`Données manquantes`}</Typography>
//                     ) : (
//                         <Box mb={2} sx={{ height: 400, width: '100%' }}>
//                             <SystemMapComponent
//                                 systems={systems}
//                                 overdueSystems={overdueSystems}
//                                 dataMapper={system => system.address}
//                                 center={addresses[0]}
//                                 selectedSystemIds={selectedSystemIds}
//                                 onClick={toggleSelectedSystem}
//                             />
//                         </Box>
//                     )}
//                 </>
//             )}
// 
//             {(selectedSystemIds.length > 0) && (
//                 <Box mb={2}>
//                     <Paper>
//                         <Box p={2}>
//                             <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
//                                 <Typography variant="h6">
//                                     <Typography variant="h6" component="span">{selectedSystemIds.length} </Typography>
//                                     {selectedSystemIds.length === 1 ? t`adresse sélectionnée` : t`adresses sélectionnées`}
//                                 </Typography>
//                                 <Button variant="outlined" color="secondary" onClick={() => setSelectedSystemIds([])}>
//                                     Déselectionner tout
//                                 </Button>
//                             </Stack>
//                         </Box>
//                     </Paper>
//                 </Box>
//             )}
// 
//             {loading && (<Loading />)}
// 
//             <div style={{
//                 // see: https://medium.com/code-writers/minmax-repeat-auto-fill-and-auto-fit-in-grid-layout-18e2912da399
//                 // this create the grid of cards with dynamic (filling) widths:
//                 display: 'grid',
//                 gridTemplateColumns: 'repeat(auto-fill, minmax(300px, auto))',
//                 gap: '1rem',
//             }}>
//                 {((selectedSystemIds.length > 0) ? overdueSystems.filter(s => selectedSystemIds.indexOf(s.id) > -1) : overdueSystems).map((system, i) => (
//                     <SystemCard system={system} overdue={true} />
//                 ))}
// 
//                 {((selectedSystemIds.length > 0) ? systems.filter(s => selectedSystemIds.indexOf(s.id) > -1) : systems).map((system, i) => (
//                     <SystemCard system={system} />
//                 ))}
//             </div>
//         </>
//     )
// }