import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { frFR, enUS, nlNL } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import { Trans, t } from '@lingui/macro'

import {
    Box,
    Card,
    CardContent,
    CardActions,
    Paper,
    Typography,
    InputAdornment,
}
    from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';

import {
    MyTextField,
    valueOrNd,
    useLocalStorage,
    Loading,
    ZenDataGrid,
    useCurrentScreenSize,
    IconBarComponent,
    useDebouncedValue,
} from './Common'

import { CLIENTS_QUERY } from "../Queries";
import { useMe } from "../CurrentUserContext";


const Clients = () => {
    const { me } = useMe()
    const [lang] = useLocalStorage('lang', 'fr'); // const { me } = useMe(); // @TODO move the language conf entirely onto the user profile
    // and have the context update the app after a language change! 
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [searchBox, setSearchBox] = useState('')
    const debouncedSearchBox = useDebouncedValue(searchBox, 650)
    const [skip, setSkip] = useState(0)
    const [clientsPerPage, setClientsPerPage] = useLocalStorage('clients.listing.clientsPerPage', 10)
    const { data, loading, error } = useQuery(CLIENTS_QUERY, {
        variables: {
            skip,
            clientsPerPage,
            searchBox: debouncedSearchBox,
        }
    })

    const screenSize = useCurrentScreenSize()

    if (error) return <pre>ERROR: {JSON.stringify(error)}</pre>

    const clients = data?.searchClients?.clients || [];
    const clientsCount = data?.searchClients?.count || 0;

    const handlePageChange = (number) => {
        setPage(number)
        setSkip(clientsPerPage * number)
    }

    const handlePageSizeChange = (number) => {
        setClientsPerPage(number)
    }

    const columns = [
        {
            field: "Type",
            headerName: ``,
            sortable: false,
            width: 30,

            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.isBusiness) {
                    return <BusinessIcon />
                }
                else {
                    return <PersonIcon />
                }
            }
        },
        {
            field: 'name',
            headerName: t`Nom`,
            sortable: false,
            minWidth: 200,
            flex: 0.2,
            renderCell: (params) => `${params.row.fullName}`
        },
        { field: 'telephone', headerName: t`Téléphone`, minWidth: 130, sortable: false, },
        {
            field: "info",
            headerName: t`Info`,
            sortable: false,
            minWidth: 200,

            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Stack spacing={1} direction="row">
                        <IconBarComponent icons={params.row.icons} />
                    </Stack>
                );
            }
        },
        { field: 'reference', headerName: t`Réference`, minWidth: 130, sortable: false, },
        {
            field: 'address',
            headerName: t`Adresse`,
            flex: 1,
            sortable: false,
            minWidth: 300,
            valueGetter: params => params.row.primaryAddress?.formatted,
        },

    ].filter((column) => {
        if ( me.profile.organization.id !== '1' ) {
            return column['field'] !== 'reference'
        }
        return true;
    })

    const LOCALE_TEXT_MAP = {
        'fr': frFR.components.MuiDataGrid.defaultProps.localeText,
        'en': enUS.components.MuiDataGrid.defaultProps.localeText,
        'nl': nlNL.components.MuiDataGrid.defaultProps.localeText,
    }

    const localeText = LOCALE_TEXT_MAP[lang]

    return <>
        <Paper sx={{ p: 2 }}>
            <Stack spacing={2} mb={2} direction="column">
                <Stack direction="row" spacing={2}>
                    <Button
                        // size="small"
                        variant="outlined"
                        onClick={() => history.push("/new-client")}
                        startIcon={<AddIcon />}
                    >
                        {t`Ajouter Client`}
                    </Button>
                </Stack>
                <MyTextField
                    sx={{ minWidth: '20em' }}
                    id="outlined-basic"
                    label={t`Recherche ...`}
                    variant="outlined"
                    value={searchBox}
                    autoFocus={true}
                    onChange={(e) => setSearchBox(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>

            {screenSize.dynamicWidth > 700 ?
                <>
                    <Paper style={{ width: '100%' }}>

                        <ZenDataGrid
                            rows={clients}
                            loading={loading}
                            columns={columns}

                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            onRowClick={(e) => history.push(`/clients/${e.row.id}/view`)}
                            // sortingMode='server' 
                            // onSortModelChange={handleSortModelChange}
                            paginationMode="server"
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            pageSize={clientsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            rowCount={clientsCount}
                            page={page}
                            isRowSelectable={() => true}
                            // density="compact" // "compact" | "standard" | "comfortable"
                            autoHeight
                            hideFooterRowCount
                            localeText={localeText}
                        />
                    </Paper>
                </>
                :
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    // justifyContent: 'space-around',
                    // justifyContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    // border: '1px solid yellow',
                }}
                // spacing={5}
                >
                    {clients.map((client, i) => (
                        <Box mb={2} mr={2} key={i} sx={{ width: "100%" }}>
                            <Card >
                                <CardContent>
                                    <Stack direction="row" spacing={1}>
                                        {client.isBusiness ? <BusinessIcon /> : <PersonIcon />}
                                        <Typography>{client.fullName}</Typography>
                                    </Stack>
                                    <Stack spacing={1} direction="row">
                                        <IconBarComponent icons={client.icons} />
                                    </Stack>
                                    {client.telephone ?
                                        <Button
                                            // variant="contained"
                                            href={`tel:${client.telephone}`}
                                            startIcon={<PhoneIcon />}
                                        >{client.telephone}</Button>

                                        : valueOrNd(null)
                                    }
                                    <Typography>{client.primaryAddress?.formatted}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="outlined"
                                        onClick={() => history.push(`/clients/${client.id}/view`)}
                                    >
                                        <Trans>Fiche Client</Trans>
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    ))}
                </Box>
            }
        </Paper>
    </>
}
export default Clients;


export const WorkOrderComponent = () => {
    // Fiche de travail ...
}