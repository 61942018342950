import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";
import { useSnackbar } from 'notistack';
import { Trans, t, Plural } from '@lingui/macro'


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Popover from '@mui/material/Popover';

import {
    Container,
    Autocomplete,
    CircularProgress,
    Tab,
    Paper,
} from '@mui/material'

import moment from 'moment';
import 'moment/locale/fr'
import { Settings } from '../Settings'

import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useMe } from '../CurrentUserContext'

import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GasMeterIcon from '@mui/icons-material/GasMeter';
// import WaterDropIcon from '@mui/icons-material/WaterDrop';

import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';
import {
    LocalFireDepartmentOutlined,
    WorkspacePremiumOutlined,
    FingerprintOutlined,
    ShieldOutlined,
    PolicyOutlined,
} from '@mui/icons-material';

import { DataGrid } from '@mui/x-data-grid';

import SignatureCanvas from 'react-signature-canvas'


export const DOCUMENT_STATE_MAPPING = {
    'IN_PROGRESS': {
        'text': t`En cours`,
        'color': 'warning',
    },
    'PENDING': {
        'text': t`En attente`,
        'color': 'warning',
    },
    'AWAITING_SIGNATURE': {
        'text': t`En attente de signature`,
        'color': 'warning',
    },
    'SIGNED': {
        'text': t`Signé`,
        'color': 'info',
    },
    'CANCELLED': {
        'text': t`Annulé`,
        'color': 'error',
    },
    'CLOSED': {
        'text': t`Cloturé`,
        'color': 'default',
    },
}

export const GSE_WORKORDER_STATE_MAPPING = {
    'PENDING': {
        'text': t`En attente`,
        'color': 'warning',
    },
    'AWAITING_SIGNATURE': {
        'text': t`En attente de signature`,
        'color': 'info',
    },
    'SIGNED': {
        'text': t`Signé`,
        'color': 'success',
    },
    'CLOSED': {
        'text': t`Cloturé`,
        'color': 'default',
    }
}


export const ICON_MAPPING = {
    'video': <VideocamOutlinedIcon />,
    'device': <DeviceUnknownIcon />,
    'sim': <SimCardOutlinedIcon />,
    'contract': <PolicyOutlined />,
    'fire': <LocalFireDepartmentOutlined />,
    'workspace': <WorkspacePremiumOutlined />,
    'fingerprint': <FingerprintOutlined />,
    'shield': <ShieldOutlined />,
}


export const iconFromName = name => ICON_MAPPING[name] || <DeviceUnknownIcon />


export const ZenDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
        outline: "none",
    },
    '& .MuiDataGrid-cell:hover': {
        cursor: 'pointer',
    },
    '& .warning': {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.primary.contrastText,
    },
    '& .warning:hover': {
        backgroundColor: theme.palette.warning.dark,
        color: 'white',
    },
}));

export const DEFAULT_ADDRESS = {
    street: "",
    number: "",
    city: "",
    postalCode: "",
    country: "Belgique",
}

export const useDebounce = (value, delay, callback) => {
    // const [debouncedValue, setDebouncedValue] = useState(value)
    const [timer, setTimer] = useState(null)
    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        // let t = setTimeout(() => setDebouncedValue(value), delay || 500)
        let t = setTimeout(() => {
            callback()
        }, delay || 500)
        setTimer(t)
        return () => {
            clearTimeout(t)
        }
    }, [value, delay])
    // return debouncedValue
}

export const useDebouncedValue = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}


export const CustomCard = styled(Card)(({ theme }) => {
    return {
        // borderRadius: '0rem',
        // borderTopRightRadius: '1rem',
        // borderBottomLeftRadius: '1rem',
        marginBottom: '1rem',
        '& .MuiCardHeader-title': {
            fontSize: "1.1rem",
            fontWeight: 'bold',
            // color: theme.palette.text.primary,
        },
        '& .MuiCardContent-root': {
            padding: '1rem',
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            textDecoration: 'none',
        }
    }
});

export const LinkTab = props => (
    <Tab
        component={Link}
        replace={false}
        to={props.to}
        {...props}
    />
)

export const GoBackButton = props => {
    const history = useHistory();
    return <Button onClick={() => history.goBack()} {...props}>{t`Retour`}</Button>
}
export const SaveButton = props => <Button variant="contained" {...props}>{props?.children || t`Sauvgarder`}</Button>
export const CardButtons = props => <Stack direction="row" sx={{ justifyContent: 'flex-end' }} pt={3} spacing={1} {...props} />
export const NarrowPageContainer = props => <Container maxWidth="md" {...props} />

export const valueOrNd = value => Boolean(value) ? value : <Typography color="text.disabled" component="span">N/D</Typography>

export const Loading = () => <CircularProgress />

export const getFlag = country => {
    try {
        return {
            'be': '🇧🇪',
            'belgium': '🇧🇪',
            'belgique': '🇧🇪',
            'fr': '🇫🇷',
            'france': '🇫🇷',
            'lu': '🇱🇺',
            'luxembourg': '🇱🇺',
        }[country.toLowerCase()];
    } catch {
        return '';
    }
}

export const getFileExtension = (filename) => {
    // get file extension
    if (!filename) return '';
    const extension = filename.split('.').pop();
    return extension.toLowerCase();
}

export const MyTextField = props => <TextField fullWidth variant="outlined" {...props} />

const DotMenu = ({ x, handleOpenDialog, handleOpenDeleteDialog }) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <IconButton
            id="long-button"
            onClick={handleClick}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >

            <MenuItem onClick={() => {
                handleOpenDialog(x)
                handleClose()
            }}>
                {t`Modifier`}
            </MenuItem>
            <MenuItem onClick={() => {
                handleOpenDeleteDialog(x)
                handleClose()
            }}>
                {t`Supprimer`}
            </MenuItem>
        </Menu>
    </>
}

export const Comments = ({
    comments,
    currentComment,
    handleChange,
    handlePostComment,
    handleUpdateComment,
    handleDeleteComment,
}) => {

    const { me } = useMe()
    const [openDialog, setOpenDialog] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [tempComment, setTempComment] = useState({
        id: "",
        text: "",
    })

    const handleOpenDialog = (comment) => {
        setTempComment({ id: comment.id, text: comment.text })
        setOpenDialog(true)
    }
    const handleOpenDeleteDialog = (comment) => {
        setTempComment({ id: comment.id, text: comment.text })
        setOpenDeleteDialog(true)

    }
    const handleEditComment = (e) => {
        setTempComment({ ...tempComment, text: e.target.value })
    }

    return <>
        <>
            {/* <Stack spacing={2}> */}
            {comments.length > 0 ? comments.map((x) => (
                <Grid container key={x.id}>
                    <Grid item xs={11}>
                        <Stack mb={1.5}>
                            <Typography style={{ fontWeight: 'bold' }}>{x.createdBy.firstName}</Typography>
                            <Typography>{x.text}</Typography>
                            <Typography style={{ color: "grey", fontSize: 12 }}>
                                {t`Posté le`}&nbsp;
                                {moment(x.createdAt).format('LLLL')}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                        {x.createdBy.id === me.id &&
                            <DotMenu
                                x={x}
                                handleOpenDialog={handleOpenDialog}
                                handleOpenDeleteDialog={handleOpenDeleteDialog}
                            />
                        }
                    </Grid>
                </Grid>

            )) :
                <></>
            }
            <Box>
                <MyTextField
                    id="comments"
                    value={currentComment}
                    onChange={(e) => handleChange(e)}
                    multiline
                    autoFocus={true}
                    placeholder={(comments.length === 0 ? t`ajouter le premiere commentaire` : t`ajouter un commentaire`) + `...`}
                    rows={3}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                // display: !currentComment?.length ? 'none' : 'default',
            }}>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ mt: 2, ml: 1 }}
                    onClick={handlePostComment}
                    disabled={!currentComment?.length}
                >
                    {t`Poster`}
                </Button>
            </Box>

        </>
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            maxWidth={"sm"}
            fullWidth
        >
            <DialogTitle>{t`Modifier commentaire`}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ mt: 2 }}>
                    <MyTextField
                        id="id_comment"
                        label={t`Commentaire`}
                        multiline
                        rows={3}
                        value={tempComment.text}
                        onChange={(e) => handleEditComment(e)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Annuler</Button>
                <Button onClick={() => {
                    handleUpdateComment(tempComment)
                    setOpenDialog(false)
                }
                } variant="contained">{t`Modifier`}</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            maxWidth={"sm"}
            fullWidth
        >
            <DialogTitle>{t`Supprimer commentaire`}</DialogTitle>
            <DialogContent>
                <Typography>
                    {t`Vous allez supprimer le commentaire`}: {tempComment.text}
                </Typography>
                <Typography>
                    {t`Veuillez Confirmer`}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDeleteDialog(false)}>{t`Annuler`}</Button>
                <Button onClick={() => {
                    handleDeleteComment(tempComment.id)
                    setOpenDeleteDialog(false)
                }
                } variant="contained">{t`Confirmer`}</Button>
            </DialogActions>
        </Dialog>

    </>
}

export const HiddenInput = styled('input')({
    display: 'none',
});

export const DocumentUpload = ({ handleChange, handleDeleteDoc, documents }) => {
    const [open, setOpen] = useState(false)
    const [docId, setDocId] = useState()
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    return <>
        <CustomCard>
            <CardHeader
                action={
                    <IconButton color="inherit" component="label" onChange={(e) => handleChange(e)}>
                        <HiddenInput type="file" />
                        <AddCircleOutlineIcon />
                    </IconButton>

                }
                title={
                    <Plural
                        value={documents.length}
                        _0="Aucun document"
                        _1="1 document"
                        other="# documents" />
                }
            />
            {documents.length > 0 &&
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t`Titre`}</TableCell>
                                <TableCell>{t`Date upload`}</TableCell>
                                <TableCell ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents.map((doc) => (
                                <TableRow
                                    key={doc.id}
                                    onClick={() => {
                                        history.push(`/document/${doc.id}`)
                                    }}
                                >
                                    <TableCell >{doc.uploadedFilename || doc.titre || 'sans titre'}</TableCell>
                                    <TableCell>{doc.dateUpload}</TableCell>
                                    <TableCell align="right">
                                        <Stack direction="row" m={-1}>
                                            <Link to={{ pathname: `${Settings.MEDIA_URL}/${doc.file}` }} target="_blank">
                                                <IconButton  >
                                                    {(getFileExtension(doc.file) === "pdf") ?
                                                        <PictureAsPdfIcon sx={{ color: "text.primary" }} /> :
                                                        (["jpg", "jpeg", "png"].includes(getFileExtension(doc.file))) ?
                                                            <ImageOutlinedIcon sx={{ color: "text.primary" }} /> :
                                                            <InsertDriveFileOutlinedIcon sx={{ color: "text.primary" }} />
                                                    }

                                                </IconButton>
                                            </Link>
                                            <IconButton color="inherit" onClick={() => {
                                                setDocId(doc.id)
                                                setOpen(true)
                                            }}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            }
        </CustomCard>
        <ConfirmDialog
            open={open}
            title={t`Supprimer document`}
            body={t`Veuillez Confirmer`}
            onClose={() => setOpen(false)}
            onSubmit={async () => {
                await handleDeleteDoc(docId)
                setOpen(false)
                enqueueSnackbar(t`Document supprimé`, { variant: 'success' });
            }}
        />
    </>
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    sx: {
        '&& .Mui-selected': {
            backgroundColor: 'rgba(1,1,1,.3)'
        },
        '&& .Mui-selected:hover': {
            backgroundColor: 'rgba(1,1,1,.5)'
        }
    }
};


export const MultiSelectUsers = ({ selectedUserIds, onChange, excludeIds, label }) => {
    label = label || t`Installateurs`
    excludeIds = excludeIds || [];
    const { data, loading } = useQuery(gql`
        query UsersQuery {
            users {
                id
                username
                firstName
                lastName
            }
        }
    `)
    if (loading) return <Loading />
    const { users } = data
    const filteredUsers = users.filter(user => excludeIds.indexOf(user.id) === -1)

    return (
        <>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id="installateurs">{label}</InputLabel>
                <Select
                    labelId="installateurs"
                    id="selectInstallateurs"
                    multiple
                    value={selectedUserIds}
                    onChange={e => {
                        onChange(e.target.value)
                    }}
                    input={<OutlinedInput label={label} />}
                    // renderValue={(selected) => selected.join(', ')}
                    renderValue={userIds => users.filter(u => userIds.indexOf(u.id) > -1).map(u => u.firstName).join(', ')}
                    MenuProps={MenuProps}
                >
                    {filteredUsers.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            <Checkbox checked={selectedUserIds.indexOf(user.id) > -1} />
                            <ListItemText primary={user.firstName} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}
export const MultiSelectSystems = ({ systems, selectedSystemIds, onChange }) => {
    const label = t`Systèmes`

    return (
        <Box mb={2}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id="systems">{label}</InputLabel>
                <Select
                    labelId="systems"
                    id="selectSystems"
                    multiple
                    value={selectedSystemIds}
                    onChange={e => {
                        onChange(e.target.value)
                    }}
                    input={<OutlinedInput label={label} />}
                    // renderValue={(selected) => selected.join(', ')}
                    renderValue={systemIds => systems.filter(s => systemIds.indexOf(s.id) > -1).map(s => s.type).join(', ')}
                    MenuProps={MenuProps}
                >
                    {systems.map((system) => (
                        <MenuItem key={system.id} value={system.id}>
                            <Checkbox checked={selectedSystemIds.indexOf(system.id) > -1} />
                            <ListItemText primary={system.type} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}


export const MultiSelect = ({ values, onChange, users }) => {
    return (
        <div>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id="installateurs">{t`Installateurs`}</InputLabel>
                <Select
                    labelId="installateurs"
                    id="selectInstallateurs"
                    multiple
                    value={values}
                    onChange={onChange}
                    input={<OutlinedInput label={t`Installateurs`} />}
                    // inputComponent={<MyTextField label="Installateurs" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {users.map((user) => (
                        <MenuItem key={user.id} value={user.firstName}>
                            <Checkbox checked={values.indexOf(user.firstName) > -1} />
                            <ListItemText primary={user.firstName} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

// Found here:
// https://usehooks.com/useLocalStorage/
export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // 
        }
    };
    return [storedValue, setValue];
}

export const toFrDate = (date) => {
    return moment(date).format('DD-MM-YYYY')
}

export const BasicBreadcrumbs = ({ pages }) => {
    return (
        <div role="presentation">
            <Breadcrumbs mb={2}>
                <Link to="/clients" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography underline="hover" color="inherit">
                        Clients
                    </Typography>
                </Link>
                {pages.slice(0, -1).map((x) => (
                    <Link to={x[0]} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography underline="hover" color="inherit">
                            {x[1]}
                        </Typography>
                    </Link>
                ))}
                <Typography color="text.primary">{pages.slice(-1)[0]}</Typography>
            </Breadcrumbs>
        </div>
    );
}

const COUNTRIES = ["Belgique", "France", "Luxembourg"]
const COUNTRIES_I18N_MAP = {
    "Belgique": <Trans id="Belgique" />,
    "France": <Trans id="France" />,
    "Luxembourg": <Trans id="Luxembourg" />,
}

export const AddressForm = ({ address, onChange }) => {
    const handleAddressChange = (key, val) => {
        onChange({ ...address, [key]: val })
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
                <MyTextField
                    id="street"
                    label={t`Rue`}
                    onChange={(e) => handleAddressChange("street", e.target.value)}
                    value={address.street}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <MyTextField
                    id="number"
                    label={t`Numéro`}
                    onChange={(e) => handleAddressChange("number", e.target.value)}
                    value={address.number}
                    required
                    type="number"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <MyTextField
                    id="postalCode"
                    label={t`Code postal`}
                    onChange={(e) => handleAddressChange("postalCode", e.target.value)}
                    value={address.postalCode}
                    required
                    type="number"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <MyTextField
                    id="city"
                    label={t`Ville`}
                    onChange={(e) => handleAddressChange("city", e.target.value)}
                    value={address.city}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <MyTextField
                    id="country"
                    label={t`Pays`}
                    onChange={(e) => handleAddressChange("country", e.target.value)}
                    value={address.country}
                    required
                    select
                >
                    {COUNTRIES.map((country) => (
                        <MenuItem key={country} value={country}>{COUNTRIES_I18N_MAP[country]}</MenuItem>
                    ))}
                </MyTextField>
            </Grid>
        </Grid>
    )
}

export const MyPopover = ({ anchorEl, popupText, open }) => {

    return <Popover
        id="mouse-over-popover"
        sx={{
            pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        // onClose={handlePopoverClose}
        disableRestoreFocus
    >
        <Typography sx={{ p: 1 }}>{popupText}</Typography>
    </Popover>
}


export const IconBarComponent = ({ icons, fontSize }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popupText, setPopupText] = useState("");
    const open = Boolean(anchorEl);
    const onMouseEnter = event => {
        setAnchorEl(event.currentTarget)
        setPopupText(event.currentTarget.id)
    }
    const onMouseLeave = () => setAnchorEl(null)
    const args = { fontSize, onMouseEnter, onMouseLeave }
    return (
        <>
            {icons.map((icon, i) => {
                const iconArgs = { ...args, id: icon.label, style: { color: icon.color } }
                return (
                    <Fragment key={i}>
                        {icon.name === 'VideocamOutlinedIcon'
                            ? <VideocamOutlinedIcon {...iconArgs} />
                            : icon.name === 'FingerprintOutlined'
                                ? <FingerprintOutlined {...iconArgs} />
                                : icon.name === 'LocalFireDepartmentOutlined'
                                    ? <LocalFireDepartmentOutlined {...iconArgs} />
                                    : icon.name === 'ShieldOutlined'
                                        ? <ShieldOutlined {...iconArgs} />
                                        : icon.name === 'PolicyOutlined'
                                            ? <PolicyOutlined {...iconArgs} />
                                            : icon.name === 'WorkspacePremiumOutlined'
                                                ? <WorkspacePremiumOutlined {...iconArgs} />
                                                : icon.name === 'SimCardOutlinedIcon'
                                                    ? <SimCardOutlinedIcon {...iconArgs} />
                                                    : icon.name === 'GasMeterIcon'
                                                        ? <GasMeterIcon {...iconArgs} />
                                                        : <DeviceUnknownIcon {...iconArgs} />
                        }
                    </Fragment>
                )
            })}
            <MyPopover anchorEl={anchorEl} popupText={popupText} open={open} />
        </>
    )
}

export const configCleanup = (config, type) => {
    if (type === "FIRE_ALARM" || "SECURITY_ALARM") {
        if (!config.monitoring) {
            config.nom_centrale = ""
            config.ip_centrale = ""
            config.port_centrale = 0
            config.protocole = ""
            config.num_prom = ""
        }
        if (!config.transmission) {
            config.gsm = ""
            config.app = ""
        }
        if (!config.acces_distance) {
            config.info_connexion = ""
        }
    }

    if (type === "VIDEO") {
        if (!checkIfValidIP(config.ip_nvr)) {
            config.port_serveur = ""
            config.port_http = ""
            config.port_service = ""
            config.dns = ""
            config.host_name = ""
        }
    }
    if (type === "ACCESS_CONTROL") {
        if (!config.controleurs) {
            config.modele_controleurs = ""
            config.nbre_contoleurs = ""
            config.localisation_controleurs = ""
        }
        if (!config.acces_distance) {
            config.info_connexion = ""
        }
    }

    return config
}

export const checkIfValidIP = (str) => {
    // Regular expression to check if string is a IP address
    const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

    return regexExp.test(str);
}


export const CreateFormDocumentDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
        >
            <DialogTitle>{t`Nouvelle document`}</DialogTitle>
            <DialogContent>
            </DialogContent>
        </Dialog>
    )
}


export const AutocompleteOptionsTextField = ({ configKey, label, onChange, value }) => {
    const { data } = useQuery(gql`
        query AutocompleteSystemConfigQuery ( $key: String! ) {
            autocompleteOptions ( key: $key )
        }
    `, {
        variables: {
            key: configKey,
        }
    })
    const options = data?.autocompleteOptions || []
    return (
        <Autocomplete
            freeSolo
            disablePortal
            id="combo-box-demo"
            value={value}
            options={options}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            autoHighlight
            autoSelect
            renderInput={(params) =>
                <MyTextField
                    {...params}
                    label={label}
                />
            }
        />
    )
}


export const ConfirmDialog = ({ open, title, body, onClose, onSubmit }) => {
    return <>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>{t`Annuler`}</Button>
                <Button onClick={onSubmit}>
                    {t`Confirmer`}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export const prettyPrintSystem = (system) => {
    const type = system.type
    if (system.identifier) {
        return `${type} - ${system.identifier}`
    }
    return type
}


export const useCurrentScreenSize = (props) => {
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    })

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    return screenSize
}

/**
 * @TODO implement this form ... How? many embedded forms or 1 "full address" form? something else?
 
export const UpdateSystemsDueDatesComponent = ({ systems }) => {
    const [ updateSystemsDueDate, { data, error, loading } ] = useMutation(gql`
        query UpdateSystemDueDatesMutation ( $input: UpdateSystemDueDateOutputType! ) {
            id
            dueDate
        }
    `)

    return (
        <>
            {systems.map(system => (
                <>
                    <div>{system.id} | {system.dueDate || 'N/A'}</div>
                    <MyTextField
                        value={system.dueDate}
                        
                </>
            ))}

            
        </>
    )
}


*/