import React, { useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from 'react-router-dom';

import { CustomCard, BasicBreadcrumbs, CardButtons, GoBackButton, SaveButton, Loading } from './Common'

import {
    Stack,
    Paper,
    Typography,
    Container,
    CardContainer,
    CardHeader,
    CardContent,
    Grid,
    Button,
} from '@mui/material'

import { useSnackbar } from 'notistack';

import { t } from '@lingui/macro'

import {
    SystemConfigForm,
} from './SystemTemplate'
import { MyForm } from "./Forms";


const EditSystemConfig = () => {
    const { enqueueSnackbar, } = useSnackbar();
    const { params } = useRouteMatch();
    const history = useHistory()
    const { id } = params;
    const [systemConfig, setSystemConfig] = useState({})

    const { data, loading } = useQuery(gql`
    query SystemQuery($id:ID!){
        system(id:$id){
          id

          config
          client {
              id
              fullName
              addresses {
                  id
                  formatted
              }
          }
          template {
              id
              type

              # legacy version, custom schema:
              schema

              # new version, rjsf schema/uiSchema:
              formSchema
              formUiSchema
          }
          sim {
              id
              operateur
              dateActivation
              number
              comments
          }
        }}
    `, {
        variables: {
            id: id,
        },
        onCompleted: data => {
            setSystemConfig(data.system.config);
        }
    })

    const [UpdateSystemConfig] = useMutation(gql`
        mutation UpdateConfig($systemId:ID!, $config:GenericScalar){
            updateSystemConfig(systemId:$systemId, config:$config){
              id
              config
            }
        }
    `)

    const handleSubmit = async () => {
        await UpdateSystemConfig({
            variables: {
                systemId: id,
                config: systemConfig,
            }
        })
        enqueueSnackbar(t`Configuration modifiée`, { variant: 'success' });
        history.push(`/systems/${id}/view`)
    }

    const [inEditMode, setInEditMode] = useState(false)

    if (loading) return <Loading />

    const legacyCard = <CustomCard sx={{ maxWidth: 'md', mx: 'auto' }}>
        <CardHeader title={t`Modifier Configuration Système`} />
        <CardContent>
            <SystemConfigForm
                values={systemConfig}
                onChange={newConfig => setSystemConfig(newConfig)}
                schema={data.system.template.schema}
            />
            <CardButtons>
                <GoBackButton />
                <SaveButton disabled={loading} onClick={handleSubmit} />
            </CardButtons>
        </CardContent>
    </CustomCard>

    const newCard = (data.system.template.formSchema && Object.keys(data.system.template.formSchema).length > 0)
        ? (
            <Paper sx={{ p: 2, mb: 2 }}>
                {/**
                <MyForm
                    formData={systemConfig}
                    onChange={newConfig => setSystemConfig(newConfig)}
                    onSubmit={handleSubmit}
                />
                */}

                <Button 
                    variant="contained"
                    onClick={() => setInEditMode(!inEditMode)}
                    >
                    {inEditMode ? t`View` : t`Edit`}
                </Button>

                <MyForm
                    readonly={!inEditMode}
                    schema={data.system.template.formSchema}
                    uiSchema={data.system.template.formUiSchema}
                    formData={systemConfig}
                    onChange={({ formData }) => setSystemConfig(formData)}
                    onSubmit={({ formData }) => {
                        console.log(formData)
                    }}
                    onError={() => console.log("errors")}
                    formContext={{
                        formId: id,
                        client: {
                            id: 0,
                            addresses: [],
                        },
                        // this will allow us to access ALL of the forms data
                        // inside individual field templates (see FieldTemplate!)
                        data: systemConfig,
                    }}
                />
            </Paper>
        ) : (
            <Paper sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                    We're missing the formSchema for this template.
                </Typography>
            </Paper>
        );

    return <>

        <Container maxWidth="md" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${data?.system?.client?.id}/view`, `${data?.system?.client?.fullName}`],
                [`/systems/${data?.system?.id}/view`, `${data?.system?.template?.type}`],
                [t`Modification`]
            ]} />

            <Grid container spacing={2}>
                <Grid item md={6}>
                    {legacyCard}
                </Grid>
                <Grid item md={6}>
                    {newCard}
                </Grid>
            </Grid>
        </Container>
    </>
}

export default EditSystemConfig;
