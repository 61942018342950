import './App.css';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  concat,
} from "@apollo/client";

import { createUploadLink } from 'apollo-upload-client';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { SnackbarProvider } from 'notistack';
import Slide from '@mui/material/Slide';
import { Settings } from './Settings'
import { AuthHandlerComponent, AuthenticationRequired } from './Auth'
import PersistentDrawerLeft from './components/Drawer';
import { ThemeProvider } from '@mui/material/styles';


import Clients from './components/Clients';
import ClientDetails from './components/ClientDetails';
import AddClient from './components/AddClient';
import EditClient from './components/EditClient';
import System from './components/System';
import AddSystem from './components/AddSystem';
import AddSim from './components/AddSim';
import EditSim from './components/EditSim';
import EditSystemConfig from './components/EditSystemConfig';
import EditSystem from './components/EditSystem';
import ExportXlsx from './components/ExportXlsx';
import { NewDispatchDashboard } from './components/Dashboard'
import { MeProvider } from "./CurrentUserContext"
import { DocumentComponent, DocumentsListing, DocumentsListingPage } from './components/Documents'

import { IssueListing } from './components/Issues'
import { DemoRouter } from './components/Demos'

import CssBaseline from '@mui/material/CssBaseline';

import 'mapbox-gl/dist/mapbox-gl.css';
import { MapProvider } from 'react-map-gl';

import { useEffect } from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {
  AddAppointmentComponent,
  AddMaintenanceAppointmentComponent,
  ViewAppointmentComponent,
  EditAppointmentComponent,
} from './components/Appointments'

import {
  AddTaskComponent,
  AddClientTaskComponent,
  EditTaskComponent,
  ViewTaskComponent,
  MyTasksComponent,
} from './components/Tasks'

import {
  ScheduleComponent, PrintableDailySchedule,
} from './components/Schedule'

import {
  useLocalStorage,
} from './components/Common'

import { AddAddress } from './components/AddAddress';

import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { messages as messagesNL } from './locales/nl/messages'
import { messages as messagesEN } from './locales/en/messages'
import { messages as messagesFR } from './locales/fr/messages'

import moment from 'moment';
import 'moment/min/locales';
import { en, fr, nl } from "make-plural/plurals"


import { EditAddress } from './components/EditAddress';

import { themeMap } from './Themes'
import { EditGseWorkOrderComponent, GseWorkOrderListing } from './components/WorkOrders';
import { CanvasComponent } from './components/Sketch';
import { UserProfileComponent } from './components/UserProfile';
import { OrganizationListingComponent, UpdateOrganizationComponent } from './components/Organization';
import { SignatureRequestComponent } from './components/SignatureRequest';

moment.locale('fr')

const uploadLink = createUploadLink({
  uri: Settings.GRAPHQL_URI,
})

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(Settings.ACCESS_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    }
  })
  return forward(operation);
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, uploadLink),
})



const ThemedSnackbarProvider = props => (
  <SnackbarProvider {...props} sx={{
    '& .SnackbarItem-variantSuccess': {
      backgroundColor: 'success.main',
      color: 'success.contrastText',
    },
    '& .SnackbarItem-variantError': {
      backgroundColor: 'error.main',
      color: 'error.contrastText',
    },
    '& .SnackbarItem-variantWarning': {
      backgroundColor: 'warning.main',
      color: 'warning.contrastText',
    },
    '& .SnackbarItem-variantInfo': {
      backgroundColor: 'info.main',
      color: 'info.contrastText',
    },
  }} />
)


i18n.loadLocaleData("en", { plurals: en })
i18n.loadLocaleData("fr", { plurals: fr })
i18n.loadLocaleData("nl", { plurals: nl })

i18n.load('en', messagesEN)
i18n.load('fr', messagesFR)
i18n.load('nl', messagesNL)


function App() {
  const [theme, setTheme] = useLocalStorage("theme", "dark")
  const themeInstance = themeMap[theme]
  const toggleTheme = () => setTheme(theme === "light" ? "dark" : "light")

  const [lang, setLang] = useLocalStorage("lang", "fr")

  useEffect(() => {
    i18n.activate(lang)
    moment.locale(lang)
  }, [lang])

  const setLanguage = code => {
    setLang(code)
    i18n.activate(code)
    moment.locale(code)
  }

  i18n.activate(lang)

  return (<>
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/auth/:token"><AuthHandlerComponent /></Route>
          <Route exact path="/demo/sketch"><CanvasComponent /></Route>
          <Route exact path="/signature-requests/:uuid"><SignatureRequestComponent /></Route>

          <ThemeProvider theme={themeInstance}>
            <CssBaseline />
            <I18nProvider i18n={i18n}>
              <AuthenticationRequired lang={lang} setLang={setLanguage}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <MapProvider>
                    <MeProvider>
                      <ThemedSnackbarProvider
                        maxSnack={7}
                        TransitionComponent={Slide}
                        // autoHideDuration={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}>

                        <Switch>
                          <Route exact path="/printable/daily-schedule/:userId/:date"><PrintableDailySchedule /></Route>

                          <Route path="/">
                            <PersistentDrawerLeft toggleTheme={toggleTheme} lang={lang} setLang={setLanguage}>
                              <Route exact path="/document/:documentId"><DocumentComponent /></Route>

                              <Route exact path="/clients"><Clients /></Route>
                              <Route exact path="/new-client/"><AddClient /></Route>

                              <Route exact path="/clients/:id/tasks/add"><AddClientTaskComponent /></Route>

                              <Route path="/clients/:id"><ClientDetails /></Route>
                              {/**
 * 
 * TODO: REFACTOR THESE INTO THE CLIENT DETAILS COMPONENT!
 *  
 * DONE:
 * <Route exact path="/clients/:id/addaddress"><AddAddress /></Route>
 * 
 * 
                          <Route exact path="/clients/:id/edit"><EditClient /></Route>
                          <Route exact path="/clients/:id/editaddress/:addressId"><EditAddress /></Route>
                          <Route exact path="/clients/:clientId/addsystem/:addressId"><AddSystem /></Route>
                          <Route exact path="/clients/:clientId/addappointment/:addressId"><AddAppointmentComponent /></Route>

*/}

                              {/**
                          <Route exact path="/clients/:clientId/addworkorder"><AddGseWorkOrder /></Route>
                          */}

                              <Route exact path="/gse/workorder/:orderId/edit"><EditGseWorkOrderComponent /></Route>
                              <Route exact path="/gse/workorders/"><GseWorkOrderListing /></Route>

                              <Route exact path="/tasks"><MyTasksComponent /></Route>
                              <Route exact path="/tasks/add"><AddTaskComponent /></Route>
                              <Route exact path="/tasks/:id/edit"><EditTaskComponent /></Route>
                              <Route exact path="/tasks/:id/view"><ViewTaskComponent /></Route>

                              <Route exact path="/appointments/:id/edit"><EditAppointmentComponent /></Route>
                              <Route exact path="/appointments/:id"><ViewAppointmentComponent /></Route>

                              <Route exact path="/systems/:id/view"><System /></Route>
                              <Route exact path="/systems/:systemId/addappointment"><AddMaintenanceAppointmentComponent /></Route>

                              {/**
                          <Route path="/calendar"><GseCalendar /></Route>
                          */}

                              <Route path="/schedule"><ScheduleComponent /></Route>
                              <Route path="/editsystem/:id"><EditSystem /></Route>
                              <Route path="/editsystemconfig/:id"><EditSystemConfig /></Route>
                              <Route path="/addsim/:id"><AddSim /></Route>
                              <Route path="/editsim/:id"><EditSim /></Route>
                              <Route path="/issues"><IssueListing /></Route>
                              <Route path="/demo"><DemoRouter /></Route>
                              <Route path="/exportxlsx"><ExportXlsx /></Route>
                              <Route path="/dispatching"><NewDispatchDashboard /></Route>

                              <Route path="/documents"><DocumentsListingPage /></Route>

                              <Route exact path="/user-profile"><UserProfileComponent /></Route>

                              <Route exact path="/organizations/:id/edit"><UpdateOrganizationComponent /></Route>
                              <Route exact path="/organizations"><OrganizationListingComponent /></Route>

                              <Route exact path="/"><Redirect push={false} to="/dispatching" /></Route>
                            </PersistentDrawerLeft>
                          </Route>
                        </Switch>
                      </ThemedSnackbarProvider>
                    </MeProvider>
                  </MapProvider>
                </LocalizationProvider>
              </AuthenticationRequired>
            </I18nProvider>
          </ThemeProvider>
        </Switch>
      </Router>
    </ApolloProvider>
  </>);
}

export default App;
