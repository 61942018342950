const hostname = window.location.hostname

const backendUrl = (hostname === "app.gse.be")
    ? "https://app.gse.be"
    : hostname === 'app.consolarium.com'
    ? 'https://app.consolarium.com'
    : hostname === 'demo.consolarium.com'
    ? 'https://demo.consolarium.com'
    : `http://${hostname}:8000`;

const graphqlUri = `${backendUrl}/graphql`

export const Settings = {
    MEDIA_URL: `${backendUrl}/media/`,
    ACCESS_TOKEN: 'gsereact.accessToken',
    GRAPHQL_URI: graphqlUri,
    LOGIN_URL: `${backendUrl}/accounts/google/login/`,
    HOME_PAGE: '/clients',
    BACKEND_URL: backendUrl,
    // for the google maps api:
    GOOGLE_API_KEY: 'AIzaSyBdQ9ZreflKbh9MLoZOln79BwQ9nnl3xvk',
    MAPBOX_API_ACCESS_KEY: 'pk.eyJ1Ijoic2hlYW4tbWFzc2V5IiwiYSI6ImNreDNpeWM4azB3ZXcybnVxZ3pwNjg4ajYifQ.ghh310nUqLxMbetMM5sMSw',
}