import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";

import { useRouteMatch, useHistory, Link, Switch, Route, Redirect, useLocation, matchPath, useParams } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { CLIENT_QUERY } from "../Queries";
import { useSnackbar } from 'notistack';

import { frFR, enUS, nlNL } from '@mui/x-data-grid';

import 'moment/locale/fr'
import {
    CustomCard,
    Comments,
    DocumentUpload,
    BasicBreadcrumbs,
    valueOrNd,
    Loading,
    useCurrentScreenSize,
    IconBarComponent,
    MyTextField,
    DOCUMENT_STATE_MAPPING,
    HiddenInput,
    getFileExtension,
    GSE_WORKORDER_STATE_MAPPING,
    ZenDataGrid,
    useDebouncedValue,
    useLocalStorage,
} from './Common'

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Trans, t, Plural } from '@lingui/macro'

import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import FormControlLabel from '@mui/material/FormControlLabel';

import { AddAddress } from './AddAddress';

import {
    Box,
    Divider,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    InputAdornment,
    Menu,
    MenuItem,
    Chip,

    Tab,
    Tabs,

    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,

    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,

    Slide,
    Zoom,
} from '@mui/material'

// import { Settings } from '../Settings'

import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import LaunchIcon from '@mui/icons-material/Launch';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TuneIcon from '@mui/icons-material/Tune';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

// import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
// import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import RoomIcon from '@mui/icons-material/Room';
// import MoreTimeIcon from '@mui/icons-material/MoreTime';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';

import {
    FormComponent,
    MyForm,
} from './Forms'

import { useMe } from "../CurrentUserContext";
import { Settings } from "../Settings";

import ReactMapGL, { Marker, useMap, Map, FullscreenControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'

import { EditAddress } from "./EditAddress";
import { DocumentsListing } from "./Documents";


const clientDetailsReducer = (state, action) => {
    switch (action.type) {
        case 'setClient':
            return { ...state, client: action.client }
        case 'selectAddress':
            return { ...state, selectedAddress: action.address }
        case 'selectAddressById':
            return { ...state, selectedAddress: state.client.addresses.find(address => address.id === action.addressId) }
        case 'setHoveredAddress': {
            return { ...state, hoveredAddress: action.address }
        }
        case 'setAddressSearch': {
            return { ...state, addressSearch: action.value }
        }
        default:
            return state
    }
}


const ClientAddressMap = ({ state, dispatch }) => {
    const history = useHistory()
    const { palette } = useTheme()
    const { client } = state
    const { addresses } = client;

    const geocodedAddresses = addresses
        .filter(address => address.lat && address.lng)
        .sort((a, b) => {
            if (state.hoveredAddress) {
                if (a.id === state.hoveredAddress?.id) return 1;
                if (b.id === state.hoveredAddress?.id) return -1;
            }
            if (state.selectedAddress) {
                if (a.id === state.selectedAddress?.id) return 1;
                if (b.id === state.selectedAddress?.id) return -1;
            }
            return 0;
        })

    const [center, setCenter] = useState({ lat: 0, lng: 0 })

    const updateCenter = () => {
        const minLat = Math.min(...geocodedAddresses.map(a => a.lat))
        const maxLat = Math.max(...geocodedAddresses.map(a => a.lat))
        const minLng = Math.min(...geocodedAddresses.map(a => a.lng))
        const maxLng = Math.max(...geocodedAddresses.map(a => a.lng))
        setCenter({
            lat: (minLat + maxLat) / 2,
            lng: (minLng + maxLng) / 2
        })
    }

    useEffect(() => {
        if (geocodedAddresses.length === 0) return;
        updateCenter();
    }, [addresses])

    const [viewport, setViewport] = useState({
        latitude: 50.8,
        longitude: 4.3,
        zoom: 8,
        bearing: 0,
        pitch: 0,
    })

    const [map, setMap] = useState(null)
    const newMapRef = useCallback(node => {
        if (node !== null) {
            setMap(node)
            console.log('HERE', node)
        }
    }, [])

    useEffect(() => {
        if (!map) return;

        if (state.selectedAddress) {
            map.flyTo({
                center: [state.selectedAddress.lng, state.selectedAddress.lat],
                zoom: 10,
                duration: 2000,
            })
        }
    }, [map, state.selectedAddress])

    useEffect(() => {
        if (!map) return;
        if (center.lat === 0 && center.lng === 0) return;
        if (state.selectedAddress) return;
        map.flyTo({
            center: [center.lng, center.lat],
            zoom: 8,
            duration: 2000,
        })
    }, [map, center])

    const mapStyle = (palette.mode === "light")
        ? 'mapbox://styles/mapbox/navigation-day-v1'
        : 'mapbox://styles/mapbox/navigation-night-v1'
        ;

    if (geocodedAddresses.length === 0) return null;

    return (
        <>
            <ReactMapGL
                ref={newMapRef}
                {...viewport}
                style={{
                    width: 'fit',
                    height: '50vh',
                }}
                mapboxAccessToken={Settings.MAPBOX_API_ACCESS_KEY}
                onMove={e => {
                    setViewport({ ...viewport, ...e.viewState })
                }}
                mapStyle={mapStyle}
            >
                {geocodedAddresses.map((address, i) => {
                    const hovering = state.hoveredAddress?.id === address.id;
                    const selected = state.selectedAddress?.id === address.id;
                    const bgcolor = selected
                        ? 'info.main'
                        : hovering
                            ? 'primary.light'
                            : 'primary.dark'
                        ;
                    // console.log('MARKER DRAWING!')
                    return (
                        <Marker
                            key={i}
                            latitude={address.lat}
                            longitude={address.lng}
                            offsetLeft={-20}
                            offsetTop={-10}
                        >
                            <Box
                                // className={ selected ? 'pulsate' : ``}
                                style={{
                                    borderRadius: '2rem',
                                    paddingTop: '.3rem',
                                    boxShadow: (selected || hovering) ? '0 0 1rem 0 rgba(0,0,0,.5)' : '',
                                }}
                                sx={{
                                    bgcolor,
                                    color: 'primary.contrastText',
                                }}
                                onClick={() => {
                                    // dispatch({ type: 'setSelectedAddress', address })
                                    history.push(`/clients/${state.client.id}/addresses/${address.id}`)
                                }}
                                onMouseEnter={() => {
                                    dispatch({ type: 'setHoveredAddress', address })
                                }}
                                onMouseLeave={() => {
                                    dispatch({ type: 'setHoveredAddress', address: null })
                                }}
                            >
                                {selected ? <PushPinIcon /> : <RoomIcon />}
                            </Box>
                        </Marker>
                    )
                })}
            </ReactMapGL>
        </>
    )
}


const SystemDetailsComponent = ({ system }) => {
    const [open, setOpen] = useState(false);
    if (!open) {
        return (
            <Button
                variant="outlined"
                onClick={() => setOpen(true)}
            >
                {system.type}
            </Button>
        )
    }
    return (
        <CustomCard>
            <CardHeader title={system.type} />
            <CardContent>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                >
                    {t`fermer`}
                </Button>
            </CardContent>
        </CustomCard>
    )
}


const AddressSystemsTable = ({ systems, clientId, addressFormatted, addressId }) => {
    const history = useHistory();
    const screenSize = useCurrentScreenSize()
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7}>
                    <Box xs={12} sm={5}>
                        <Typography variant="h6">
                            {addressFormatted}
                        </Typography>
                        <Typography color="text.primary">
                            <Plural
                                value={systems.length}
                                _0="Aucun systeme"
                                _1="1 systeme"
                                other="# systemes" />
                        </Typography>
                        <Stack
                            direction={screenSize.dynamicWidth > 676 ? "row" : "column"}
                            spacing={screenSize.dynamicWidth > 676 ? 1 : 0}
                        >
                            <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                <Button
                                    fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                    variant="outlined"
                                    onClick={() => history.push(`/clients/${clientId}/addsystem/${addressId}`)}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    {t`ajouter un systeme`}
                                </Button>
                            </Box>
                            <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                <Button
                                    fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                    variant="outlined"
                                    onClick={() => history.push(`/clients/${clientId}/addappointment/${addressId}`)}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    {t`ajouter un rdv`}
                                </Button>
                            </Box>
                            <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                <Button
                                    fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                    onClick={() => history.push(`/clients/${clientId}/addresses/${addressId}/edit`)}
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    variant="outlined">
                                    {t`Modifier`}
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    {systems.map(system => (
                        <Paper
                            variant="outlined"
                            style={{ width: '100%', cursor: 'pointer', marginBottom: '1rem' }}
                            sx={{
                                ':hover': {
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                }
                            }}
                            onClick={() => history.push(`/systems/${system.id}/view`)}
                        >
                            <Box p={2}>
                                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                                    <Typography variant="h6">
                                        <span style={{ textTransform: "uppercase" }}>
                                            {system.type} {system.identifier ? `- ${system.identifier.toUpperCase()}` : ""}
                                        </span>
                                    </Typography>
                                    <Typography variant="h6">
                                        <LaunchIcon />
                                    </Typography>
                                </Stack>
                                <Typography>

                                </Typography>

                                <IconBarComponent icons={system.icons} />

                                <Typography sx={{ color: "text.primary" }}
                                >
                                    <Trans>Installé le {system.dateInstallation}</Trans>

                                    {system.installers.length
                                        ? <> <Trans>par</Trans> {system.installers.map(i => i.firstName).join(" ")}</>

                                        : <></>
                                    }

                                </Typography>
                                <Typography sx={{ color: "text.primary" }}
                                >
                                    <Trans>Date limite</Trans>: {valueOrNd(system.maintenanceDueDate)}
                                </Typography>
                            </Box>
                        </Paper>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}


const EDITABLE_STATES = ['IN_PROGRESS', 'PENDING', 'AWAITING_SIGNATURE']


const DocumentTableComponent = ({ clientId }) => {
    // const { me } = useMe();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { data, loading, error, refetch } = useQuery(gql`
        query DocumentTableComponentQuery( $clientId: ID! ) {
            client ( id: $clientId ) {
                id
                organization {
                    id
                    formTemplates {
                        id
                        displayName
                    }
                }
                allDocuments {
                    ... on GseWorkOrderType {
                        id
                        createdAt
                        state
                        signedDocument {
                            id
                            createdAt
                            titre
                            file
                            uploadedFilename
                        }
                    }
                    ... on DocumentType {
                        id
                        createdAt
                        titre
                        file
                        uploadedFilename
                    }
                    ... on FormType {
                        id
                        createdAt
                        state
                        template {
                            id
                            displayName
                        }
                        document {
                            id
                            createdAt
                            titre
                            file
                            uploadedFilename
                        }
                    }
                }
            }
        }
    `, { variables: { clientId } })

    if (loading) return <Loading />
    if (error) return `Error! ${error.message}`

    const { client } = data
    const { organization, allDocuments } = client
    const { formTemplates } = organization

    return (
        <>
            <Stack direction="column" spacing={2}>

                {allDocuments.length === 0 && (
                    <Typography variant="body2" color="textSecondary">
                        {t`Aucun document`}
                    </Typography>
                )}

                {allDocuments.length > 3 && (
                    <MyTextField
                        fullWidth
                        variant="outlined"
                        label={t`Rechercher un document`}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                )}

                {Boolean(allDocuments.length) && (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t`Nom`}</TableCell>
                                    <TableCell>{t`Date`}</TableCell>
                                    <TableCell align="right">{t`Actions`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allDocuments.map(document => (
                                    <TableRow
                                        hover
                                        key={document.id}
                                    >
                                        {document.__typename === "DocumentType"
                                            ? (
                                                <>
                                                    <TableCell>
                                                        {document.uploadedFilename || document.titre || t`sans titre`}
                                                        <Chip size="small" label="UPLOAD" color="default" sx={{ ml: 1 }} />
                                                    </TableCell>
                                                    <TableCell>{document.createdAt}</TableCell>
                                                    <TableCell align="right">
                                                        {/** ... icon buttons ... */}
                                                        <Link to={{ pathname: `${Settings.MEDIA_URL}/${document.file}` }} target="_blank">
                                                            <IconButton size="small">
                                                                {(getFileExtension(document.file) === "pdf") ?
                                                                    <PictureAsPdfIcon sx={{ color: "text.primary" }} /> :
                                                                    (["jpg", "jpeg", "png"].includes(getFileExtension(document.file))) ?
                                                                        <ImageOutlinedIcon sx={{ color: "text.primary" }} /> :
                                                                        <InsertDriveFileOutlinedIcon sx={{ color: "text.primary" }} />
                                                                }
                                                            </IconButton>
                                                        </Link>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => history.push(`/documents/${document.id}/delete`)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </>
                                            )
                                            : document.__typename === "GseWorkOrderType"
                                                ? (
                                                    <>
                                                        <TableCell>
                                                            Fiche de travail
                                                            <Chip
                                                                // label={document.state}
                                                                label={GSE_WORKORDER_STATE_MAPPING[document.state]['text']}
                                                                color={GSE_WORKORDER_STATE_MAPPING[document.state]['color']}
                                                                size="small" sx={{ ml: 1 }} />
                                                        </TableCell>
                                                        <TableCell>{document.createdAt}</TableCell>
                                                        <TableCell align="right">
                                                            {/** ... icon buttons ... */}
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => history.push(`/gse/workorder/${document.id}/edit`)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            {document?.signedDocument?.file ? (
                                                                <Link to={{ pathname: `${Settings.MEDIA_URL}/${document.signedDocument.file}` }} target="_blank">
                                                                    <IconButton size="small">
                                                                        {(getFileExtension(document.signedDocument.file) === "pdf") ?
                                                                            <PictureAsPdfIcon sx={{ color: "text.primary" }} /> :
                                                                            (["jpg", "jpeg", "png"].includes(getFileExtension(document.signedDocument.file))) ?
                                                                                <ImageOutlinedIcon sx={{ color: "text.primary" }} /> :
                                                                                <InsertDriveFileOutlinedIcon sx={{ color: "text.primary" }} />
                                                                        }
                                                                    </IconButton>
                                                                </Link>
                                                            ) : (
                                                                <IconButton
                                                                    size="small"
                                                                    disabled={true}
                                                                >
                                                                    <PictureAsPdfIcon sx={{ color: "text.disabled" }} />
                                                                </IconButton>
                                                            )}
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => history.push(`/documents/${document.id}/delete`)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <TableCell>
                                                            {document.template.displayName}
                                                            <Chip
                                                                // label={document.state}
                                                                label={DOCUMENT_STATE_MAPPING[document.state]['text']}
                                                                color={DOCUMENT_STATE_MAPPING[document.state]['color']}
                                                                size="small" sx={{ ml: 1 }} />
                                                        </TableCell>
                                                        <TableCell>{document.createdAt}</TableCell>
                                                        <TableCell align="right">
                                                            {/** ... icon buttons ... */}
                                                            <IconButton
                                                                size="small"
                                                                disabled={!EDITABLE_STATES.includes(document.state)}
                                                                onClick={() => history.push(`/demo/forms/${document.id}`)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => history.push(`/documents/${document.id}/delete`)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </>
                                                )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>

        </>
    )
}


const ClientAddressDetails = ({ state, dispatch }) => {
    const history = useHistory()
    const { addressId } = useParams()
    if (addressId && (!state.selectedAddress || state.selectedAddress?.id !== addressId)) {
        dispatch({
            type: "selectAddressById",
            addressId,
        })
    }
    const { selectedAddress, client } = state
    const [expandedSystem, setExpandedSystem] = useState(null)
    const handleChangeSystemPanel = (system) => (event, isExpanded) =>
        setExpandedSystem(isExpanded ? system : false)
        ;
    if (!selectedAddress) return <Loading />
    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6" component="div">
                {selectedAddress.formatted}
            </Typography>
            <Stack direction="row" spacing={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => history.push(`/clients/${client.id}/addresses/${selectedAddress.id}/edit`)}
                >
                    {t`Modifier`}
                </Button>
            </Stack>
            <Box>
                {selectedAddress.systems.map((system) => {
                    const formData = (Object.keys(system.values).length === 0 && system.config)
                        ? system.config
                        : system.values
                        ;
                    return <SystemAccordion
                        formData={formData}
                        system={system}
                        expanded={expandedSystem?.id === system.id}
                        onChange={handleChangeSystemPanel(system)}
                    />
                })}
            </Box>
            <ClientAddressMap
                state={state}
                dispatch={dispatch}
            />
        </Stack>
    )
}


const SystemAccordion = props => {
    const { client, system, expanded, onChange } = props
    const [formData, setFormData] = useState(props.formData)
    const { enqueueSnackbar } = useSnackbar()
    const [ updateSystem, { loading, error } ] = useMutation(gql`
        mutation UpdateSystemConfigMutation($id: ID!, $input: SystemConfigInputType!) {
            updateSystemConfig(id: $id, input: $input) {
                id
                values
            }
        }
    `)

    return (
        <Accordion
            key={system.id}
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" spacing={2}>
                    <IconBarComponent icons={system.icons} />
                    <Typography>
                        {system.template.type}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <MyForm
                    schema={system.template.formSchema}
                    uiSchema={system.template.formUiSchema}
                    formData={formData}
                    readonly={false}
                    onChange={({ formData }) => setFormData(formData)}
                    onSubmit={async ({ formData }) => {
                        console.log(formData)
                        try {
                            await updateSystem({
                                variables: {
                                    id: system.id,
                                    input: {
                                        values: formData,
                                    },
                                },
                            })
                            enqueueSnackbar("Système mis à jour", { variant: "success" })
                        } catch (error) {
                            enqueueSnackbar(error.message, { variant: "error" })
                        }
                    }}
                    onError={() => console.log("errors")}
                    formContext={{
                        // formId: id,
                        client,
                        // this will allow us to access ALL of the forms data
                        // inside individual field templates (see FieldTemplate!)
                        data: formData,
                    }}
                />

            </AccordionDetails>
        </Accordion>
    )
}

const ClientAddresses = ({ clientId, state, dispatch }) => {
    const history = useHistory()

    const client = state?.client || {}
    const addresses = state?.client?.addresses || []

    const [showFilter, setShowFilters] = useState(false)

    const [page, setPage] = useState(0);
    const [searchBox, setSearchBox] = useState('')
    const debouncedSearchBox = useDebouncedValue(searchBox, 650)
    const [skip, setSkip] = useState(0)
    const [clientsPerPage, setClientsPerPage] = useLocalStorage('clients.clientDetails.clientsPerPage', 5)

    const { data, loading, errors } = useQuery(gql`
        query SearchClientAddressesQuery( $input: AddressSearchInputType! ) {
            searchAddresses( input: $input ) {
                count
                results {
                    id
                    formatted
                    icons {
                        name
                        color
                        label
                    }
                }
            }
        }
    `, {
        fetchPolicy: 'no-cache',
        variables: {
            input: {
                clientId,
                q: debouncedSearchBox,
                skip,
                first: clientsPerPage,
            },
        },
    })

    // useEffect(() => {
    //     if (data?.searchAddresses?.count > 0 && !state.selectedAddress) {
    //         history.replace(`/clients/${clientId}/addresses/${data.searchAddresses.results[0].id}`)
    //     }
    // }, [data, state.selectedAddress])

    const columns = [
        {
            field: 'formatted',
            headerName: t`Adresse`,
            flex: 1,
            renderCell: (params) => (
                <Stack direction="column" spacing={0}>
                    <Typography variant="body2" noWrap>
                        {params.row.formatted}
                    </Typography>
                </Stack>
            )
        },
        {
            field: 'systems',
            headerName: t`Systèmes`,
            flex: 0.2,
            renderCell: (params) => (
                <IconBarComponent icons={params.row.icons} />
            ),
        }
    ]

    const handlePageChange = (number) => {
        setPage(number)
        setSkip(clientsPerPage * number)
    }
    const handlePageSizeChange = (number) =>
        setClientsPerPage(number)
        ;

    const [lang] = useLocalStorage('lang', 'fr'); // const { me } = useMe(); // @TODO move the language conf entirely onto the user profile

    if (!client) return <Loading />

    // if (state.selectedAddress) {
    // return <ClientAddressDetails state={state} dispatch={dispatch} />
    // }

    const LOCALE_TEXT_MAP = {
        'fr': frFR.components.MuiDataGrid.defaultProps.localeText,
        'en': enUS.components.MuiDataGrid.defaultProps.localeText,
        'nl': nlNL.components.MuiDataGrid.defaultProps.localeText,
    }


    const localeText = LOCALE_TEXT_MAP[lang]

    return (
        <>
            <MyTextField
                sx={{ mb: 2 }}
                label={t`Rechercher`}
                autoFocus={true}
                value={searchBox}
                // onChange={(e) => dispatch({ type: 'setAddressSearch', value: e.target.value })}
                onChange={e => setSearchBox(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                // disabled={!state.addressSearch}
                                disabled={!searchBox}
                                // onClick={() => dispatch({ type: 'setAddressSearch', value: '' })}
                                onClick={() => setSearchBox('')}
                            >
                                <ClearIcon />
                            </IconButton>
                            {/**
                            <IconButton onClick={() => setShowFilters(!showFilter)}
                            >
                                <TuneIcon />
                            </IconButton>
                            */}
                        </InputAdornment>
                    )
                }}
            />

            {/**
            {showFilter && (
                <Paper elevation={10} sx={{ p: 2, mb: 2 }}>
                    <Stack direction="column" spacing={2}>
                        <Typography>{t`Filtres`}</Typography>
                        <MyTextField
                            variant="outlined"
                            label={t`Rechercher`}
                        />
                    </Stack>
                </Paper>
            )}
            */}

            <ZenDataGrid
                loading={loading || (searchBox !== debouncedSearchBox)}
                columns={columns}
                rows={data?.searchAddresses?.results || []}
                onRowClick={(params) => history.push(`/clients/${client.id}/addresses/${params.row.id}`)}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                // sortingMode='server' 
                // onSortModelChange={handleSortModelChange}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                pageSize={clientsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                rowCount={data?.searchAddresses?.count || 0}
                page={page}
                isRowSelectable={() => true}
                // density="compact" // "compact" | "standard" | "comfortable"
                autoHeight
                hideFooterRowCount
                localeText={localeText}
            />

            {/**
                                        <TableCell align="right"><IconBarComponent icons={address.icons} /></TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                                            <IconButton
                                                size="small"
                                                onClick={() => history.push(`/clients/${client.id}/addresses/${address.id}/edit`)}
                                                color="inherit"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                // onClick={() => history.push(`/clients/${client.id}/address/${address.id}`)}
                                                onClick={() => history.push(`/clients/${client.id}/addappointment/${address.id}`)}
                                                color="inherit"
                                            >
                                                <CalendarTodayIcon />
                                            </IconButton>

                                            <IconButton
                                                size="small"
                                                // onClick={() => history.push(`/address/${address.id}`)}
                                                onClick={() => history.push(`/clients/${client.id}/addsystem/${address.id}`)}
                                                color="inherit"
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => history.push(`/clients/${client.id}/deletesystem/${address.id}`)}
                                                color="inherit"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                */}
        </>
    )
}


const ClientContactInfo = ({ client }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {t`Adresse Principale`}
                </Typography>
                {valueOrNd(client?.primaryAddress?.formatted)}
            </Grid>
            {client?.billingAddress && <>
                <Grid item xs={12} >
                    <Typography variant="h6">
                        {t`Adresse de Facturation`}
                    </Typography>
                    {client?.billingAddress.formatted}
                </Grid>
            </>
            }
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">
                    {t`Tel.`}
                </Typography>
                {client.telephone
                    ? <a href={`tel://${client.telephone}`}>{client.telephone}</a>
                    : valueOrNd(null)
                }
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">
                    {t`Email`}
                </Typography>
                {valueOrNd(client.email)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Typography variant="h6">
                    {t`Réference`}
                </Typography>
                {valueOrNd(client.reference)}
            </Grid>
            {client.isBusiness && <>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Typography variant="h6">
                        {t`Numéro TVA`}
                    </Typography>
                    {valueOrNd(client.vatNumber)}
                </Grid>
                {/**
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {t`Entreprise`}
                    </Typography>
                    {valueOrNd(client.businessName)}
                </Grid>
                */}
            </>
            }
        </Grid>
    )
}


const useMyRouteMatch = patterns => {
    const { pathname } = useLocation()
    const possibleMatches = patterns
        .map(pattern => matchPath(pathname, pattern))
        .filter(match => match !== null)
        ;
    console.log('pathname', pathname, 'possibleMatches', possibleMatches)
    return possibleMatches.length > 0
        ? possibleMatches[0].path
        : null
        ;
}


const ClientInfoPage = () => {
    return (
        <>
            Graphs, logs, history, etc.
        </>
    )
}

const ClientDetails = () => {
    const { me } = useMe()
    const location = useLocation()
    const [createGseWorkOrder] = useMutation(gql`
        mutation CreateGseWorkOrderMutation ( $clientId: ID! ) {
            createGseWorkOrder ( clientId: $clientId ) {
                id
            }
        }
    `)
    // the dropdown menu for selecting new document types:
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const [addDocument] = useMutation(gql`
        mutation addDocument( $clientId: ID!, $file: Upload! ){
            docUpload( clientId: $clientId, file: $file ){
              id
            }
          }
    `)

    const [createForm, { loading: mutationLoading, error: mutationError }] = useMutation(gql`
        mutation CreateFormMutation($input: CreateFormInput!) {
            createForm(input: $input) {
                id
                uuid
                template {
                    id
                    displayName
                }
                client {
                    id
                    fullName
                }
            }
        }
    `)

    const onSave = async templateId => {
        const d = await createForm({
            variables: {
                input: {
                    clientId: id,
                    templateId,
                    values: {},
                }
            }
        })
        enqueueSnackbar('Document créé', { variant: 'success' })
        const { data: submitData, errors: submitErrors } = d
        if (submitErrors) {
            console.log(submitErrors)
            return
        }
        const formId = submitData.createForm.id
        await refetch()
        history.push(`/clients/${state.client.id}/documents/forms/${formId}`)
    }

    const myRouteMatch = useMyRouteMatch([
        '/clients/:id/documents',
        '/clients/:id/addresses',
        '/clients/:id/comments',
    ])
    // console.log('location', location)

    useEffect(() => {
        console.log('LOCATION CHANGED', location)
    }, [location])

    const { enqueueSnackbar } = useSnackbar();

    const [state, dispatch] = useReducer(clientDetailsReducer, {
        addressSearch: '',
        selectedAddress: null,
        hoveredAddress: null,
        client: null,
    })

    const { params, url } = useRouteMatch();
    const { id } = params;

    const { data, loading, refetch } = useQuery(CLIENT_QUERY, {
        variables: { id },
        onCompleted: data => {
            dispatch({ type: 'setClient', client: data?.client })
        }
    })

    const [updateClientComment] = useMutation(gql`
    mutation UpdateComment($id:ID!, $text:String!){
        updateClientComment(id:$id, text:$text){
          id
        }
      }
    `)

    const [deleteClientComment] = useMutation(gql`
    mutation Delete($clientCommentId:ID){
        delete(clientCommentId:$clientCommentId){
          ok
        }
      }
    `)
    // const [deleteDoc] = useMutation(gql`
    // mutation Delete($docId:ID){
    //     delete(docId:$docId){
    //       ok
    //     }
    //   }
    // `)
    const [addComment] = useMutation(gql`
    mutation ClientComment($clientId:ID!, $text:String!){
        createClientComment(clientId:$clientId, text:$text){
          id
        }
      }`)
    // const [addDocument] = useMutation(gql`
    // mutation addDocument($clientId:ID!, $file:Upload!){
    //     docUpload(clientId:$clientId, file:$file){
    //       id
    //     }
    //   }`)
    const [comment, setComment] = useState("")
    const history = useHistory()

    if (loading) return <Loading />

    const { client } = data
    const { organization } = client
    const { formTemplates } = organization

    const handleCommentChange = (e) => {
        setComment(e.target.value)
    }

    const handlePostComment = async () => {
        await addComment({
            variables: {
                clientId: id,
                text: comment,
            }
        })
        await refetch()
        enqueueSnackbar(t`Commentaire ajouté`, { variant: 'success' });
        setComment("")
    }

    // const handleFileChange = async (e) => {
    //     await addDocument({
    //         variables: {
    //             file: e.target.files[0],
    //             clientId: id,
    //         }
    //     })
    //     await refetch()
    //     enqueueSnackbar(t`Document ajouté`, { variant: 'success' });
    // }

    const handleUpdateComment = async (comment) => {
        await updateClientComment({ variables: comment })
        await refetch()
        enqueueSnackbar(t`Commentaire modifié`, { variant: 'success' });
    }

    const handleDeleteComment = async (id) => {
        await deleteClientComment({ variables: { clientCommentId: id } })
        await refetch()
        enqueueSnackbar(t`Commentaire supprimé`, { variant: 'success' });
    }

    // const handleDeleteDoc = async (id) => {
    //     await deleteDoc({ variables: { docId: id } })
    //     await refetch()
    // }

    if (!state.client) return <Loading />

    return <>
        <Box sx={{ mb: 2 }} >
            <BasicBreadcrumbs pages={[
                [`${client.fullName ? client.fullName : client.companyName ? client.companyName : 'sans nom'}`]
            ]} />

            <Stack direction="row" mb={2} spacing={1.5} alignItems="center">
                {client.isBusiness ? <BusinessIcon /> : <PersonIcon />}
                <Typography variant="h4" align="left">
                    {client.fullName}
                </Typography>
                <Stack spacing={1} direction="row">
                    <IconBarComponent icons={client.icons} fontSize={"large"} />
                </Stack>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 2 }}>
                <Button
                    onClick={() => history.push(`/clients/${client.id}/addresses/addaddress`)}
                    variant="outlined"
                    color="primary"
                    startIcon={<AddCircleOutlineIcon />}
                >
                    {t`Nouveau adresse`}
                </Button>

                <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >{t`Nouveau Document`}</Button>
                <Menu
                    id="document-filter-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => {

                        }}
                    >
                        <UploadIcon />
                        &nbsp;&nbsp;
                        {t`Télécharger vers le serveur`}
                    </MenuItem>
                    <Divider />

                    {(me.profile.organization.id === '1') && (
                        <MenuItem
                            onClick={async () => {
                                setAnchorEl(null)
                                const { data } = await createGseWorkOrder({ variables: { clientId: id } })
                                const workOrder = data.createGseWorkOrder;
                                enqueueSnackbar(`Fiche de travail cree`, { variant: 'success' })
                                history.push(`/gse/workorder/${workOrder.id}/edit`)
                            }}
                        >
                            <PictureAsPdfIcon />
                            &nbsp;&nbsp;
                            {t`Fiche de travail`}
                        </MenuItem>
                    )}

                    {formTemplates.map(formTemplate => (
                        <MenuItem
                            key={formTemplate.id}
                            onClick={() => {
                                setAnchorEl(null)
                                onSave(formTemplate.id)
                            }}
                        >
                            <PictureAsPdfIcon />
                            &nbsp;&nbsp;
                            {formTemplate.displayName}
                        </MenuItem>
                    ))}
                </Menu>
                <form>
                    <Button
                        variant="outlined"
                        // size="small"
                        component="label"
                        startIcon={<UploadIcon />}
                        onClick={async (e) => {
                            const { files } = e.target
                            if (!files) return
                            if (files.length === 0) return
                            if (files.length === 1) {
                                await addDocument({
                                    variables: {
                                        file: e.target.files[0],
                                        clientId: id,
                                    }
                                })
                                await refetch()
                                enqueueSnackbar(t`Document ajouté`, { variant: 'success' });
                            }
                        }}
                    >
                        {t`Upload`}
                        <HiddenInput type="file" />
                    </Button>
                </form>

                <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    disabled={true}
                >
                    {t`Nouveau RDV`}
                </Button>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Paper sx={{ p: 2, mb: 2 }}>
                        <ClientContactInfo client={client} />
                    </Paper>

                    <Paper sx={{ p: 2, mb: 2 }}>
                        <Tabs value={myRouteMatch} sx={{ mb: 2 }}>
                            <Tab label={t`Adresses`} value={"/clients/:id/addresses"} to={`${url}/addresses`} component={Link} />
                            <Tab label={t`Documents`} value={"/clients/:id/documents"} to={`${url}/documents`} component={Link} />
                            <Tab label={t`Notes`} value={"/clients/:id/comments"} to={`${url}/comments`} component={Link} />
                        </Tabs>

                        <Switch>
                            {/** 
                            <Route path="/clients/:id/documents"><DocumentTableComponent clientId={client.id} /></Route>
                            */}
                            <Route path="/clients/:id/documents"><DocumentsListing clientId={client.id} /></Route>
                            <Route path="/clients/:id/addresses"><ClientAddresses clientId={client.id} state={state} dispatch={dispatch} /></Route>
                            <Route path="/clients/:id/comments">
                                <Comments
                                    comments={client.comments}
                                    currentComment={comment}
                                    handleChange={handleCommentChange}
                                    handlePostComment={handlePostComment}
                                    handleUpdateComment={handleUpdateComment}
                                    handleDeleteComment={handleDeleteComment}
                                />
                            </Route>
                            <Route path="/clients/:id"><Redirect to={`/clients/${client.id}/addresses`} /></Route>
                        </Switch>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={7}>
                    <Grid item xs={12} // sm={12} md={5} lg={4}
                    // sx={{
                    //     display: { xs: 'none', sm: 'none', 'md': 'block' },
                    // }}
                    >
                        <Paper sx={{ p: 2, mb: 2 }}>
                            {/**
                            <Stack direction="column" spacing={2}>
                                <ClientAddresses state={state} dispatch={dispatch} />
                            </Stack>
                            */}

                            <Switch>
                                <Route exact path="/clients/:id/addresses/addaddress"><AddAddress state={state} dispatch={dispatch} /></Route>
                                <Route path="/clients/:id/addresses/:addressId/edit"><EditAddress state={state} dispatch={dispatch} /></Route>
                                <Route path="/clients/:id/addresses/:addressId"><ClientAddressDetails state={state} dispatch={dispatch} /></Route>

                                <Route exact path="/clients/:id/documents">Meh... documents</Route>
                                <Route exact path="/clients/:id/addresses">
                                    <ClientAddressMap
                                        state={state}
                                        dispatch={dispatch}
                                    />
                                </Route>

                                <Route path="/clients/:id/documents/forms/:id"><FormComponent /></Route>
                            </Switch>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            {/**
            <Grid container>
                <Grid item sm={12} // md={5} lg={4}
                >
                    <CustomCard>
                        <CardHeader
                            action={
                                <IconButton onClick={() => history.push(`/clients/${client.id}/edit`)} color="inherit">
                                    <EditIcon />
                                </IconButton>
                            }
                            title={t`Information Generale`} />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {t`Adresse Principale`}
                                    </Typography>
                                    {valueOrNd(primaryAddress?.formatted)}
                                </Grid>
                                {billingAddress && <>
                                    <Grid item xs={12} >
                                        <Typography variant="h6">
                                            {t`Adresse de Facturation`}
                                        </Typography>
                                        {billingAddress.formatted}
                                    </Grid>
                                </>
                                }
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <Typography variant="h6">
                                        {t`Tel.`}
                                    </Typography>
                                    {client.telephone
                                        ? <a href={`tel://${client.telephone}`}>{client.telephone}</a>
                                        : valueOrNd(null)
                                    }
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <Typography variant="h6">
                                        {t`Email`}
                                    </Typography>
                                    {valueOrNd(client.email)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <Typography variant="h6">
                                        {t`Réference`}
                                    </Typography>
                                    {valueOrNd(client.reference)}
                                </Grid>
                                {client.isBusiness && <>
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <Typography variant="h6">
                                            {t`Numéro TVA`}
                                        </Typography>
                                        {valueOrNd(client.vatNumber)}
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {t`Entreprise`}
                                        </Typography>
                                        {valueOrNd(client.businessName)}
                                    </Grid>
                                </>
                                }
                            </Grid>
                        </CardContent>
                    </CustomCard>
                </Grid>
            </Grid>
            

            <ClientAddresses state={state} dispatch={dispatch} />
            <DocumentTableComponent clientId={client.id} />
            */}

            {/**
            <CustomCard>
                <CardHeader
                    title={t`Adresses`}
                    action={
                        <IconButton onClick={() => history.push(`/clients/${client.id}/addaddress`)} color="inherit">
                            <AddCircleOutlineIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    {addresses.map((address, i) => {
                        if (address.systems.length === 0) {
                            return <><Trans>Pas de système à cette adresse</Trans>: <br /> {address.formatted}
                                <Stack
                                    direction={screenSize.dynamicWidth > 676 ? "row" : "column"}
                                    spacing={screenSize.dynamicWidth > 676 ? 1 : 0}
                                >
                                    <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                        <Button
                                            fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                            variant="outlined"
                                            onClick={() => history.push(`/clients/${client.id}/addsystem/${address.id}`)}
                                            startIcon={<AddCircleOutlineIcon />}
                                        >
                                            {t`ajouter un systeme`}
                                        </Button>
                                    </Box>
                                    <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                        <Button
                                            fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                            variant="outlined"
                                            onClick={() => history.push(`/clients/${client.id}/addappointment/${address.id}`)}
                                            startIcon={<AddCircleOutlineIcon />}
                                        >
                                            {t`ajouter un rdv`}
                                        </Button>
                                    </Box>
                                    <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                        <Button
                                            fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                            onClick={() => history.push(`/clients/${client.id}/editaddress/${address.id}`)}
                                            color="primary"
                                            startIcon={<EditIcon />}
                                            variant="outlined">
                                            {t`Modifier`}
                                        </Button>
                                    </Box>
                                </Stack>
                            </>
                        }
                        return <>
                            {i !== 0 && (<Box my={2}><Divider light /></Box>)}
                            <AddressSystemsTable
                                systems={address.systems}
                                addressFormatted={address.formatted}
                                clientId={id}
                                addressId={address.id}
                            />
                        </>
                    })}
                </CardContent>
            </CustomCard>
            */}

            {/**
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Comments
                        comments={client.comments}
                        currentComment={comment}
                        handleChange={handleCommentChange}
                        handlePostComment={handlePostComment}
                        handleUpdateComment={handleUpdateComment}
                        handleDeleteComment={handleDeleteComment}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DocumentUpload
                        handleChange={handleFileChange}
                        handleDeleteDoc={handleDeleteDoc}
                        documents={documents}
                    />
                </Grid>
            </Grid>
            */}

            {/* <ClientMutationLogsTable id={id} /> */}
        </Box>
    </>
}

export default ClientDetails;
