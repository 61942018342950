import { createTheme } from '@mui/material/styles';
import {
    red,
    amber,
    pink,
    blue,
    // green,
    blueGrey,
    // brown,
    // deepPurple,
    // indigo,
    // purple,
    // deepOrange,
    lime,
} from '@mui/material/colors';

import Box from '@mui/material/Box';
import Slider, { sliderClasses } from '@mui/material/Slider';
import { paperClasses } from '@mui/material/Paper';

import { darken, lighten } from '@mui/material/styles';


const lightTheme = createTheme({
    palette: {
        mode: 'light',
        text: {
            primary: '#000',
            secondary: '#777',
            disabled: '#aaa',
        },
        primary: {
            // main: '#52e052', // this is the same green from highways in the "light" map
            main: blueGrey[500],
            contrastText: '#fff',
        },
        secondary: {
            main: red[500],
            contrastText: '#f6f7f9',
        },
        success: {
            main: blueGrey[500],
            contrastText: '#fff',
        },
        info: {
            main: blue.A200,
            contrastText: blue[50],
        },
        warning: {
            main: amber.A200,
            contrastText: '#333',
        },
        error: {
            main: red.A400,
            contrastText: red[50],
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#f6f7f9",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    // backgroundColor: '#52e052',
                    // backgroundColor: deepOrange[800],
                    backgroundColor: blueGrey[700],
                    color: '#fff',
                }
            }
        }
    },
    typography: {
        fontFamily: '"Ubuntu", "Lato", "San Francisco", Helvetica, Arial, sans-serif',
    }
})

const solarizedTheme = createTheme({
    palette: {
        mode: 'dark',
        text: {
            primary: '#fff',
            secondary: '#aaa',
        },
        primary: {
            main: lime.A200,
            contrastText: '#333',
        },
        secondary: {
            main: pink.A200,
            contrastText: '#f2f3f4',
        },
        success: {
            // main: green.A200,
            // contrastText: green[900],
            main: lighten('#1f2930', .3),
            contrastText: '#fff',
        },
        info: {
            main: blue.A200,
            contrastText: blue[50],
        },
        warning: {
            main: amber.A200,
            contrastText: '#333',
        },
        error: {
            main: red.A400,
            contrastText: red[50],
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#172b3a",
                    contrastText: '#555e66',
                },
            },
        },
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true
            },
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#09141c',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: darken('#0f202d', .3),
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#09141c',
                    color: '#f2f3f4',
                }
            }
        },
    },
    typography: {
        fontFamily: '"Ubuntu", "Lato", "San Francisco", Helvetica, Arial, sans-serif',
    }
});


const prideTheme = createTheme({
    palette: {
        mode: 'dark',
        text: {
            primary: '#fff',
            secondary: '#aaa',
        },
        primary: {
            main: pink.A200,
            contrastText: '#333',
        },
        secondary: {
            main: pink.A200,
            contrastText: '#f2f3f4',
        },
        success: {
            // main: green.A200,
            // contrastText: green[900],
            main: lighten('#1f2930', .3),
            contrastText: '#fff',
        },
        info: {
            main: blue.A200,
            contrastText: blue[50],
        },
        warning: {
            main: amber.A200,
            contrastText: '#333',
        },
        error: {
            main: red.A400,
            contrastText: red[50],
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // backgroundColor: "#222",
                    // backgroundImage: 'linear-gradient(90deg, #222 0%, #111 100%)',
                    backgroundImage: 'linear-gradient(90deg, pink 0%, #11f 100%)',
                    backgroundSize: 'cover',
                    contrastText: '#555e66',
                },
                '& a': {
                    color: pink.A100,
                    fontWeight: 'bold',
                    textDecoration: 'none',
                }
            },
        },
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true
            },
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: 'hotpink',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'linear-gradient(0deg, turquoise 0%, hotpink 100%)',
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#121212',
                    color: '#f2f3f4',
                }
            }
        },
    },
    typography: {
        fontFamily: '"Ubuntu", "Lato", "San Francisco", Helvetica, Arial, sans-serif',
    }
});


const anotherDarkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: {
            primary: '#fff',
            secondary: '#aaa',
        },
        primary: {
            main: amber.A100,
            contrastText: '#333',
        },
        secondary: {
            main: pink.A200,
            contrastText: '#f2f3f4',
        },
        success: {
            // main: green.A200,
            // contrastText: green[900],
            main: lighten('#1f2930', .3),
            contrastText: '#fff',
        },
        info: {
            main: blue.A200,
            contrastText: blue[50],
        },
        warning: {
            main: amber.A200,
            contrastText: '#333',
        },
        error: {
            main: red.A400,
            contrastText: red[50],
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#222",
                    contrastText: '#555e66',
                },
                '& a': {
                    color: amber.A100,
                    fontWeight: 'bold',
                    textDecoration: 'none',
                }
            },
        },
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true
            },
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#000000',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: darken('#222', .6),
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#121212',
                    color: '#f2f3f4',
                }
            }
        },
    },
    typography: {
        fontFamily: '"Ubuntu", "Lato", "San Francisco", Helvetica, Arial, sans-serif',
    }
});


export const PrintableTheme = createTheme({})

export const themeMap = {
    "light": lightTheme,
    // "dark": solarizedTheme,
    "dark": anotherDarkTheme,
    // "dark": prideTheme,
}