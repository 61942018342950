import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useRouteMatch, NavLink } from "react-router-dom";
import {
    useQuery,
    gql,
    useMutation,
    // useMutation,
} from '@apollo/client';

import { t } from '@lingui/macro'


// import Form from '@rjsf/core';
import Form, { Templates } from '@rjsf/mui';

// import { RJSFSchema } from '@rjsf/utils';

import validator from '@rjsf/validator-ajv8';

import CodeEditor from '@uiw/react-textarea-code-editor';

// import { Wrapper } from "@googlemaps/react-wrapper"; // @TODO can be removed?
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import ReactMapGL, { Marker, FullscreenControl } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import '../App.css'

import { MyForm, FormComponent, MyJsonEditor } from './Forms'

import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Typography,
    Tabs,
    Tab,
    Box,
    Button,
    MenuItem,
    Chip,
    Stack,
    InputAdornment,
    IconButton,
    Autocomplete,
} from '@mui/material';
import Container from '@mui/material/Container';
import {
    // LocalFireDepartmentOutlined,
    // WorkspacePremiumOutlined,
    // FingerprintOutlined,
    ShieldOutlined,
    // PolicyOutlined,
    WarningAmber,
    EditOutlined,
}
    from '@mui/icons-material';

import {
    useLocalStorage,
    AddressForm,
    Loading,
    MyTextField,
    LinkTab,
    DOCUMENT_STATE_MAPPING,
} from './Common'

import mapboxgl from "mapbox-gl";
import { useSnackbar } from 'notistack';

import { Settings } from '../Settings';

// import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import {
    Switch, Route, useRouteMatch, Redirect, NavLink, useParams, useHistory,
    // useHistory,
} from 'react-router-dom';

import '../App.css'
import { SelectClient } from './Tasks';
import { SignatureForm } from './WorkOrders';
import { useMe } from '../CurrentUserContext';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

console.log(Templates)


// const mapboxApiAccessToken = 'pk.eyJ1Ijoic2hlYW4tbWFzc2V5IiwiYSI6ImNreDNpeWM4azB3ZXcybnVxZ3pwNjg4ajYifQ.ghh310nUqLxMbetMM5sMSw'
// const mapboxApiAccessToken = 'pk.eyJ1Ijoic21hc3NleSIsImEiOiJja3h4Y2ljNDU0cGU1Mnh1Ym9meWpveTNnIn0.Lv4S-HXm7PcQ86jdEAAljA'

const mapboxApiAccessToken = 'pk.eyJ1Ijoic2hlYW4tbWFzc2V5IiwiYSI6ImNreDNpeWM4azB3ZXcybnVxZ3pwNjg4ajYifQ.ghh310nUqLxMbetMM5sMSw'


const SystemMarker = ({ system, onHover, dataMapper, onClick, selected, overdue }) => {
    onHover = onHover || (() => { })
    dataMapper = dataMapper || (a => a); // default is an identity function 
    const a = dataMapper(system)
    // const [hovering, setHovering] = useState(false)
    const { palette } = useTheme()
    if (!(a.lat && a.lng)) {
        return <></>
    }

    const hasAppointment = system.upcomingMaintenanceAppointments.length > 0

    const getColors = (() => {
        const defaultBgColor = (palette.mode === "dark")
            ? '#0f202d'
            : '#aaa';

        const _getColors = () => {
            if (selected) {
                if (hasAppointment) {
                    return { bgcolor: 'secondary.dark', color: 'secondary.contrastColor' }
                } else {
                    return { bgcolor: 'primary.dark', color: 'primary.contrastColor' }
                }
            } else {
                if (hasAppointment) {
                    return { bgcolor: defaultBgColor, color: 'secondary.main' }
                } else {
                    return { bgcolor: defaultBgColor, color: 'primary.main' }
                }
            }
        }

        const c = _getColors();

        // inverse the colors for overdue items:
        if (overdue) return { bgcolor: c.color, color: c.bgcolor }
        return c;
    })

    return (
        <Marker latitude={a.lat} longitude={a.lng} offsetLeft={-20} offsetTop={-10}>
            <Box
                className={selected ? 'pulsate' : ''}
                style={{
                    borderRadius: '2rem',
                    paddingTop: '.3rem',
                }}
                sx={{
                    ...getColors(),
                    cursor: 'pointer',
                }}
                onMouseEnter={() => {
                    onHover(system)
                }}
                onMouseLeave={() => {
                    onHover(null)
                }}
                onClick={() => onClick(system)}
            >
                {overdue ? <WarningAmber /> : <ShieldOutlined />}
            </Box>
        </Marker>
    )
}

export const SystemMapComponent = ({ systems, overdueSystems, center, fullScreen, onHover, height, dataMapper, selectedSystemIds, onClick }) => {
    onHover = onHover || (() => { console.log('no onHover handler defined') })
    height = height || '100%'

    const [map, setMap] = useState(null)
    const mapRef = useCallback(node => {
        if (node !== null) {
            setMap(node)
        }
    }, [])

    const [viewport, setViewport] = useState({
        latitude: center?.lat || 50.8476, // default is center bxl here
        longitude: center?.lng || 4.3572,
        zoom: 9,
    })

    const { palette } = useTheme();

    const mapStyle = (palette.mode === "light")
        ? 'mapbox://styles/mapbox/navigation-day-v1'
        : 'mapbox://styles/mapbox/navigation-night-v1'
        ;

    const fullscreenControlStyle = {
        right: 10,
        top: 10
    };

    const isSelected = system => selectedSystemIds.indexOf(system.id) >= 0;

    return (
        <>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                style={{
                    width: '100%',
                    height,
                }}
                mapboxAccessToken={mapboxApiAccessToken}
                onMove={e => {
                    setViewport({ ...viewport, ...e.viewState, width: 'fit' })
                }}
                mapStyle={mapStyle}
                onResize={newDimensions => {
                    setViewport({ ...viewport, width: '100%', height: '100%' })
                }}
            >
                {systems.map((system, i) =>
                    <SystemMarker
                        key={i}
                        selected={isSelected(system)}
                        onClick={onClick}
                        dataMapper={dataMapper}
                        system={system}
                        onHover={a => onHover(a ? system : null)}
                    />
                )}
                {overdueSystems.map((system, i) =>
                    <SystemMarker
                        key={i}
                        selected={isSelected(system)}
                        onClick={onClick}
                        dataMapper={dataMapper}
                        system={system}
                        onHover={a => onHover(a ? system : null)}
                        overdue={true}
                    />
                )}
                {fullScreen && <FullscreenControl style={fullscreenControlStyle} />}
            </ReactMapGL>
        </>
    )
}



const AddressMarker = ({ address, onHover, addressMap, onClick }) => {
    onHover = onHover || (() => { })
    addressMap = addressMap || (a => a); // default is an identity function 
    const a = addressMap(address)

    const [hovering, isHovering] = useState(false)

    if (!(a.lat && a.lng)) {
        return <></>
    }

    return (
        <Marker latitude={a.lat} longitude={a.lng} offsetLeft={-20} offsetTop={-10}>
            <Box
                className={hovering ? '' : `pulsate`}
                style={{
                    borderRadius: '2rem',
                    paddingTop: '.3rem',
                }}
                sx={{
                    bgcolor: hovering ? 'primary.main' : 'primary.dark',
                    color: 'primary.contrastText'
                }}
                onMouseEnter={() => {
                    onHover(address)
                    isHovering(true)
                }}
                onMouseLeave={() => {
                    onHover(null)
                    isHovering(false)
                }}
            >
                <ShieldOutlined />
            </Box>
        </Marker>
    )
}

export const AddressMapComponent = ({ addresses, center, fullScreen, onHover, height, addressMap }) => {
    onHover = onHover || (() => { console.log('no onHover handler defined') })
    height = height || '20rem'
    const mapRef = useRef()
    const [viewport, setViewport] = useState({
        width: 'fit',
        height,
        // height: '',
        // minHeight: 200,
        latitude: center.lat,
        longitude: center.lng,
        zoom: 9,
    })

    // const STYLES = [
    //     'mapbox://styles/mapbox/streets-v11',
    //     'mapbox://styles/mapbox/outdoors-v11',
    //     'mapbox://styles/mapbox/light-v10',
    //     'mapbox://styles/mapbox/dark-v10',
    //     'mapbox://styles/mapbox/satellite-v9',
    //     'mapbox://styles/mapbox/satellite-streets-v11',
    //     'mapbox://styles/mapbox/navigation-day-v1',
    //     'mapbox://styles/mapbox/navigation-night-v1',
    // ]
    // const [mapStyle, setMapStyle] = useState(STYLES[7])

    const { palette } = useTheme();

    const mapStyle = (palette.mode === "light")
        ? 'mapbox://styles/mapbox/navigation-day-v1'
        : 'mapbox://styles/mapbox/navigation-night-v1'
        ;

    const fullscreenControlStyle = {
        right: 10,
        top: 10
    };

    return (
        <>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                mapboxApiAccessToken={mapboxApiAccessToken}
                onViewportChange={newViewport => setViewport({ ...newViewport, width: 'fit' })}
                mapStyle={mapStyle}
            >
                {addresses.map((address, i) => <AddressMarker key={i} addressMap={addressMap} address={address} onHover={a => onHover(a ? address : null)} />)}
                {fullScreen && <FullscreenControl style={fullscreenControlStyle} />}
            </ReactMapGL>
        </>
    )
}

// const LinkTab = props => {
//     // const { onClick } = props;
//     return (
//         <Tab
//             component={NavLink}
//             {...props}
//         />
//     )
// }

class DemoEvalContainer extends React.Component {
    render() {
        return React.createElement('div', null, `Moot ${this.props.demoString}`);
    }
}

const DemoEvalContainerFunction = props =>
    React.createElement('div', null, `Moot ${props.demoString}`)

const DemoEvalContainerComponent = () => (
    <>
        <DemoEvalContainer demoString={`w00t`} />
        <DemoEvalContainerFunction demoString={`w00t2 \${props.x}`} x={'42'} />
    </>
)

const MyNavTabs = ({ tabs }) => {
    const matched = useRouteMatch({ path: "/demo/:matched" });
    const [value, setValue] = useState(matched?.url)
    if (!matched) return <></>
    // console.log(`inside MyNavTabs, path, url = ${path}, ${url}`)
    // console.log(`value = ${value}`)
    return (
        <Tabs
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
            sx={{ mb: 2 }}
        >
            {tabs.map((tab, i) => (
                <LinkTab
                    key={i}
                    to={tab.to}
                    label={tab.label}
                    value={tab.to}
                />
            ))}
        </Tabs>
    )
}

export const DemoRouter = () => {
    const { path, url } = useRouteMatch();
    const [value, setValue] = useState(0)
    console.log(`init path, url = ${path}, ${url}`)
    return (
        <Container>
            <MyNavTabs tabs={[
                // { to: `${url}/map`, label: 'Map' },
                { to: `${url}/templates`, label: 'Templates' },
                { to: `${url}/create-form-template`, label: 'New form template' },
                { to: `${url}/create-form`, label: 'New form' },
            ]} />

            <Switch>
                {/**
                <Route path={`${path}/eval`}><DemoEvalContainerComponent /></Route>
                <Route path={`${path}/form`}><DemoForms /></Route>
                */}
                <Route path={`${path}/forms/:id`}><FormComponent /></Route>
                <Route path={`${path}/create-form`}><CreateForm /></Route>
                <Route path={`${path}/create-form-template`}><CreateFormTemplate /></Route>
                <Route path={`${path}/templates`}><ListFormTemplates /></Route>
                <Route path={`${path}/template/:id`}><EditFormTemplate /></Route>

                <Route exact path={`${path}/`}><Redirect to={`${path}/templates`} push={false} /></Route>
            </Switch>
        </Container>
    )
}

const MB = props => {
    const title = props.title || 'Untitled'
    const style = props.style || {}
    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '1rem', padding: 10, border: '2px solid yellow', overflow: 'scroll', ...style }}>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            <div {...props} />
        </div>
    )
}
export const ListFormTemplates = () => {
    const { path, } = useRouteMatch();
    const { data, loading, error } = useQuery(gql`
        query ListFormTemplatesQuery {
            formTemplates {
                id
                displayName
                organization {
                    name
                    lang
                }
                forms {
                    id
                    client {
                        id
                        fullName
                    }
                }
            }
        }
    `, {
        fetchPolicy: 'cache-and-network',
    })

    if (loading) return <Loading />
    if (error) return 'error...'

    const { formTemplates } = data

    // window.alert(`path: ${path}`)

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>client</th>
                        <th>display name</th>
                    </tr>
                </thead>
                <tbody>
                    {formTemplates.map(({ organization, displayName, id, forms }) => (
                        <tr>
                            <td>
                                <NavLink to={`/demo/template/${id}`}>{organization.name}</NavLink>
                            </td>
                            <td>{displayName}</td>
                            <td>
                                <ul>
                                    {forms.map(({ id, client }) => (
                                        <li key={id}>
                                            <NavLink to={`./forms/${id}`}>{client.fullName}</NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}


export const CreateFormTemplate = () => {
    const history = useHistory()
    const [displayName, setDisplayName] = useState('')
    const [createFormTemplate] = useMutation(gql`
        mutation CreateFormTemplateMutation ( $input: CreateFormTemplateInput! ) {
            createFormTemplate ( input: $input ) {
                id
            }
        }
    `)
    return (
        <Paper sx={{ p: 2 }}>
            <Stack spacing={2} direction="column">
                <MyTextField
                    label="Display Name"
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                />
                <Stack spacing={2} direction="row">
                    <Button
                        variant="contained"
                        onClick={async () => {
                            const { data, errors } = await createFormTemplate({
                                variables: {
                                    input: {
                                        displayName,
                                    }
                                }
                            })
                            if (errors) {
                                console.log(errors)
                                return;
                            }
                            const { createFormTemplate: { id } } = data
                            history.push(`/demo/templates/${id}`)
                        }}>
                        Create
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    )
}


export const EditFormTemplate = () => {
    const { id } = useParams()
    const [isValid, setIsValid] = useState(false)

    const [displayName, setDisplayName] = useState('')
    const [jsonSchema, setJsonSchema] = useState({})
    const [uiSchema, setUiSchema] = useState({})
    const [template, setTemplate] = useState('')
    const [unsavedChanges, hasUnsavedChanges] = useState(false)

    const [formData, setFormData] = useState({})

    const [updateFormTemplate, { loading: mutationLoading, error: mutationError }] = useMutation(gql`
        mutation UpdateFormTemplateMutation ( $input: UpdateFormTemplateInput! ) {
            updateFormTemplate ( input: $input ) {
                id
            }
        }
    `)

    const { data, loading, error, refetch } = useQuery(gql`
        query EditFormTemplateQuery($id: ID!) {
            formTemplate(id: $id) {
                id
                organization {
                    id
                    name
                    lang
                }
                displayName
                formSchema
                formUiSchema
                template
            }
        }
    `, {
        variables: { id },
        fetchPolicy: 'no-cache',
        onCompleted: ({ formTemplate }) => {
            setDisplayName(formTemplate.displayName)
            setJsonSchema(formTemplate.formSchema)
            setUiSchema(formTemplate.formUiSchema)
            setTemplate(formTemplate.template)
            hasUnsavedChanges(false)
        }
    })

    if (loading || mutationLoading) return <Loading />
    if (error || mutationError) return 'error...'

    const { formTemplate } = data

    const wrappedSetJsonSchema = (value) => {
        setJsonSchema(value)
        hasUnsavedChanges(true)
    }

    const wrappedSetUiSchema = (value) => {
        setUiSchema(value)
        hasUnsavedChanges(true)
    }

    const wrappedSetDisplayName = (value) => {
        setDisplayName(value)
        hasUnsavedChanges(true)
    }


    const onSubmit = async () => {
        const { data, errors } = await updateFormTemplate({
            variables: {
                input: {
                    id,
                    displayName,
                    formSchema: jsonSchema,
                    formUiSchema: uiSchema,
                    template,
                }
            }
        })
        if (errors) {
            console.error(errors)
            return
        }
        await refetch()
        hasUnsavedChanges(false)
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={4}>
                    <Stack spacing={2} direction="column">
                        <Paper sx={{ p: 2 }}>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="h6" gutterBottom>Form Template</Typography>
                                <MyTextField
                                    label="Display Name"
                                    value={displayName}
                                    onChange={e => wrappedSetDisplayName(e.target.value)}
                                />
                            </Stack>
                        </Paper>
                        <MyJsonEditor
                            title="JSON Schema"
                            value={jsonSchema}
                            setValue={wrappedSetJsonSchema}
                        />
                        <MyJsonEditor
                            title="UI Schema"
                            value={uiSchema}
                            setValue={wrappedSetUiSchema}
                        />
                    </Stack>
                </Grid>
                <Grid item sm={12} md={6} lg={8}>
                    <Stack spacing={2} direction="column">
                        <Paper sx={{ p: 2 }}>
                            <MyForm
                                schema={jsonSchema}
                                uiSchema={uiSchema}
                                formData={formData}
                                onChange={({ formData }) => setFormData(formData)}
                                onSubmit={({ formData }) => console.log(formData)}
                                onError={() => console.log("errors")}
                                formContext={{
                                    formId: id,
                                    client: {
                                        id: 0,
                                        addresses: [],
                                    },
                                    // this will allow us to access ALL of the forms data
                                    // inside individual field templates (see FieldTemplate!)
                                    data: formData,
                                }}
                            />
                        </Paper>
                        <MyJsonEditor
                            title="Form Data"
                            value={formData}
                            setValue={setFormData}
                        />
                    </Stack>

                </Grid>
                <Grid item sm={12}>
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="contained"
                            onClick={onSubmit}
                            disabled={!unsavedChanges}
                        >
                            Save
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}


export const CreateForm = () => {
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedFormTemplate, setSelectedFormTemplate] = useState('')
    const history = useHistory()

    const [createForm, { loading: mutationLoading, error: mutationError }] = useMutation(gql`
        mutation CreateFormMutation($input: CreateFormInput!) {
            createForm(input: $input) {
                id
                uuid
                template {
                    id
                    displayName
                }
                client {
                    id
                    fullName
                }
            }
        }
    `)

    const { data, loading, error } = useQuery(gql`
        query CreateFormQuery {
            formTemplates {
                id
                displayName
            }
        }
    `)

    const onSave = async () => {
        if (!selectedClient || !selectedFormTemplate) return
        const d = await createForm({
            variables: {
                input: {
                    clientId: selectedClient,
                    templateId: selectedFormTemplate,
                    values: {},
                }
            }
        })
        const { data: submitData, errors: submitErrors } = d
        if (submitErrors) {
            console.log(submitErrors)
            return
        }
        const { id } = submitData.createForm
        // navigate(`/forms/${id}`)
        history.push(`./forms/${id}`)
    }

    if (loading || mutationLoading) return <Loading />
    if (error || mutationError) return 'error...'

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <SelectClient
                        selected={selectedClient}
                        onSelected={setSelectedClient}
                        autoFocus={true}
                    />

                    <MyTextField
                        label='Form Template'
                        value={selectedFormTemplate}
                        onChange={e => setSelectedFormTemplate(e.target.value)}
                        select
                        SelectProps={{
                            // native: true,
                        }}
                        variant='outlined'
                        fullWidth
                    >
                        <MenuItem value=''></MenuItem>
                        {data.formTemplates.map(({ id, displayName }) => (
                            <MenuItem key={id} value={id}>{displayName}</MenuItem>
                        ))}
                    </MyTextField>

                    <Stack direction="row" spacing={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={onSave}
                        >
                            Submit
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </>
    )
}
