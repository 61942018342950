import { gql, useQuery } from "@apollo/client"
import { useReducer, useState } from "react"
import { useParams } from "react-router-dom"
import { Settings } from "../Settings"
import { Loading } from "./Common"
import {
    Box,
    Stack,
    Button,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,

    CircularProgress,

    Typography,
} from '@mui/material'

import { t } from '@lingui/macro'
import { SignatureForm } from "./WorkOrders"


const I18N_SIGN_HERE = t`SIGNER ICI`
const I18N_SUBMIT = t`SOUMETTRE`


const LandingDialog = props => {
    const { loading, organization } = props

    const [open, setOpen] = useState(true)
    const handleClose = () => {
        if (!loading) {
            setOpen(false)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <DialogContentText>
                    Cher/Chère,
                </DialogContentText>
                <DialogContentText>
                    {t`Nous vous remercions de votre coopération. Pour signer le document, il suffit de le lire attentivement, puis de cliquer sur ${I18N_SIGN_HERE}. Après avoir signé, veuillez cliquer sur ${I18N_SUBMIT}. En cas de problème ou de question, n'hésitez pas à contacter notre équipe de support à ${organization.supportEmail || 'N/A'}.`}.
                </DialogContentText>
                <DialogContentText>
                    Cordialement,
                </DialogContentText>
                <DialogContentText>
                    <Typography sx={{ fontWeight: "bold" }}>{organization.name}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box sx={{ position: 'relative', m: 1 }}>
                    <Button
                        variant="contained"
                        // endIcon={loading ? <Loading /> : null}
                        disabled={loading}
                        onClick={handleClose}>
                        OK
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: 'primary',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const SignatureDialog = ({ state, dispatch }) => {
    const open = state.pageState === 'signatureForm'
    const [ clientSignature, setClientSignature ] = useState('')
    
    return (
        <Dialog
            maxWidth="lg"
            open={open}
            onClose={() => {
                dispatch({ type: 'setPageState', payload: 'landing' })
            }}
        >
            <DialogContent>
                <DialogContentText>
                    <SignatureForm
                        title={t`Veuillez signer ci-dessous`}
                        state={{
                            clientSignature,
                        }}
                        dispatch={({ value }) => {
                            setClientSignature(value)
                        }}
                        backgroundColor='#fff'
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={clientSignature.length === 0}
                    variant="contained"
                    onClick={() => { }}
                    size="large"
                    color="primary"
                >
                    {I18N_SUBMIT}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'setPageState':
            return {
                ...state,
                pageState: action.payload
            }
        default:
            return state
    }
}

export const SignatureRequestComponent = props => {
    const [ state, dispatch ] = useReducer(reducer, {
        pageState: 'landing'
    })

    const { uuid } = useParams()
    const [iframeLoading, setIframeLoading] = useState(true)

    const handleSignHereClicked = () => {
        dispatch({ type: 'setPageState', payload: 'signatureForm' })
    }

    const { data, loading, error } = useQuery(gql`
        query SignatureRequestQuery( $uuid: ID! ) {
            signatureRequest( uuid: $uuid ) {
                uuid
                formUuid
                organization {
                    name
                    supportEmail
                }
            }
        }
    `, {
        variables: { uuid }
    })

    // if (loading) return <p>Loading...</p>
    if (error) return <p>Error: {error.message}</p>

    if (!data?.signatureRequest && !loading) return <p>No signature request found with id:{uuid}</p>

    const organization = data?.signatureRequest?.organization || {
        name: '...',
        supportEmail: '...'
    }

    return (
        <>
            {state.pageState === 'landing' ? (
                <LandingDialog loading={iframeLoading || loading} organization={organization} />
            ) : (state.pageState === 'signatureForm') ? (
                <SignatureDialog state={state} dispatch={dispatch} />
            ) : (
                <>
                    ALL DONE.
                </>
            )}
            <Box sx={{ display: 'block' }}>
                {(!loading) && (
                    <iframe
                        style={{
                            margin: '5vh auto 0 auto',
                            width: 'calc(210mm + 25px)',
                            height: '84vh',
                            display: iframeLoading ? 'none' : 'block',
                            // display: 'block',
                        }}
                        onLoad={() => setIframeLoading(false)}
                        src={`${Settings.BACKEND_URL}/export/printable/form-preview/${data.signatureRequest.formUuid}/`}>
                    </iframe>
                )}
                {iframeLoading ? null : (
                    <Stack direction="row" spacing={2} sx={{ margin: '2vh auto 0 auto', width: 'fit-content' }}>
                        <Button
                            variant="contained"
                            onClick={handleSignHereClicked}
                            size="large"
                            color="primary"
                        >
                            {I18N_SIGN_HERE}
                        </Button>
                    </Stack>
                )}
            </Box>
        </>
    )
}