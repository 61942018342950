import { AddressForm, DEFAULT_ADDRESS } from "./Common";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useState } from "react";
import { useSnackbar } from 'notistack';

import { t } from '@lingui/macro'

import {
    Typography,
    Stack,
    Box,
} from '@mui/material';

import {
    GoBackButton,
    SaveButton,
} from "./Common";


export const AddAddress = ({ state, dispatch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const { params } = useRouteMatch()
    const { id } = params
    const { refetch } = useQuery(gql`
    query ClientQuery( $id: ID! ){
      client( id: $id ){
        id
        addresses {
          id
          formatted
          number
          street
          city
          postalCode
          city
          country
          systems {
            id
            type
            config
            dateInstallation
            omnium
            contratEntretien
            maintenanceDueDate
            maintenanceCycleDays
            sim {
              id
            }
            comments{
              id
              text
              createdBy {
                  id
                  firstName
              }
              createdAt
            }
            installers {
                id
                firstName
            }
            address {
                id
                formatted
            }
            appointments {
                id
                datetime
                technicians {
                    id
                    firstName
                }
            }
          }
        }
      }
    }
    `, { variables: { id: id } })
    const [AddAddress, { loading }] = useMutation(gql`
        mutation AddAddressMutation ( $address: AddressInput!, $clientId: ID! ) {
            newCreateAddress( address: $address, clientId: $clientId ) {
                id
            }
        }
    `)
    const [address, setAddress] = useState({ ...DEFAULT_ADDRESS })
    const onSubmit = async () => {
        await AddAddress({ variables: { address, clientId: id } })
        await refetch()
        enqueueSnackbar(t`Adresse ajoutée`, { variant: 'success' });
        history.push(`/clients/${id}/view`)
    }
    return (
        <Stack direction="column" spacing={0}>
            <Typography variant="h6">{t`Nouvelle Adresse`}</Typography>
            <Box sx={{ my: 2 }}>
                <AddressForm
                    address={address}
                    onChange={a => setAddress(a)}
                />
            </Box>
            <Stack direction="row" spacing={2}>
                <GoBackButton />
                <SaveButton onClick={onSubmit} disabled={loading}>
                    {t`Sauvgarder`}
                </SaveButton>
            </Stack>
        </Stack>
    )
}
