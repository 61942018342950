import React, { useState, useRef, useReducer } from 'react';
// import { useRouteMatch, NavLink } from "react-router-dom";
import {
    useQuery,
    gql,
    useMutation,
    // useMutation,
} from '@apollo/client';

// import Form from '@rjsf/core';
import Form from '@rjsf/mui';

import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import CodeEditor from '@uiw/react-textarea-code-editor';

// import { Wrapper } from "@googlemaps/react-wrapper"; // @TODO can be removed?
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import ReactMapGL, { Marker, FullscreenControl } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import '../App.css'

import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Typography,
    Button,
    MenuItem,
    Stack,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Checkbox,
    ListItemText,
    IconButton,
    InputAdornment,
} from '@mui/material';

import {
    // LocalFireDepartmentOutlined,
    // WorkspacePremiumOutlined,
    // FingerprintOutlined,
    ShieldOutlined,
    // PolicyOutlined,
    WarningAmber,
    EditOutlined,
}
    from '@mui/icons-material';

import Container from '@mui/material/Container';

import {
    useLocalStorage,
    AddressForm,
    Loading,
    MyTextField,
    LinkTab,
} from './Common'

import mapboxgl from "mapbox-gl";
import { useSnackbar } from 'notistack';

import { Settings } from '../Settings';

// import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import {
    Switch, Route, useRouteMatch, Redirect, NavLink, useParams, useHistory,
    // useHistory,
} from 'react-router-dom';

import '../App.css'
import { SelectClient } from './Tasks';
import { SignatureForm } from './WorkOrders';


// const useOrgnaization = id => {
//     const { data, loading, error, refetch: refetchOrganization } = useQuery(gql`
//         query Organization($id: ID!) {
//             organization(id: $id) {
//                 id
//                 name
//                 description
//             }
//         }
//     `, { variables: { id } });
//     if (loading) return <CircularProgress />
//     if (error) return 'ERROR: ' + error.message
//     const { organization } = data
//     return { 
//         organization,
//         refetchOrganization,
//     }
// }


const createUserOrganizationFormReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                [action.field]: action.value,
            }
        // case 'add':
        //     const index = state[action.field].findIndex(item => item == action.value)
        //     if (index == -1) {
        //         return {
        //             ...state,
        //             [action.field]: [...state[action.field], action.value],
        //         }
        //     }
        //     return state
        // case 'remove':
        //     return {
        //         ...state,
        //         [action.field]: state[action.field].filter(item => item != action.value),
        //     }
        default:
            throw new Error();
    }
}

export const CreateOrganizationUserComponent = () => {
    const history = useHistory()
    const [createOrganizationUser] = useMutation(gql`
        mutation CreateOrganizationUser ($input: CreateOrganizationUserInput!) {
            id
        }
    `)
    const [state, dispatch] = useReducer(createUserOrganizationFormReducer, {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        groups: [],
    })
    const GROUPS = ['admin', 'user', 'developer']
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = async () => {
        const data = await createOrganizationUser({
            variables: {
                input: state,
            }
        })
        enqueueSnackbar('User created', { variant: 'success' })

    }
    return (
        <Container>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6">Create User</Typography>
                    <MyTextField
                        label="Username"
                        value={state.username}
                        onChange={e => dispatch({ type: 'set', field: 'username', value: e.target.value })}
                    />
                    <MyTextField
                        label="First Name"
                        value={state.firstName}
                        onChange={e => dispatch({ type: 'set', field: 'firstName', value: e.target.value })}
                    />
                    <MyTextField

                        label="Last Name"
                        value={state.lastName}
                        onChange={e => dispatch({ type: 'set', field: 'lastName', value: e.target.value })}
                    />
                    <MyTextField
                        label="Email"
                        value={state.email}
                        onChange={e => dispatch({ type: 'set', field: 'email', value: e.target.value })}
                    />
                    <MyTextField
                        label="Groups"
                        value={state.groups}
                        onChange={e => dispatch({ type: 'set', field: 'groups', value: e.target.value })}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="new-user-groups-id">Groups</InputLabel>
                        <Select
                            labelId="new-user-groups-id"
                            id="new-user-groups"
                            multiple
                            value={state.groups}
                            onChange={e => dispatch({ type: 'set', field: 'groups', value: e.target.value })}
                            input={<OutlinedInput label="Groups" />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {GROUPS.map((group) => (
                                <MenuItem key={group} value={group}>
                                    <Checkbox checked={state.groups.indexOf(group) > -1} />
                                    <ListItemText primary={group} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                </Stack>
            </Paper>
        </Container>
    )
}


export const UpdateOrganizationComponent = () => {
    const { id } = useParams();
    const history = useHistory()
    // const { organization, refetchOrganization } = useOrgnaization(organizationId);
    const { data, loading, error, refetch: refetchOrganization } = useQuery(gql`
        query Organization($id: ID!) {
            organization(id: $id) {
                id
                name
                formTemplates {
                    id
                    displayName
                }
                profiles {
                    id
                    user {
                        id
                        username
                        firstName
                        lastName
                        email
                        isActive
                    }
                }
            }
        }
    `, { variables: { id } });
    if (error) return 'ERROR: ' + error.message
    if (loading) return <CircularProgress />
    const { organization } = data
    return (
        <Container>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="h4">{organization.name} | [id:{organization.id}]</Typography>

                    <Typography variant="h6">USERS</Typography>

                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Full name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organization.profiles.map((profile) => (
                                    <TableRow
                                        hover
                                        key={profile.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {profile.user.username}
                                        </TableCell>
                                        <TableCell>{profile.user.firstName} {profile.user.lastName}</TableCell>
                                        <TableCell>{profile.user.email}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => history.push(`/organizations/${organization.id}/users/${profile.user.id}`)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={!profile.user.isActive}
                                            // onClick={() => history.push(`/organizations/${organization.id}/users/${profile.user.id}/delete`)}
                                            >
                                                <BlockIcon />
                                            </IconButton>
                                            <IconButton
                                            // onClick={() => history.push(`/organizations/${organization.id}/users/${profile.user.id}/delete`)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant="contained"
                        onClick={() => history.push(`/organizations/${organization.id}/users/create`)}
                    >
                        Add User
                    </Button>

                    <Typography variant="h6">TEMPLATES</Typography>

                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Form Template</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organization.formTemplates.map((formTemplate) => (
                                    <TableRow
                                        hover
                                        key={formTemplate.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {formTemplate.displayName}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => history.push(`/form-templates/${formTemplate.id}/edit`)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                            // onClick={() => history.push(`/organizations/${organization.id}/users/${profile.user.id}/delete`)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant="contained"
                        onClick={() => history.push(`/organizations/${organization.id}/users/create`)}
                    >
                        Add Form Template
                    </Button>
                </Stack>
            </Paper>
        </Container>
    )
}

export const OrganizationListingComponent = () => {
    const history = useHistory();
    const { data, loading, error, refetch: refetchOrganizations } = useQuery(gql`
        query Organizations {
            organizations {
                id
                name
                lang
                profiles {
                    id
                    user {
                        id
                        username
                        email
                        firstName
                        lastName
                    }
                }
            }
        }
    `, {
        fetchPolicy: 'no-cache',
    });
    if (error) return 'ERROR: ' + error.message
    if (loading) return <CircularProgress />
    const { organizations } = data
    return (
        <Container>
            <Paper sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6">ORGANIZATIONS</Typography>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => history.push('/organizations/create')}
                        >
                            Add Organization
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => refetchOrganizations()}
                        >
                            Refresh
                        </Button>
                    </Stack>
                    <MyTextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Language</TableCell>
                                    <TableCell align="right">Profiles</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organizations.map((organization) => (
                                    <TableRow
                                        hover
                                        key={organization.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {organization.name.trim()
                                                ? <>{organization.name.trim()}</>
                                                : <Typography variant="inherit" color="text.disabled">No Name</Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="right">{organization.lang}</TableCell>
                                        <TableCell align="right">{organization.profiles.length}</TableCell>
                                        <TableCell align="right">
                                            <IconButton edge="end" color="default" onClick={() => {
                                                history.push(`/organizations/${organization.id}/edit`)
                                            }}>
                                                <EditOutlined />
                                            </IconButton>
                                            <IconButton edge="end" color="default" onClick={() => {
                                                history.push(`/organizations/${organization.id}/delete`)
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Paper>
        </Container>
    )
}